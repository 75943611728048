import { action, reducerGenerator, createSelector } from './utils';

// Action Types
const MGM_WIDGET_FAILURE = 'MGM_WIDGET_FAILURE';
const MGM_WIDGET_RESET = 'MGM_WIDGET_RESET';
const MGM_WIDGET_SUCCESS = 'MGM_WIDGET_SUCCESS';

//Reducer
const defaultState = {
  mgmWidgetFailure: false,
  mgmWidgetDidLoad: false
};

export const mgmWidgetReducer = reducerGenerator(defaultState, {
  [MGM_WIDGET_FAILURE]: state => ({
    ...state,
    mgmWidgetFailure: true
  }),
  [MGM_WIDGET_RESET]: state => ({
    ...state,
    mgmWidgetFailure: false,
    mgmWidgetDidLoad: false
  }),
  [MGM_WIDGET_SUCCESS]: state => ({
    ...state,
    mgmWidgetDidLoad: true
  })
});

//Actions
export const dispatchMGMWidgetError = dispatch => {
  dispatch(action(MGM_WIDGET_FAILURE));
};

export const dispatchMGMWidgetReset = dispatch => {
  dispatch(action(MGM_WIDGET_RESET));
};

export const dispatchMGMWidgetLoad = dispatch => {
  dispatch(action(MGM_WIDGET_SUCCESS));
};

//Selectors
export const getWidgetInfo = state => state.mgmWidget;

export const didWidgetFail = createSelector(
  getWidgetInfo,
  widgetInfo => widgetInfo.mgmWidgetFailure
);

export const didWidgetLoad = createSelector(
  getWidgetInfo,
  widgetInfo => widgetInfo.mgmWidgetDidLoad
);
