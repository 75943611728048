import get from 'lodash.get';
import { getStateObj } from 'src/universal/utils/reduxStoreUtil';
import { getSelectedColorInfo, colorChoicesAllowed } from '../redux/cardColors';
import { hasPznDataArrived } from 'src/universal/redux/pznData.js';
import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';
import { isBrowser } from 'acq-utils/lib/misc';

// ===== session storage related =====

const SESSKEY = 'rubiks-selections';
const storage = browserStorage(STORAGE.session).bindItem(SESSKEY);

export const saveSessColorChoices = (selectionHash = {}) => {
  if (isBrowser() && selectionHash && typeof selectionHash === 'object') {
    storage.set(JSON.stringify(selectionHash));
  }
};

export const getSessColorChoices = () => {
  if (isBrowser()) {
    const rawVal = storage.get();
    if (typeof rawVal === 'string') {
      return JSON.parse(rawVal);
    }
  }
  return {};
};

// ===== misc. util functions =====

const hasDesignVariation = cardColorInfo => {
  return (
    cardColorInfo &&
    typeof cardColorInfo.hasOwnProperty === 'function' &&
    Object.prototype.hasOwnProperty.call(cardColorInfo, 'selectedAssetId') &&
    Array.isArray(cardColorInfo?.rubiksContent?.content)
  );
};

export const getRubiksIndexByAssetId = (cardColorInfo = {}, assetId) => {
  const indexVal = (cardColorInfo?.rubiksContent?.content || []).findIndex(item => {
    return item.digitalAssetId === assetId;
  });
  return indexVal <= -1 ? 0 : indexVal;
};

export const getRubiksAssetIdByIndex = (cardColorInfo = {}, index) => {
  const contentObj = (cardColorInfo?.rubiksContent?.content || [])[index];
  return typeof contentObj?.digitalAssetId === 'string'
    ? contentObj.digitalAssetId
    : '';
};

export const getBubiksItemByAssetId = (cardColorInfo = {}, assetId) => {
  return (cardColorInfo?.rubiksContent?.content || []).find(item => {
    return item.digitalAssetId === assetId;
  });
};

export const getRubiksDtmProductName = (cardColorInfo, defaultVal) => {
  if (!cardColorInfo) {
    return defaultVal || '';
  }

  const rubiksItem = getBubiksItemByAssetId(
    cardColorInfo,
    cardColorInfo.selectedAssetId
  );
  return rubiksItem?.dtmProductName || defaultVal || '';
};

export const decideCardArtUrl = (bauCardArtUrl, selectedColorInfo, shortName) => {
  if (hasDesignVariation(selectedColorInfo)) {
    const assetId = selectedColorInfo?.selectedAssetId || '';
    const selectedIndex = getRubiksIndexByAssetId(selectedColorInfo, assetId);
    return (
      get(
        selectedColorInfo,
        `rubiksContent.content[${selectedIndex}].appAssets.cardArt`
      ) || bauCardArtUrl
    );
  }

  let needBlankAsDefault = false;

  if (
    !isBrowser() ||
    (typeof shortName === 'string' &&
      colorChoicesAllowed(shortName)(getStateObj()) &&
      !hasPznDataArrived(getStateObj()))
  ) {
    needBlankAsDefault = true;
  }
  return needBlankAsDefault ? '' : bauCardArtUrl;
};

export const getSelecteddDesignInfo = cardShortName => {
  // collecting needed vals for eApply
  const cardColorInfo = getSelectedColorInfo(cardShortName)(getStateObj());
  if (hasDesignVariation(cardColorInfo)) {
    const assetId = cardColorInfo?.selectedAssetId || '';
    const selectedIndex = getRubiksIndexByAssetId(cardColorInfo, assetId);
    return {
      allowDesignVariation: true,
      arrangementId: get(cardColorInfo, 'designVariations.arrangementId') || '',
      experienceId: get(cardColorInfo, 'designVariations.experienceId') || '',
      experienceType: get(cardColorInfo, 'designVariations.type') || '',
      pmcCode: cardColorInfo.pmcCode,
      designId:
        get(cardColorInfo, `designVariations.cardDesigns[${selectedIndex}].id`) || ''
    };
  }
  return { allowDesignVariation: false };
};

const createField = (fieldName, fieldVal) => {
  const fieldEle = document.createElement('input');
  fieldEle.setAttribute('type', 'hidden');
  fieldEle.setAttribute('name', fieldName);
  fieldEle.setAttribute('id', fieldName);
  fieldEle.setAttribute('value', fieldVal);
  return fieldEle;
};

export const addCardDesignFieldsToForm = (formObj, cardDesignInfo) => {
  // we are going to mutate/change the incoming formObj...
  if (!formObj || !cardDesignInfo || typeof formObj.appendChild !== 'function') {
    return false;
  }
  formObj.appendChild(createField('arrangementId', cardDesignInfo.arrangementId));
  formObj.appendChild(createField('experienceId', cardDesignInfo.experienceId));
  formObj.appendChild(createField('designId', cardDesignInfo.designId));
  return true;
};

export const addCardDesignFieldsToUrl = (url, cardDesignInfo) => {
  if (!url || !cardDesignInfo) {
    return url;
  }
  const { arrangementId, experienceId, designId, pmcCode } = cardDesignInfo;
  return `${url}&arrangementId=${arrangementId}&experienceId=${experienceId}&designId=${designId}&pmccode=${pmcCode}`;
};
