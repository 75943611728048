import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { graphQlMiddleware } from 'page_modules/intl-pages/common/middleware/graphql';
import { dataMapperMiddleware } from 'page_modules/intl-pages/common/middleware/datamapper';
import { dnbScriptMiddleware } from 'src/services/store/middlewares/dnbScriptsManager.js';
import { resetStatesOnNavigation } from 'src/services/store/middlewares/resetStatesOnNavigation';

import createReducer from '../reducers';

const composeEnhancers =
  typeof window !== 'undefined' &&
  typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore(preloadedState) {
  let asyncReducers = {};
  const store = createStore(
    createReducer(),
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        graphQlMiddleware,
        dataMapperMiddleware,
        dnbScriptMiddleware,
        resetStatesOnNavigation
      )
    )
  );

  store.injectReducer = reducer => {
    const newReducers = {
      ...reducer,
      ...asyncReducers
    };

    if (Object.keys(newReducers).length !== Object.keys(asyncReducers).length) {
      asyncReducers = newReducers;
      store.replaceReducer(createReducer(asyncReducers, preloadedState));
    }
  };
  return store;
}
