import { setPage } from 'page_modules/active-page';
import { buildUrl } from 'core_modules/utils/url.utils';
import {
  US_BASE_URL,
  BUSINESS_CARD_PATH,
  CORPORATE_PROGRAMS_ROUTE_PATH
} from 'src/routes';
import { injectReducerToStore } from 'src/universal/redux/utils';

const path = buildUrl(
  US_BASE_URL,
  BUSINESS_CARD_PATH,
  CORPORATE_PROGRAMS_ROUTE_PATH
);

const corpCategoryRouteInit = store => ({
  path,
  getComponent: (routerState, cb) => {
    require.ensure(
      [],
      require => {
        const PageComponent = require('../').default;
        injectReducerToStore(store, PageComponent);
        setPage('corpCategory');
        cb(null, PageComponent);
      },
      null,
      'corpCategory'
    );
  },
  onEnter: (location, replaceWith, next) => {
    next();
  }
});

export { corpCategoryRouteInit };
