// Corporate Programs
export const CORPORATE_PROGRAMS_GET_DATA = 'CORPORATE_PROGRAMS_GET_DATA';
export const CORPORATE_PROGRAMS_EXIT_BANNER = 'CORPORATE_PROGRAMS_EXIT_BANNER';

// Lead Gen Form
export const LEADGEN_GET_DATA = 'LEADGEN_GET_DATA';
export const LEADGEN_SET_VIEW_STATE = 'LEADGEN_SET_VIEW_STATE';
export const LEADGEN_UPDATE_FIELD = 'LEADGEN_UPDATE_FIELD';
export const LEADGEN_VALIDATE_FIELD = 'LEADGEN_VALIDATE_FIELD';

// Compare Cards
export const COMPARE_CARDS_ERROR = 'COMPARE_CARDS_ERROR';
export const COMPARE_CARDS_GET_DATA = 'COMPARE_CARDS_GET_DATA';
export const COMPARE_CARDS_OFFER_DATA = 'COMPARE_CARDS_OFFER_DATA';
export const COMPARE_CARDS_OFFER_EXPIRY = 'COMPARE_CARDS_OFFER_EXPIRY';
export const COMPARE_CARDS_ADD = 'COMPARE_CARDS_ADD';
export const COMPARE_CARDS_REMOVE = 'COMPARE_CARDS_REMOVE';
export const INTL_GCP_COMPARE_CARDS_CLOSE = 'INTL_GCP_COMPARE_CARDS_CLOSE';
export const INTL_JOINT_FRONT_DOOR_SUCCESS = 'INTL_JOINT_FRONT_DOOR_SUCCESS';

// Category Page
export const ALL_CARDS_GET_DATA = 'ALL_CARDS_GET_DATA';
export const ALL_CARDS_OFFER_DATA = 'ALL_CARDS_OFFER_DATA';
export const ALL_CARDS_OFFER_EXPIRY = 'ALL_CARDS_OFFER_EXPIRY';

// Category Page V2
export const ALL_CARDS_GET_DATA_V2 = 'ALL_CARDS_GET_DATA_V2';
export const ALL_CARDS_OFFER_DATA_V2 = 'ALL_CARDS_OFFER_DATA_V2';
export const ALL_CARDS_OFFER_EXPIRY_V2 = 'ALL_CARDS_OFFER_EXPIRY_V2';
export const ALL_CARDS_EXIT_BANNER_V2 = 'ALL_CARDS_EXIT_BANNER_V2';
export const ALL_CARDS_ERROR_V2 = 'ALL_CARDS_ERROR_V2';

// Card Detail
export const CARD_DETAIL_GET_DATA = 'CARD_DETAIL_GET_DATA';
export const CARD_DETAIL_OFFER_DATA = 'CARD_DETAIL_OFFER_DATA';
export const CARD_DETAIL_OFFER_EXPIRY = 'CARD_DETAIL_OFFER_EXPIRY';
export const CARD_DETAIL_EXIT_BANNER = 'CARD_DETAIL_EXIT_BANNER';
export const CARD_DETAIL_SUPP_MODAL = 'CARD_DETAIL_SUPP_MODAL';

// Tagging
export const DTM_PAGE_LOAD = 'DTM_PAGE_LOAD';

//Redirect Modal
export const GET_MODAL_DATA = 'GET_MODAL_DATA';

//Prequal Error
export const PREQUAL_ERROR = 'PREQUAL_ERROR';

// New Business Homepage
export const REQ_HOME_DATA = 'REQ_HOME_DATA';
export const RES_HOME_DATA = 'RES_HOME_DATA';
export const ERR_HOME_DATA = 'ERR_HOME_DATA';

//Corporate Category
export const REQ_CORP_CATEGORY_DATA = 'REQ_CORP_CATEGORY_DATA';
export const RES_CORP_CATEGORY_DATA = 'RES_CORP_CATEGORY_DATA';
export const ERR_CORP_CATEGORY_DATA = 'ERR_CORP_CATEGORY_DATA';
