// enums
import { Color, Align } from '@americanexpress/aqx-components';

export const mobileTheme = {
  bgColor: Color.Gray01
};

export const tabletTheme = {
  bgColor: Color.Gray01,
  paddingLeft: 0,
  paddingRight: 20
};

export const tabletDropdownTheme = {
  theme: {
    bgColor: Color.Gray01,
    paddingHorizontal: 25
  }
};

export const desktopDropdownTheme = {
  theme: {
    bgColor: Color.Gray01
  }
};

export const mobileCallTheme = {
  theme: {
    padding: 20,
    margin: 0,
    horizontallyFluid: true
  }
};

export const tabletCallTheme = {
  theme: {
    padding: 0,
    margin: 0,
    horizontallyFluid: false
  }
};

export const tabletDropdownTitleTheme = {
  paddingLeft: 25,
  paddingRight: 10
};

export const mobileDropdownTitleTheme = {
  paddingHorizontal: 15
};

export const desktopDropdownTitleTheme = {
  paddingHorizontal: 5
};

export const tabletCallTypographyTheme = {
  textAlign: Align.Left,
  paddingTop: 15,
  color: Color.Gray06
};
