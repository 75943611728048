'use strict';

import React from 'react';

import MainContainer from 'page_modules/business-page-containers/templates/main-container';
import TplError from './templates/error.tpl';
import { getSubnavData } from 'service_modules/services/open-shop-platform/get-subnav-data';
import { errorPageData } from './data';
import { logError } from 'src/universal/utils/IsomorphicLogger/logError';
import get from 'lodash.get';
import dtmEvents from 'service_modules/models/dtm-tagging';

import 'theme_modules/open-gcp/pages/http-errors/ssr-error-page.scss';

export class SSRErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalData: null,
      TNL: null
    };
  }

  getErrorCode() {
    const props = this.props;
    const paramErr = props.params ? props.params.errorCode : void 0;
    const errCode = paramErr || props.httpStatus || '500';
    return String(errCode);
  }

  getExpiredEepVal() {
    const { reqUrl } = this.props;
    if (typeof reqUrl === 'string') {
      const matchResult = /\/open_cd\/\d{2,20}(\/)/.exec(reqUrl);
      if (matchResult && Array.isArray(matchResult)) {
        const resultVal = String(matchResult[0]);
        return resultVal.replace(/\/open_cd\//, '').replace(/\//g, '');
      }
    }
    return void 0;
  }

  isDisplaySubNav() {
    const errorCode = this.getErrorCode();
    const errCodesRequireSubNav = ['400', '417', '404'];
    /*
    Per conversation with Doris:
    UK wants product based subnav in 19.3.
    Hiding subnav until then.
    */
    const isUK = this.props.countryCode && this.props.params.countryCode === 'en-uk';
    return errCodesRequireSubNav.indexOf(errorCode) > -1 && !isUK;
  }

  async componentDidMount() {
    if (this.getErrorCode() === '404') {
      dtmEvents.dispatch({
        type: 'DTM_DATA_READY',
        dtmTag: { pageName: 'errorbusiness404' }
      });
      dtmEvents.dispatch({ type: 'DTM_PAGE_LOAD' });
    }
    if (this.getErrorCode() === '417') {
      dtmEvents.dispatch({
        type: 'DTM_DATA_READY',
        dtmTag: { pageName: 'errorbusiness417' }
      });
      dtmEvents.dispatch({ type: 'DTM_PAGE_LOAD' });
    }
    if (this.getErrorCode() === '500') {
      dtmEvents.dispatch({
        type: 'DTM_DATA_READY',
        dtmTag: { pageName: 'errorbusiness500' }
      });
      dtmEvents.dispatch({ type: 'DTM_PAGE_LOAD' });
    }

    if (!this.props.serverSideError) {
      const errorObj = {
        errorCode: this.getErrorCode(),
        errorDetails: this.props.errorDetails,
        stackTrace: this.props.stackTrace
      };
      logError('client-side-error', errorObj);
    }
    if (this.isDisplaySubNav()) {
      try {
        const errorPageResponse = await getSubnavData({
          countryCode: this.props.countryCode
        });
        this.setState(() => {
          const newState = {};
          const args = {
            status: errorPageResponse.status,
            currentPageType: 'error'
          };
          newState.globalData = {
            ...args,
            ...get(errorPageResponse, 'data.cardShop.global')
          };
          /**
           * TNL data is needed on the error page because subnav tests are getting
           * run on us-commercial - please do not remove the line below.
           */
          newState.TNL = { ...get(errorPageResponse, 'data.cardShop.TNL') };
          return newState;
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const errorCode = this.getErrorCode();
    let data;
    const countryCode = this.props.countryCode;

    const ErrorPageData = errorPageData[countryCode]
      ? errorPageData[countryCode]
      : errorPageData['en-us'];
    switch (errorCode) {
      case '400':
      case '417':
        data = ErrorPageData.errorOfferGatingData
          ? ErrorPageData.errorOfferGatingData
          : errorPageData['en-us'].errorOfferGatingData;
        // per conv with Parag on feb-16, we need to provide EEP for propergating onto target vac page,
        data.expiredEepVal = this.getExpiredEepVal();
        break;
      case '404':
        data = ErrorPageData.error404Data;
        break;
      default:
        // 500, 503, anything else
        data = ErrorPageData.error500Data;
        break;
    }

    const errView = (
      <ssr-error-page>
        <TplError data={data} isPrequal={this.props.isPrequal} />
      </ssr-error-page>
    );

    if (this.isDisplaySubNav() === true) {
      return (
        <MainContainer
          globalData={this.state.globalData}
          TNL={this.state.TNL}
          showOffers={true}
          showPhoneNumber={!this.props.isPrequal}
          countryCode={countryCode}
        >
          {errView}
        </MainContainer>
      );
    } else {
      return errView;
    }
  }
}

SSRErrorPage.displayName = 'SSRErrorPage';

export default SSRErrorPage;
