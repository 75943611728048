import { action, createSelector, reducerGenerator } from './utils';
import {
  getUserType as getUserTypeUtil,
  loggedInString,
  loggedOutString,
  prospectString
} from 'src/universal/utils/getUserType';
import {
  hasMemberInfoArrived,
  isGCSMember,
  isCPSMember,
  isUserLoggedIn
} from './cardMemberInfo';
// actionTypes
const USER_TYPE_LOGGED_IN = 'USER_TYPE_LOGGED_IN';
const USER_TYPE_LOGGED_OUT = 'USER_TYPE_LOGGED_OUT';

// Reducer
export const userTypeReducer = reducerGenerator(prospectString, {
  [USER_TYPE_LOGGED_IN]: () => loggedInString,
  [USER_TYPE_LOGGED_OUT]: () => loggedOutString
});

// Actions
export const dispatchLoggedInAction = dispatch =>
  dispatch(action(USER_TYPE_LOGGED_IN));
export const dispatchLoggedOutAction = dispatch =>
  dispatch(action(USER_TYPE_LOGGED_OUT));
export const dispatchUserTypeActions = (cookies = {}, dispatch = () => {}) => {
  const [userType] = getUserTypeUtil(cookies);
  switch (userType) {
    case loggedInString:
      dispatchLoggedInAction(dispatch);
      break;
    case loggedOutString:
      dispatchLoggedOutAction(dispatch);
      break;
  }
};

// Selectors
export const getUserType = state => state.userType;

// Applies to both Consumer and GCS CardMembers
export const isLoggedIn = createSelector(
  getUserType,
  userType => userType === loggedInString
);
export const isLoggedOut = createSelector(
  getUserType,
  userType => userType === loggedOutString
);
export const isCardMember = createSelector(
  getUserType,
  userType => userType === loggedOutString || userType === loggedInString
);
export const isProspect = createSelector(
  getUserType,
  userType => userType === prospectString
);

// Applies only for GCS CardMembers
export const isLoggedInGCS = createSelector(
  hasMemberInfoArrived,
  isGCSMember,
  isUserLoggedIn,
  (cmDataArrived, isGCS, userLoggedIn) => cmDataArrived && isGCS && userLoggedIn
);
export const isLoggedInCPS = createSelector(
  hasMemberInfoArrived,
  isCPSMember,
  isUserLoggedIn,
  (cmDataArrived, isCPS, userLoggedIn) => cmDataArrived && isCPS && userLoggedIn
);
export const isLoggedOutGCS = createSelector(
  hasMemberInfoArrived,
  isGCSMember,
  isUserLoggedIn,
  (cmDataArrived, isGCS, userLoggedIn) => cmDataArrived && isGCS && !userLoggedIn
);
export const isLoggedOutCPS = createSelector(
  hasMemberInfoArrived,
  isLoggedOutGCS,
  isUserLoggedIn,
  isProspect,
  (cmDataArrived, isLoggedOutGCS, userLoggedIn, isProspect) =>
    cmDataArrived && !isLoggedOutGCS && !userLoggedIn && !isProspect
);
export const isCardMemberGCS = createSelector(
  isCardMember,
  isLoggedInGCS,
  isLoggedOutGCS,
  (isCardMember, isLoggedInGCS, isLoggedOutGCS) =>
    isCardMember && (isLoggedInGCS || isLoggedOutGCS)
);
export const isNotCardMemberGCS = createSelector(
  isCardMemberGCS,
  isCardMemberGCS => !isCardMemberGCS
); // includes prospects and consumer card members

export const isCardMemberCPS = createSelector(
  isCardMember,
  isCPSMember,
  (isCardMember, isCPSMember) => !!isCardMember && !!isCPSMember
);

// this is inclusive of GCS and CPS
export const isLoggedInCardMember = createSelector(
  isLoggedInGCS,
  isLoggedInCPS,
  (loggedInGCS, loggedInCPS) => !!loggedInGCS || !!loggedInCPS
);
