export const isServer = () => typeof window === 'undefined';

export const isTouchDevice = () => {
  if (isServer()) {
    return false;
  }

  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  return hasTouchScreen;
};

export const isMobileDevice = () => {
  return isTouchDevice() && window.innerWidth < 768;
};

export const isTabletDevice = () => {
  return isTouchDevice() && window.innerWidth > 767 && window.innerWidth < 1024;
};

const parseUserAgent = () => {
  if (isServer()) {
    return false;
  }

  const ua = window.navigator.userAgent;

  let browserType = '';

  if (ua.includes('Chrome')) {
    browserType = 'Chrome';
  } else if (ua.includes('Firefox')) {
    browserType = 'Firefox';
  } else if (ua.includes('Safari')) {
    browserType = 'Safari';
  } else if (ua.includes('msie') || ua.includes('trident/')) {
    browserType = 'IE';
  }

  return browserType;
};

export const isIE = () => {
  return parseUserAgent() === 'IE';
};

export const isFirefox = () => parseUserAgent() === 'Firefox';

export const isChrome = () => parseUserAgent() === 'Chrome';

export const isSafari = () => parseUserAgent() === 'Safari';
