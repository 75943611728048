import {
  isIE11,
  isEdge,
  isChrome
} from 'core_modules/bootstrap/browsers/browser-detection';
import { ifBrowserThen } from 'acq-utils/lib/Browser';

const scrollToPosition = ifBrowserThen(targetPosition => {
  const isIE = isEdge && isIE11;
  if (isIE) {
    return window.scrollTo(0, targetPosition);
  }

  return window.scroll({
    top: targetPosition,
    behavior: 'smooth'
  });
});

const scrollToTop = () => {
  const waitVal = isChrome ? 120 : 60;
  setTimeout(() => scrollToPosition(0), waitVal);
};

export const scrollToTopOnOrientationChange = ifBrowserThen(() => {
  window.addEventListener('orientationchange', () => scrollToTop());
});
