import { reducerGenerator, action, createSelector } from './utils';
import get from 'lodash.get';
import compareCardLocalstorage from 'service_modules/persistence/compare-card/compare-card-localstorage';
import { getPageCardsData } from 'src/universal/redux/page';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';

// Action Types
const ADD_CARD = 'ADD_CARD';
const REMOVE_CARD = 'REMOVE_CARD';
const PRESELECTED_CARDS_ARRIVED = 'PRESELECTED_CARDS_ARRIVED';

// Default State
const defaultState = {
  cards: []
};

export const selectedCompareCardsReducer = reducerGenerator(defaultState, {
  [PRESELECTED_CARDS_ARRIVED]: (state, action) => {
    const { preselectedCards } = action;
    if (Array.isArray(preselectedCards) && preselectedCards.length > 0) {
      compareCardLocalstorage.selected.set(preselectedCards);
      compareCardLocalstorage.activity.set(true);
    }
    const selectedCardsInLocalStorage = compareCardLocalstorage.selected.get();
    return {
      ...state,
      cards: selectedCardsInLocalStorage.filter(card => card !== null)
    };
  },
  [ADD_CARD]: (state, action) => {
    const cardToBeAdded = get(action, 'shortName');
    if (state.cards.length === 3 || !cardToBeAdded) {
      return state;
    } else {
      const newSelectedCardsState = [...state.cards, cardToBeAdded];
      compareCardLocalstorage.selected.set(newSelectedCardsState);
      compareCardLocalstorage.activity.set(true);
      return {
        ...state,
        cards: newSelectedCardsState
      };
    }
  },
  [REMOVE_CARD]: (state, action) => {
    const cardToBeRemovedShortName = get(action, 'shortName') || '';
    const newSelectedCardsState = state.cards.filter(
      shortName => shortName !== cardToBeRemovedShortName
    );
    compareCardLocalstorage.selected.set(newSelectedCardsState);
    compareCardLocalstorage.activity.set(true);
    return {
      ...state,
      cards: newSelectedCardsState
    };
  }
});

// Dispatches
export const dispatchAddCardToCompare = (dispatch, payload) => {
  dispatch(action(ADD_CARD, payload));
};

export const dispatchRemoveCardFromCompare = (dispatch, payload) => {
  dispatch(action(REMOVE_CARD, payload));
};

export const dispatchPreselectedCardsArrived = (dispatch, payload) => {
  dispatch(action(PRESELECTED_CARDS_ARRIVED, payload));
};

// Selectors
export const getSelectedCompareCards = state =>
  state?.selectedCompareCards?.cards ?? [];

export const getCardListData = createSelector(
  [getSelectedCompareCards, getPageCardsData],
  (selectedCards, cardsData) => {
    const cardObj = parseArrayToObject(cardsData, 'shortName');
    return selectedCards.map(card => cardObj[card]);
  }
);
