import React from 'react';

// components
import { Typography } from '@americanexpress/aqx-components';
// enums
import { FontType } from '@americanexpress/aqx-components';

export const Text = ({ children, $fontSize, textRole = '' }) => {
  return (
    <Typography
      Component="span"
      $size={$fontSize}
      $family={FontType.HelveticaRegular}
      role={textRole}
    >
      {children}
    </Typography>
  );
};
