import { combineReducers } from 'redux';

import eepReducer from './eep-reducer';
import performanceReducer from './performance-reducer';
import modalData from './modal-reducer';
import suppModal from './supp-modal-reducer';
import prequalError from './prequal-error-state-reducer';
import { routerReducer as routing } from 'react-router-redux';
import { oneXpReducer } from 'src/universal/redux/oneXp';

import { userTypeReducer } from 'src/universal/redux/userType';
import { cardMemberInfoReducer } from 'src/universal/redux/cardMemberInfo';
import { pznDataReducer } from 'src/universal/redux/pznData';
import { mgmWidgetReducer } from 'src/universal/redux/mgmWidget';
import { activepageReducer } from 'src/universal/redux/page';
import { filterReducer } from 'src/universal/redux/cardsFilter';
import { selectedCompareCardsReducer } from 'src/universal/redux/compareCardsTray';
import { cardColorChangeReducer } from 'src/universal/redux/cardColors';

global.pagesVisited = [];

const pageTracker = (state, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    global.pagesVisited.push(action.payload.pathname);
  }
  return {};
};

const dtmEventsReducer = (state = { dtmEventJustFired: '' }, action) => {
  if (action.type === 'CURRENT_DTM_EVENT') {
    return Object.assign({}, state, { dtmEventJustFired: action.data.dtmEventName });
  }

  return state;
};

function injectState(reducers, preloadedState = {}) {
  return Object.keys(reducers).reduce((finalReducers, key) => {
    if (typeof reducers[key] === 'function') {
      finalReducers[key] = (state = preloadedState[key], action) =>
        reducers[key](state, action);
    }
    return finalReducers;
  }, {});
}

const createReducer = (asyncReducers, preloadedState) =>
  combineReducers(
    injectState(
      {
        pageTracker,
        eepReducer,
        modalData,
        suppModal,
        prequalError,
        routing,
        performanceReducer,
        dtmEventsReducer,
        oneXp: oneXpReducer,
        userType: userTypeReducer,
        cardMemberInfo: cardMemberInfoReducer,
        pznData: pznDataReducer,
        mgmWidget: mgmWidgetReducer,
        activePageName: activepageReducer,
        filter: filterReducer,
        selectedCompareCards: selectedCompareCardsReducer,
        selectedCardColors: cardColorChangeReducer,
        ...asyncReducers
      },
      preloadedState
    )
  );

export default createReducer;
