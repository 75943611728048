import React from 'react';
import { Link } from 'react-router';

import { ROUTES } from 'acq-gcs-pages/lib/universal/constants';
import { setPage } from 'page_modules/active-page';

import { isUrlExternal } from 'acq-utils/lib/utils';
import { appendMarketingParams } from '../common/marketingQueryParams';
import getCountryCode from 'src/util-getCountryCodeFromUrl';
import dtmEvents from 'service_modules/models/dtm-tagging';
import { injectReducerToStore } from 'src/universal/redux/utils';

const onClickHandler = pageData => props => {
  const { onClick, dtm, ...rest } = props;

  if (rest.href || rest.to) {
    const withMarketingParams = appendMarketingParams(
      rest.href || rest.to,
      pageData.location.query
    );

    if (isUrlExternal(withMarketingParams)) {
      props.href = withMarketingParams;
      rest.href = withMarketingParams;
    } else {
      props.to = withMarketingParams;
      rest.to = withMarketingParams;
    }

    if (!onClick) {
      props.Component = Link;
      rest.Component = Link;
    }
  }

  if (typeof window !== 'object') {
    return props;
  }

  if (!onClick && !dtm) {
    return props;
  }

  rest.onClick = event => {
    if (dtm) {
      dtmEvents.dispatch({
        type: 'DTM_CLICK',
        dtmTag: dtm
      });
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return rest;
};
const addMarketingQPsToEdgeCases = function(props) {
  (props.cards || []).forEach(card => {
    card.links = card.links.map(link => ({
      ...link,
      url: appendMarketingParams(link.url, props.location.query)
    }));

    const applyModal = card.applyModal;
    if (applyModal) {
      applyModal.content = applyModal.content.map(content => ({
        ...content,
        button: {
          ...content.button,
          url: appendMarketingParams(content.button.url, props.location.query)
        }
      }));
    }
  });
  /**
   * no real need to return here since this mutates via reference
   * but it is a healthy pattern to keep functions functional
   * and test friendly by returning an expected value
   */
  return props;
};

const intlVACPage = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          viewAllCardsComponents
        } = require('acq-gcs-pages/lib/universal/routes/ViewAllCardsBusiness');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/viewAllCards');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');

        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const VacComponent = viewAllCardsComponents[countryCode];
        const styles = stylesMap[countryCode].business;
        const queryByCountry = queriesByCountryCode[countryCode] || {};
        const query = queryByCountry.business;

        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props),
              compare: onClickHandler(props)
            });
            props = addMarketingQPsToEdgeCases(props);
            return (
              <PropertyProvider parseProps={eventHandler}>
                <VacComponent
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={styles}
                />
              </PropertyProvider>
            );
          },
          {
            query,
            isCorporate: false,
            page: 'vac',
            productType: 'viewAllCards'
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlVacBusiness'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('intlPage');
    next();
  }
});

const intlVACPageCorporate = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          viewAllCardsCorporateComponents
        } = require('acq-gcs-pages/lib/universal/routes/ViewAllCardsCorporate');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/viewAllCards');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');

        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const VacComponent = viewAllCardsCorporateComponents[countryCode];

        const styles = stylesMap[countryCode].corporate;
        const queryByCountry = queriesByCountryCode[countryCode] || {};
        const query = queryByCountry.corporate;

        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props),
              compare: onClickHandler(props)
            });
            props = addMarketingQPsToEdgeCases(props);
            return (
              <PropertyProvider parseProps={eventHandler}>
                <VacComponent
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={styles}
                />
              </PropertyProvider>
            );
          },
          {
            query,
            isCorporate: true,
            page: 'vac',
            productType: 'viewAllCards'
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlVacCorp'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('intlPage');
    next();
  }
});

const routes = {
  intlVACPage: store => ROUTES.ViewAllCardsSmallBusiness.map(intlVACPage(store)),
  intlVACPageCorporate: store =>
    ROUTES.ViewAllCardsCorporate.map(intlVACPageCorporate(store))
};
export default routes;
