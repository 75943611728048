import { ssrErrorPageRoutes } from 'page_modules/http-errors/ssr-error-route';
import { getErrorPageCountryCode } from 'page_modules/http-errors/utils.js';

export const notFoundRoute = {
  path: '*',
  onEnter: function(location, replaceWith, next) {
    location.errorCode = 404;
    const pathArr = location.location.pathname.split('/')[1];
    const incomingCountryCode =
      pathArr.length > 1 ? location.location.pathname.split('/')[1] : 'us';

    location.params.countryCode = getErrorPageCountryCode(incomingCountryCode);
    return ssrErrorPageRoutes.onEnter(location, replaceWith, next);
  },
  onLeave: function() {}
};
