import React from 'react';
import get from 'lodash.get';
import { Link } from 'react-router';

import { isUrlExternal } from 'acq-utils/lib/utils';
import { ROUTES } from 'acq-gcs-pages/lib/universal/constants';
import { setPage } from 'page_modules/active-page';
import { appendMarketingParams } from '../common/marketingQueryParams';
import getCountryCode from 'src/util-getCountryCodeFromUrl';
import dtmEvents from 'service_modules/models/dtm-tagging';
import { injectReducerToStore } from 'src/universal/redux/utils';

const onClickHandler = pageData => props => {
  const { onClick, dtm, ...rest } = props;

  if (rest.href || rest.to) {
    const withMarketingParams = appendMarketingParams(
      rest.href || rest.to,
      pageData.location.query
    );

    if (isUrlExternal(withMarketingParams)) {
      props.href = withMarketingParams;
      rest.href = withMarketingParams;
    } else {
      props.to = withMarketingParams;
      rest.to = withMarketingParams;
    }

    if (!onClick) {
      props.Component = Link;
      rest.Component = Link;
    }
  }

  if (typeof window !== 'object') {
    return props;
  }

  if (!onClick && !dtm) {
    return props;
  }

  rest.onClick = event => {
    if (dtm) {
      dtmEvents.dispatch({
        type: 'DTM_CLICK',
        dtmTag: dtm
      });
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return rest;
};

const getLinkMutator = props => links => {
  const linksToSkip = ['phoneNumber'];
  (links || []).forEach(link => {
    if (!linksToSkip.includes(link.key)) {
      link.url = appendMarketingParams(link.url, props.location.query);
    }
  });
};

const addMarketingQPsToEdgeCases = function(props) {
  const { hero = {}, links = [] } = props.page || {};
  const compareCards = get(props, 'page.compareCards') || {};
  const card = (props.cards && props.cards[0]) || {};
  const miniCompareCards = card.compareCards || [];
  const mutateLinks = getLinkMutator(props);
  const applyModal = card.applyModal || {};
  const applyModalLinks =
    applyModal.content && applyModal.content.map(content => content.button);

  mutateLinks(compareCards.links);
  (miniCompareCards || []).forEach(card => {
    mutateLinks(card.links);
  });
  mutateLinks(compareCards.links);
  mutateLinks(card.links);
  mutateLinks(hero.links);
  mutateLinks(links);
  mutateLinks(applyModalLinks);

  /**
   * no real need to return here since this mutates via reference
   * but it is a healthy pattern to keep functions functional
   * and test friendly by returning an expected value
   */
  return props;
};

const intlCardDetailsSmallBusiness = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/cardDetails');
        const {
          cardDetailComponents
        } = require('acq-gcs-pages/lib/universal/routes/CardDetailsBusiness');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');

        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const queryByCountry = queriesByCountryCode[countryCode] || {};

        const CardDetails = cardDetailComponents[countryCode];
        const pageStyles = get(stylesMap, `${countryCode}.business`, {});
        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props)
            });
            props = addMarketingQPsToEdgeCases(props);
            return (
              <PropertyProvider parseProps={eventHandler}>
                <CardDetails
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={pageStyles}
                />
              </PropertyProvider>
            );
          },
          {
            page: 'pdp',
            productType: 'cardDetails',
            isCorporate: false,
            query: queryByCountry.business
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlPDPBusiness'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('intlPage');
    next();
  }
});

const intlCardDetailsCorporate = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/cardDetails');
        const {
          cardDetailCorporateComponents
        } = require('acq-gcs-pages/lib/universal/routes/CardDetailsCorporate');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');

        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const queryByCountry = queriesByCountryCode[countryCode] || {};
        const CardDetailsCorporate = cardDetailCorporateComponents[countryCode];
        const pageStyles = get(stylesMap, `${countryCode}.corporate`, {});
        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props)
            });
            props = addMarketingQPsToEdgeCases(props);
            return (
              <PropertyProvider parseProps={eventHandler}>
                <CardDetailsCorporate
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={pageStyles}
                />
              </PropertyProvider>
            );
          },
          {
            page: 'pdp',
            productType: 'cardDetails',
            isCorporate: true,
            query: queryByCountry.corporate
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlPDPCorp'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('intlPage');
    next();
  }
});

const routes = {
  intlCardDetailsSmallBusiness: store =>
    ROUTES.CardDetailsSmallBusiness.map(intlCardDetailsSmallBusiness(store)),
  intlCardDetailsCorporate: store =>
    ROUTES.CardDetailsCorporate.map(intlCardDetailsCorporate(store))
};

export default routes;
