import { Log, LogEventDuration, getNext } from 'src/laas-logger';
import post, { toJSON } from 'src/laas-post';
import { isServer } from 'src/universal/utils/browser-detection/index';

let csrPageLaaSInterval;

export const pageLaaSQueue = (timer = 5000) => {
  try {
    if (!isServer()) {
      clearInterval(csrPageLaaSInterval);

      csrPageLaaSInterval = setInterval(() => {
        const entry = toJSON(getNext());

        if (!entry) {
          return clearInterval(csrPageLaaSInterval);
        }

        post(entry);

        // console.log('entry', entry);
      }, timer);
    }
  } catch (error) {
    console.error('[ERROR] queueLaaS', error.stack);
  }

  return () => clearInterval(csrPageLaaSInterval);
};

let requestLaaSQueueInterval;

export const requestLaaSQueue = (timer = 3000) => {
  try {
    clearInterval(requestLaaSQueueInterval);

    requestLaaSQueueInterval = setInterval(() => {
      const entry = toJSON(getNext());

      if (!entry) {
        return clearInterval(requestLaaSQueueInterval);
      }

      post(entry);

      // console.log('entry', entry);
    }, timer);
  } catch (error) {
    console.error('[ERROR] queueLaaS', error.stack);
  }

  return () => clearInterval(requestLaaSQueueInterval);
};

export const logRequestData = options => {
  const logEventDuration = LogEventDuration();
  return response => {
    logEventDuration({})(data => {
      data.message.url = response.url;
      data.message.method = options.method;
      data.message.headers = response.headers;
      data.status = response.status;
      data.responseTime = data.message.eventDuration;
      data.functionName = `/da/request-data/runtime@${isServer() ? 'SSR' : 'CSR'}`;
      return data;
    });
  };
};

export const logRequestHeaderCookies = () => {
  Log((data = {}) => {
    data.message = {
      //id,
      //data: JSON.stringify(cookies)
    };
    data.status = 200;
    data.responseTime = null;
    data.functionName = `/da/request/cookie@${isServer() ? 'SSR' : 'CSR'}`;
    return data;
  });
};
