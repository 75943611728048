export const initialState = {
  willReceivePropsTimestamp: undefined,
  willMountTimestamp: undefined,
  didMountTimestamp: undefined,
  routeChangeTimestamp: undefined,
  pageName: undefined,
  deviceType: undefined,
  initialRequest: undefined,
  isMobile: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return Object.assign({}, initialState, {
        routeChangeTimestamp: new Date().getTime()
      });
    case 'PERFORMANCE_WILL_RECEIVE_PROPS':
      return Object.assign({}, state, {
        willReceivePropsTimestamp: action.data.timestamp
      });
    case 'PERFORMANCE_WILLMOUNT':
      return Object.assign({}, state, {
        willMountTimestamp: action.data.timestamp
      });
    case 'PERFORMANCE_DIDMOUNT':
      return Object.assign({}, state, {
        didMountTimestamp: action.data.timestamp,
        pageName: action.data.pageName,
        deviceType: action.data.deviceType,
        initialRequest: action.data.initialRequest,
        isMobile: action.data.isMobile
      });
    default:
      return state;
  }
};
