import get from 'lodash.get';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { getStateObj } from 'src/universal/utils/reduxStoreUtil';
import { pznPageIds } from 'src/universal/components/PZN/config';
import { determineCmIndicator } from 'src/universal/components/PZN/FetchData/getPznParams';
import { hasPznDataArrived } from 'src/universal/redux/pznData';
import { hasMemberInfoArrived } from 'src/universal/redux/cardMemberInfo';

/* 
[[ US3495698: What does this module do? ]]
(1) provides shared functions to handle/process the PZN ext calls for Card Member use-case.
(2) hosts detailed behavior documentation for PZN-EXT calls (Card Member use-case).

[[ How/why the PZN EXT call behaves differently for Card Member use-case (vs. propspect)? ]]
(1) Unlike Prospect use-case, Card Member use-case does not fire PZN-EXT event unless
    the relavent components are shown to the user.
(2) Unlike Prospect use-case, which makes a single call for each page loading, Card Member
    use-case requires making multiple EXT calls as when user interacts with the page.
(3) As result of the above, CM-PZN-EXT event is no longer tied to the Ajax PZN request itself
    as it is with the Prospect use-case. Instead it is driven (for the most part) at
    component level logic (similar to DTM impression events).
(4) We will have 2 types of cm-pzn-ext invocations:
    type-1: bundled: triggered at the page-loading level so that we bundle the ids for multiple
            components to save network bandwidth.
    type-2: action-triggered: this is triggered by user action such as scrolling or changing card.

[[ VAC - CM-PZN-EXT behavior ]]
(1) Page-loading (type-1):
    CmTabbedHero: for first tab content ONLY.
    CardTile: for the visible tile ONLY (above fold).
    CmToaster: if is configured to be and actually is displayed.
(2) Click interaction (type-2):
    CmTabbedHero: tab-clicking: the tab content that user just clicked on.
    CmTabbedHero: user-card clicking: the content of the 1st tab when user clicks a different
                  card when the card has an associated tab content-set.
(3) Scrolling/impressions (type-2):
    CardTile: as each cardTile being scrolled into view (once per card).
    CmBanner: if configured to be displayed. (once).

[[ B2B - CM-PZN-EXT behavior ]]
(1) Page-loading (type-1):
    CmTabbedHero: for first tab content ONLY.
    CmToaster: if is configured to be displayed.
(2) Click interaction (type-2):
    CmTabbedHero: tab-clicking: the tab content that user just clicked on.
    CmTabbedHero: user-card clicking: the content of the 1st tab when user clicks a different
                  card when the card has an associated tab content-set.
(3) Scrolling/impressions (type-2):
    CmBanner: if configured to be displayed. (once).

[[ PDP - CM-PZN-EXT behavior ]]
(1) Page-loading (type-1):
    Host Card of the page (once).
    CmToaster: if is configured to be displayed.
(2) Scrolling/impressions (type-2):
    SimilarCardTiles at lower part of the page: (once).
*/

let hasPageLevelEventDone = false;
let pznReqEep = '45094';
const processedItems = [];
const pageLevelItemQueue = [];

export const triggerCmPznExtEvent = async ({ pageId = '', cmExtItems = [] }) => {
  const stateObj = getStateObj();
  let pznPgId;
  if (!pageId) {
    pznPgId = pznPageIds[stateObj.activePageName] || 'unknown';
  } else {
    pznPgId = pageId;
  }

  const cmExtItemsVals = cmExtItems.map(extItem => {
    return `${extItem.compId || ''}-${extItem.id || ''}-${extItem.iaCode || ''}`;
  });

  const requestUrl = `open/content/pznExtn/${pznPgId}/${pznReqEep}?isCM=true&cmExtItems=${cmExtItemsVals.join(
    '|'
  )}&pznReqFlowId=${get(stateObj, 'pznData.pznAttributes.pznReqFlowId')}`;
  // sample: open/content/pznExtn/open_vac?isCM=true&cmExtItems=2134234-FHS|13123453-SBS
  await ajax.get(requestUrl, { requestName: 'app-request-pzn-ext' });
};

export const cmComponentPznExt = (cmExtItemList = []) => {
  /*
  notes:
  (1) Per Mina, treatmentId itself is not unique. So it must be paired with iaCode.
  https://aexp-mobile-and-web.slack.com/archives/C027V130VB7/p1647842419734299?thread_ts=1647632679.227229&cid=C027V130VB7
  (2) incoming cmExtItemList: [{ id: '123232323', iaCode: 'NJZ', compId: 'Hero' }, { id: '223232888', iaCode: '8DY', compId: 'Hero' }]
  */

  // we coerce an non-array obj to array
  let itemList = cmExtItemList;
  if (!Array.isArray(itemList) && typeof itemList === 'object') {
    itemList = [itemList];
  }

  if (!Array.isArray(itemList) || itemList.length <= 0) {
    return;
  }

  // processing each item
  const currList = [];
  itemList.forEach(item => {
    if (item && typeof item.id === 'string' && item.id.length > 0) {
      const resultItem = processedItems.find(itemDone => {
        return (
          itemDone.id === item.id &&
          itemDone.iaCode === item.iaCode &&
          itemDone.compId === item.compId
        );
      });
      if (!resultItem) {
        const itemCopy = { ...item };
        processedItems.push(itemCopy);
        currList.push(itemCopy);
        if (!hasPageLevelEventDone) {
          pageLevelItemQueue.push(itemCopy);
        }
      }
    }
  });

  if (hasPageLevelEventDone && currList.length > 0) {
    // type-2 call: we directly fire the event without queuing it up
    triggerCmPznExtEvent({ cmExtItems: currList });
  }
};

// type-1 call
export const pageLevelBundledCmPznExt = () => {
  if (hasPageLevelEventDone) {
    return;
  }
  const pageIdSupported = ['open_vac', 'open_businessHome', 'open_cd', 'open_cc'];
  const stateObj = getStateObj();
  const pznPgId = pznPageIds[stateObj.activePageName] || 'unknown';
  const hasPznArrived = hasPznDataArrived(stateObj);
  const hasCmInfoArrived = hasMemberInfoArrived(stateObj);
  const isCM = determineCmIndicator(stateObj.cardMemberInfo);

  // the condition validations are done so to make the caller side workload lighter.
  if (
    pageIdSupported.indexOf(pznPgId) <= -1 ||
    !hasPznArrived ||
    !hasCmInfoArrived ||
    !isCM
  ) {
    return;
  }

  const queueCopy = [...pageLevelItemQueue];
  pageLevelItemQueue.length = 0;
  hasPageLevelEventDone = true;
  if (queueCopy.length > 0) {
    triggerCmPznExtEvent({ cmExtItems: queueCopy });
  }
};

export const setPznReqEep = reqEep => {
  pznReqEep = reqEep;
};

// we need to clean up for CSR transition.
export const cleanUpBeforeCsr = () => {
  hasPageLevelEventDone = false;
  processedItems.length = 0;
  pageLevelItemQueue.length = 0;
  pznReqEep = '45094';
};
