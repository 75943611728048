import React from 'react';
import get from 'lodash.get';
import { Link } from 'react-router';

import { isUrlExternal } from 'acq-utils/lib/utils';
import { ROUTES } from 'acq-gcs-pages/lib/universal/constants';
import { appendMarketingParams } from '../common/marketingQueryParams';
import getCountryCode from 'src/util-getCountryCodeFromUrl';
import { setPage } from 'page_modules/active-page';
import dtmEvents from 'service_modules/models/dtm-tagging';
import { injectReducerToStore } from 'src/universal/redux/utils';

const onClickHandler = pageData => props => {
  const { onClick, dtm, ...rest } = props;

  if (rest.href || rest.to) {
    const withMarketingParams = appendMarketingParams(
      rest.href || rest.to,
      pageData.location.query
    );

    if (isUrlExternal(withMarketingParams)) {
      props.href = withMarketingParams;
      rest.href = withMarketingParams;
      props.to = '';
      rest.to = '';
    } else {
      props.to = withMarketingParams;
      rest.to = withMarketingParams;
      props.href = '';
      rest.href = '';
    }

    props.Component = Link;
    rest.Component = Link;
  }

  if (typeof window !== 'object') {
    return props;
  }

  if (!onClick && !dtm) {
    return props;
  }

  rest.onClick = event => {
    if (dtm) {
      dtmEvents.dispatch({
        type: 'DTM_CLICK',
        dtmTag: dtm
      });
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return rest;
};

const intlCompareSmallBusiness = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/compare');
        const {
          compareComponents: businessCompareComponents
        } = require('acq-gcs-pages/lib/universal/routes/CompareBusiness');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');
        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const queryByCountry = queriesByCountryCode[countryCode] || {};
        const CompareSmallBusinessCardsComponent =
          businessCompareComponents[countryCode];
        const pageStyles = get(stylesMap, `${countryCode}.business`, {});
        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props),
              compare: onClickHandler(props)
            });
            return (
              <PropertyProvider parseProps={eventHandler}>
                <CompareSmallBusinessCardsComponent
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={pageStyles}
                />
              </PropertyProvider>
            );
          },
          {
            isCorporate: false,
            query: queryByCountry.business,
            page: 'compare',
            productType: 'compare'
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlCompareBusiness'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('InternationalGcpCompare');
    next();
  }
});

const intlCompareCorporate = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          queries: queriesByCountryCode
        } = require('acq-gcs-pages/lib/universal/queries/compare');
        const {
          compareComponents: corporateCompareComponents
        } = require('acq-gcs-pages/lib/universal/routes/CompareCorporate');
        const {
          propertyHandler
        } = require('acq-gcs-pages/lib/universal/props/propertyHandler');
        const stylesMap = require('./styles.js').default;
        const { countryCode } = getCountryCode(params);
        const queryByCountry = queriesByCountryCode[countryCode] || {};
        const CompareCorporateCardsComponent =
          corporateCompareComponents[countryCode];
        const pageStyles = get(stylesMap, `${countryCode}.corporate`, {});
        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props),
              compare: onClickHandler(props)
            });
            return (
              <PropertyProvider parseProps={eventHandler}>
                <CompareCorporateCardsComponent
                  intlPage={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={pageStyles}
                />
              </PropertyProvider>
            );
          },
          {
            isCorporate: true,
            query: queryByCountry.corporate,
            page: 'compare',
            productType: 'compare'
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'intlCompareCorp'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('InternationalGcpCompare');
    next();
  }
});

export default {
  intlCompareSmallBusiness: store =>
    ROUTES.CompareSmallBusiness.map(intlCompareSmallBusiness(store)),
  intlCompareCorporate: store =>
    ROUTES.CompareCorporate.map(intlCompareCorporate(store))
};
