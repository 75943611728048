import React from 'react';
import { connect } from 'react-redux';

// components
import { Element } from '@americanexpress/aqx-components';
import { DropdownMenu } from './DropdownMenu';
import { CallInfo } from './CallInfo';
import { ClickableLogo } from './Logo';
import { CardMemberLink } from './CardMemberLink';

// styles
import styles from '../__styles__/index.scss?modules';
import { tabletDropdownTheme, tabletCallTheme } from '../__styles__/themes';

// utils
import { getCardMemberData } from '../utils';

const TabletSubnav = props => {
  const { userLoggedIn, viewMyAccountSubData, isCardMember } = props.cmData;

  return (
    <Element className={styles.tabletSubnav}>
      {props.desktopLogo && <ClickableLogo {...props.desktopLogo} />}
      <DropdownMenu theme={tabletDropdownTheme} {...props} data={props.data} />
      <CallInfo
        mobile={false}
        desktop={false}
        {...props.callInfo}
        pageName={props.pageName}
        theme={tabletCallTheme}
      />
      {userLoggedIn && isCardMember && <CardMemberLink {...viewMyAccountSubData} />}
    </Element>
  );
};

const mapStateToProps = state => {
  return {
    cmData: getCardMemberData(state)
  };
};

export default connect(mapStateToProps)(TabletSubnav);
