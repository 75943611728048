export const getEnvVariables = (force = false) => {
  if (force || (typeof process === 'object' && process.env)) {
    return process.env;
  }
  console.error(
    [
      '\n',
      new Array(100).join('*'),
      `* process.env not found. default to e3`,
      new Array(100).join('*'),
      '\n'
    ].join('\n')
  );
  return {
    NODE_ENV: 'e3'
  };
};

export const e0Server = env => {
  if (!env) {
    return false;
  }
  const environments = [env.EPAAS_ENV, env.ENV, env.NODE_ENV];
  return (
    environments.indexOf('local_development') !== -1 ||
    environments.indexOf('e0') !== -1
  );
};

export const e1Server = env => {
  if (!env) {
    return false;
  }
  const environments = [env.EPAAS_ENV, env.ENV, env.NODE_ENV];
  return environments.indexOf('e1') !== -1 || environments.indexOf('dev') !== -1;
};

export const e2Server = env => {
  if (!env) {
    return false;
  }
  const environments = [env.EPAAS_ENV, env.ENV, env.NODE_ENV];
  return environments.indexOf('e2') !== -1 || environments.indexOf('qa') !== -1;
};

export const e3Server = env => {
  if (!env) {
    return false;
  }
  const environments = [env.EPAAS_ENV, env.ENV, env.NODE_ENV];
  return (
    environments.indexOf('e3') !== -1 || environments.indexOf('production') !== -1
  );
};

export const defaultServer = env => {
  if (e3Server(env)) {
    return true;
  }
  console.error(
    [
      '\n',
      new Array(100).join('*'),
      `* no default server environment found. server will default to e3`,
      new Array(100).join('*'),
      '\n'
    ].join('\n')
  );
  return true;
};

export const getEnv = env => {
  const environments = env || getEnvVariables();
  const fns = [
    [e3Server, 'e3'],
    [e2Server, 'e2'],
    [e1Server, 'e1'],
    [e0Server, 'e0'],
    [defaultServer, 'e3']
  ];

  for (var i = 0, len = fns.length; i < len; i++) {
    if (fns[i][0](environments)) {
      return fns[i][1];
    }
  }
};

export const setEnv = (process, key, value) => {
  if (!process) {
    return false;
  }
  //NOTE: handle webpack stringify version of process.env
  process.env[key] = value;
  return true;
};
