import isProduction from 'src/universal/utils/isProduction';
import { ONE_XP_DATA_HAS_ARRIVED, ONE_XP_TIMEDOUT } from 'src/universal/redux/oneXp';

export const updateExperimentState = () => {
  const {
    sdkClient,
    oneXpStateUpdater,
    testNLearn,
    location,
    trackIt,
    digitalData
  } =
    window || {};
  setTimeout(() => {
    oneXpStateUpdater && oneXpStateUpdater.dispatchOneXpAction(ONE_XP_TIMEDOUT);
  }, !isProduction() ? 10000 : 3000);
  sdkClient &&
    sdkClient
      .findVariants({
        locator: location.href,
        fetchOpts: {
          credentials: 'include',
          mode: isProduction() ? 'same-origin' : 'cors'
        }
      })
      .then(experiments => {
        if (Array.isArray(experiments)) {
          // populate digital data CSR
          if (digitalData && digitalData.campaigns) {
            while (digitalData.campaigns.length > 0) {
              digitalData.campaigns.pop();
            }
            experiments.forEach(experiment => {
              digitalData.campaigns.push({
                ...experiment,
                tags: experiment.tags || []
              });
            });
          }
          experiments.forEach(experiment => {
            trackIt &&
              trackIt.trackExperiment({
                ...experiment,
                dimensions: experiment.dimensions.map(dimension => ({
                  ...dimension,
                  impressed: false
                })),
                source: 'ONE XP'
              });
          });
          oneXpStateUpdater &&
            oneXpStateUpdater.dispatchOneXpAction(
              ONE_XP_DATA_HAS_ARRIVED,
              experiments
            );
        }
      });

  if (testNLearn) {
    testNLearn.timeOut && clearTimeout(testNLearn.timeOut);
    testNLearn.initalize({ timeoutOverride: !isProduction() ? 10000 : 3000 });
  }
};
