import queryString from 'qs';
import { isServer, isBrowser } from 'acq-utils/lib/utils';
import url from 'url';

const getQueryParams = () => {
  let params = {};

  if (isServer() && global.reqUrl) {
    const { query = '' } = url.parse(global.reqUrl);
    if (query) {
      params = queryString.parse(query);
    }
  } else if (isBrowser()) {
    params = queryString.parse(window.location.search.slice(1));
  }

  return params;
};

export default getQueryParams;
