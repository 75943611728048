'use strict';

import { store } from './store';

export default function(path, onEnterHandler) {
  return function(location, replace, next) {
    store.dispatch({
      type: `URL_ROUTE[${path}]`,
      data: location
    });

    return onEnterHandler(location, replace, next);
  };
}
