/**
 * Used when you need to await a certain condition before continuing
 * @param {@callback} condition - function containing the condition you'd like to wait for
 * @param {string} logName - logs this if the maxWait time is reached
 * @param {string} waitTime - max time to wait before rejecting
 * @returns {Promise<boolean>}
 * @example 
     getWaitUntilConditionPromise({
       condition: () => window._satellite && window._satellite.getVisitorId,
       logName: 'ensureAdobeScriptHasLoaded',
       waitTime
     });
 */
const getWaitUntilConditionPromise = ({
  condition,
  logName = 'getWaitUntilConditionPromise',
  waitTime = 2000
}) =>
  new Promise((resolve, reject) => {
    if (condition()) {
      resolve(true);
    } else {
      const maxWait = Date.now() + waitTime;
      const intervalId = setInterval(() => {
        const stopInterval = () => clearInterval(intervalId);
        if (Date.now() > maxWait) {
          stopInterval();
          reject(`${logName} ${waitTime}ms max reached`);
        } else if (condition()) {
          stopInterval();
          resolve(true);
        }
      }, 100);
    }
  });

export default getWaitUntilConditionPromise;
