import { useCallback, useEffect } from 'react';
import { isBrowser } from 'acq-utils/lib/misc';
import { hasMemberInfoArrived } from 'src/universal/redux/cardMemberInfo.js';
import { isCardMember } from 'src/universal/redux/userType';
import {
  isLoggedOutGCS,
  isLoggedOut,
  isLoggedIn,
  isLoggedInCardMember
} from 'src/universal/redux/userType';
import { getViewMyAccountSubData, appendRedirectQuery } from './redux';

export const isIpadOS = () => {
  return navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
};

export const getCardMemberData = state => {
  let isUserLoggedIn;
  let isUserLoggedOut;
  let pageName = state.activePageName;

  if (isBrowser() === true) {
    isUserLoggedIn = isLoggedInCardMember(state);
    isUserLoggedOut = isLoggedOutGCS(state);
  } else {
    isUserLoggedIn = isLoggedIn(state);
    isUserLoggedOut = isLoggedOut(state);
  }
  const viewMyAccountSubContent = getViewMyAccountSubData(state);

  return {
    userLoggedIn: isUserLoggedIn,
    userLoggedOut: isUserLoggedOut,
    memberDataArrived: hasMemberInfoArrived(state),
    viewMyAccountSubData: appendRedirectQuery(viewMyAccountSubContent) || {},
    pageName,
    isCardMember: isCardMember(state)
  };
};

const MOUSE_UP = 'mouseup';
const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keyup';
const SCROLL = 'scroll';

export const useScroll = handleClose => {
  const handleScroll = useCallback(
    event => {
      if (event.type === SCROLL) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(
    () => {
      document.addEventListener(SCROLL, handleScroll);
      return () => {
        document.removeEventListener(SCROLL, handleScroll);
      };
    },
    [handleScroll]
  );
};

export const useOutsideClick = (handleClose, ref) => {
  const handleClick = useCallback(
    event => {
      if (
        typeof ref?.current?.contains === 'function' &&
        !ref.current.contains(event.target)
      ) {
        if (typeof handleClose === 'function') {
          handleClose();
        }
      }
    },
    [handleClose, ref]
  );

  useEffect(
    () => {
      document.addEventListener(MOUSE_UP, handleClick);

      return () => {
        document.removeEventListener(MOUSE_UP, handleClick);
      };
    },
    [handleClick]
  );
  return { handleClose };
};

export const useEscapeKey = handleClose => {
  const handleEscKey = useCallback(
    event => {
      if (event.key === KEY_NAME_ESC) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(
    () => {
      document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

      return () => {
        document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
      };
    },
    [handleEscKey]
  );
};

export const getPageTypeVal = (pageName, segmentParams) => {
  let pageTypeVal = pageName;

  if (pageName === 'corpSegment') {
    pageTypeVal = `${pageName}-${segmentParams}`;
  }
  return pageTypeVal;
};
