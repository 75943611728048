import React from 'react';

// components
import {
  Element,
  FontType,
  Link,
  Typography
} from '@americanexpress/aqx-components';
// utils
import clsx from 'clsx';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';

// styles
import styles from '../__styles__/index.scss?modules';
import { tabletCallTypographyTheme } from '../__styles__/themes';

const NumberComponent = ({ desktop, numberObject, dtm, pageName }) => {
  if (desktop) {
    return <span>{numberObject[pageName].contactNumber}</span>;
  } else {
    return (
      <Link
        href={`tel:${numberObject[pageName].contactNumber}`}
        onClick={() => sendDTMClickEvent(dtm)}
        aria-labelledby="callInfo"
      >
        {numberObject[pageName].contactNumber}
      </Link>
    );
  }
};

export const CallInfo = ({
  text,
  dtm,
  numberArray,
  pageName,
  theme,
  mobile,
  desktop,
  isLoggedInCardMember = false
}) => {
  const renderCallInfo = (numberArray ?? []).find(x => x.pageId === pageName);

  if (!renderCallInfo) {
    return null;
  }

  const numberObject = parseArrayToObject(numberArray, 'pageId');

  const numberProps = {
    desktop,
    numberObject,
    dtm,
    pageName
  };

  return (
    <Element
      id="callInfo"
      className={clsx(
        styles.callInfo,
        styles.dropdownbutton,
        isLoggedInCardMember ? styles.cardMemberCallInfo : styles.prospectCallInfo
      )}
      style={{ zIndex: 500 }}
      theme={{
        ...theme
      }}
    >
      <Typography
        $family={FontType.HelveticaRegular}
        $size={!mobile ? '10px' : '14px'}
        theme={!mobile && tabletCallTypographyTheme}
        $lineHeight={!mobile && 12}
      >
        {text}
        <NumberComponent {...numberProps} />
      </Typography>
    </Element>
  );
};
