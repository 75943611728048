import enAuData from './en_au/error-page-data.json';
import enUkData from './en_uk/error-page-data.json';
import enUsData from './en_us/error-page-data.json';
import frFrData from './fr_fr/error-page-data.json';
import jaJpData from './ja_jp/error-page-data.json';
import deDeData from './de_de/error-page-data.json';

export const errorPageData = {
  'en-au': enAuData,
  'en-uk': enUkData,
  'en-us': enUsData,
  'fr-fr': frFrData,
  'ja-jp': jaJpData,
  'en-jp': jaJpData,
  'de-de': deDeData
};
