import { isServer } from 'acq-utils/lib/misc';
import { isCardMember } from 'src/universal/redux/userType.js';
import isProduction from '/src/universal/utils/isProduction.js';
import useProductionFeatureFlag from 'src/universal/utils/useProductionFeatureFlag.js';
import { appendDnBScripts } from 'acq-components/lib/utils/SharedScripts/dunBradstreetScripts.js';

let isDnbScriptActivated = false;

export const dnbScriptMiddleware = store => next => action => {
  try {
    if (
      isServer() ||
      window.sessionStorage.getItem('gcs_dnb_Data') ||
      isDnbScriptActivated
    ) {
      return next(action);
    }
  } catch (e) {
    console.error('error accessing storage', e);
    return next(action);
  }

  const state = store.getState();
  const isCommercial =
    window.location.href.indexOf('/us/') > -1 ||
    window.location.href.indexOf('/en-us/') > -1;
  let noSessionData;
  try {
    noSessionData = !window.sessionStorage.getItem('gcs_dnb_Data');
  } catch (e) {
    noSessionData = true;
  }

  const restrictToE3 = useProductionFeatureFlag() || isProduction();

  if (restrictToE3 && isCommercial && !isCardMember(state) && noSessionData) {
    isDnbScriptActivated = true;
    appendDnBScripts();
  }

  return next(action);
};
