import { createCorrelationId } from 'src/laas-utils';
import { isServer } from 'src/universal/utils/browser-detection/index';

export const AMEX_API_KEY = 'NyPmLUPdYKHZwGj1onApG12MC4brWUVd';
const e1DeveloperApp = '200005363CardShop';
export const laasUrlE1 =
  'https://api.dev.americanexpress.com/servicing/v1/laas/logs';

export const application = () => {
  return {
    version: '1.0.0',
    type: 'REPORT',
    application: {
      project: 'dxpcardappintra',
      portfolio: 'apply',
      name: e1DeveloperApp,
      type: 'WEB_APP'
    }
  };
};

export const environment = (data = {}) => {
  return {
    environment: Object.assign(
      {
        renderingEngine: isServer() ? 'SSR' : 'CSR'
      },
      data,
      {
        name: 'E1',
        containerType: 'BROWSER'
      }
    )
  };
};

export const header = (data = {}) => {
  return {
    headers: Object.assign(
      {
        url: isServer() ? void 0 : location.href,
        referrerUrl: isServer() ? void 0 : document.referrer,
        userAgent: isServer() ? void 0 : navigator.userAgent,
        clientIp: null,
        locale: 'en-US',
        httpStatusCode: null,
        responseSize: null,
        saneId: null,
        sessionToken: null,
        publicGuid: null
      },
      data
    )
  };
};

export const event = data => {
  return Object.assign(
    {
      functionName: null,
      status: null,
      message: {},
      responseTime: null
    },
    data,
    {
      timestamp: new Date().toISOString(),
      correlationId: createCorrelationId()
    }
  );
};
