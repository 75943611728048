/* 
Utils functions for error pages
*/

export const getErrorPageCountryCode = cc => {
  switch (cc) {
    case 'de-de':
    case 'de':
      return 'de-de';
    case 'it-it':
    case 'it':
      return 'it-it';
    case 'fr-fr':
    case 'fr':
      return 'fr-fr';
    case 'en-au':
    case 'au':
      return 'en-au';
    case 'en-jp':
    case 'ja-jp':
    case 'jp':
      return 'ja-jp';
    default:
      return 'en-us';
  }
};

export const getRedirectRoute = cc => {
  switch (cc) {
    case 'de-de':
    case 'de':
      return 'www.americanexpress.de';
    case 'it-it':
    case 'it':
      return '/it-it/aziende/carte-di-pagamento/';
    case 'fr-fr':
    case 'fr':
      return '/fr-fr/entreprise/cartes-business/';
    case 'en-au':
    case 'au':
      return '/au/business/business-credit-cards';
    case 'en-jp':
    case 'ja-jp':
    case 'jp':
      return '/ja-jp/ビジネス/ビジネスカード';
    default:
      return '/us/credit-cards/business/business-credit-cards';
  }
};

export const getAPICountryCode = cc => {
  switch (cc) {
    case 'de-de':
    case 'de':
      return 'de_de';
    case 'it-it':
    case 'it':
      return 'it_it';
    case 'fr-fr':
    case 'fr':
      return 'fr_fr';
    case 'en-au':
    case 'au':
      return 'en_au';
    case 'en-jp':
    case 'ja-jp':
    case 'jp':
      return 'ja_jp';
    default:
      return 'us';
  }
};
