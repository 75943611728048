import get from 'lodash.get';
import cloneDeep from 'clone-deep';
import { createSelector } from 'src/universal/redux/utils';
import { getPageState } from 'src/universal/redux/page';
import isProduction from 'src/universal/utils/isProduction';

export const getViewMyAccountSubData = createSelector(
  getPageState,
  pageState =>
    get(pageState, `global.globalHeaderPage.subNavViewAccount`) ||
    get(pageState, `globalData.globalHeaderPage.subNavViewAccount`) ||
    {}
);

export const appendRedirectQuery = (viewMyAccountSubData = {}) => {
  const clone = cloneDeep(viewMyAccountSubData);
  const isProd = isProduction();
  const { e2, e3 } = clone;

  if (isProd) {
    clone.url = e3;
  } else {
    clone.url = e2;
  }
  return clone;
};
