import { isBrowser as isBrowserFunc } from 'acq-utils/lib/misc';

import { getPage } from 'page_modules/active-page';
import { isMobileDevice } from 'src/universal/utils/browser-detection/index';
import {
  getDeviceType,
  getNetworkType
} from 'core_modules/bootstrap/browsers/browser-detection';

import { loggerFunc } from './initLogger';

const isMobile = isMobileDevice();
const deviceType = getDeviceType();
const network = getNetworkType();
const isBrowser = isBrowserFunc();
let logger;

const isomorphicLogger = (eventName, payload, isError = false) => {
  if (!logger) {
    logger = loggerFunc();
  }

  const modifiedPayload = {
    renderType: isBrowser ? 'csr' : 'ssr',
    url: isBrowser ? window.location.href : global.reqUrl,
    isMobile,
    deviceType,
    network,
    pageName: getPage(),
    ...payload
  };
  const stringPayload = JSON.stringify(modifiedPayload);
  const sendLog = () =>
    isError
      ? logger.error(eventName, stringPayload)
      : logger.info(eventName, stringPayload);

  if (isBrowser) {
    setTimeout(sendLog, 0);
    return;
  }

  sendLog();
};

export default isomorphicLogger;
