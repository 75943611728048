import { setPage } from 'page_modules/active-page';
import { buildUrl } from 'core_modules/utils/url.utils';
import { US_BASE_URL, BUSINESS_CARD_PATH } from 'src/routes';
import { injectReducerToStore } from 'src/universal/redux/utils';
import { name as pageName } from '../__styles__/pageVars.scss';

const path = buildUrl(US_BASE_URL, BUSINESS_CARD_PATH);

const businessHubRouteInit = store => ({
  path,
  getComponent: (_, callback) => {
    require.ensure(
      [],
      require => {
        const PageComponent = require('../').default;
        injectReducerToStore(store, PageComponent);
        callback(null, PageComponent);
      },
      null,
      'businessHub'
    );
  },
  onEnter: (_, __, next) => {
    setPage(pageName);
    next();
  }
});

export { businessHubRouteInit };
