import { graphqlService } from 'acq-gcs-pages/lib/universal/services/graphql-service';
import { translateCardShortName } from 'acq-gcs-pages/lib/universal/translations';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import getCountryCode from 'src/util-getCountryCodeFromUrl';

const pagesWithGraphqlService = ['vac', 'pdp', 'compare'];

export const graphQlMiddleware = store => next => action => {
  if (action.type !== 'FETCH_DATA') {
    return next(action);
  }

  const { countryCode, language } = getCountryCode(action.payload);
  action.payload.countryCode = `${language}_${countryCode}`;

  if (pagesWithGraphqlService.includes(action.payload.page)) {
    return graphqlService({
      store,
      action,
      next
    });
  } else {
    // TNC CASE
    const params = action.payload;

    const variables = {
      country: params.countryCode,
      shop: params.shop,
      page: translateCardShortName(params.countryCode, params.page)
    };

    return ajax
      .graphQLPost(
        {
          query: params.query,
          variables
        },
        {
          'set-cookie': params['set-cookie'],
          queryName: `${variables.page}_${variables.shop}_query`.toUpperCase(),
          skipParser: true
        }
      )
      .then(response => {
        const { status, data } = response;

        if (status !== 200) {
          throw new Error(
            `GRAPHQL request failed: ${JSON.stringify(response, null, 2)}`
          );
        } else if (data && !data.cardShop) {
          throw new Error(`GRAPHQL<404>: ${JSON.stringify(response, null, 2)}`);
        }

        return data;
      })
      .then(({ cardShop }) => {
        return next({
          type: 'PAGE_DATA_RECEIVED',
          payload: cardShop
        });
      });
  }
};
