import { injectReducerToStore } from 'src/universal/redux/utils';
import { setPage } from 'page_modules/active-page';

const pageNamePdp = 'businessPDP';
export const getPdpComponent = ({ store, cb }) => {
  require.ensure(
    [],
    require => {
      const PageComponent = require('page_modules/business-pdp').default;
      PageComponent.WrappedComponent.chunkName = pageNamePdp;
      setPage(pageNamePdp);
      injectReducerToStore(store, PageComponent);
      cb(null, PageComponent);
    },
    null,
    'businessPDP' //this must be a literal value
  );
};
