/* eslint-disable no-prototype-builtins */
// Explained here: https://redux.js.org/recipes/reducing-boilerplate#generating-reducers
export const reducerGenerator = (initialState = null, handlers = {}) => {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else if (
      typeof handlers === 'function' &&
      handlers().hasOwnProperty(action.type)
    ) {
      return handlers()[action.type](state, action);
    } else {
      return state;
    }
  };
};
