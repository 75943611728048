/* eslint-disable no-async-promise-executor */
import get from 'lodash.get';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import dtmEvents from 'service_modules/models/dtm-tagging';
import { getPage } from 'page_modules/active-page';
import { getCardInfo } from 'service_modules/models/card-names/card-names.model';
import { mapCardNameToShortName } from 'page_modules/corp-pdp/utils';
import { isBrowser } from 'acq-utils/lib/misc';

import { executeReduxActionSafely, executeSafely, reportError } from './misc';
import { reducerGenerator } from './reducerGenerator';

export class InitFetchPageDataHelper {
  constructor({
    pageName,
    customReducer,
    debugMode = false,
    shouldDispatchDtmDataReady = true
  }) {
    this.funcName = InitFetchPageDataHelper.name;
    this.pageName = pageName;
    if (!this.pageName) {
      throw new Error(`${this.funcName}: pageName must be passed as parameter`);
    }
    this.customReducer = customReducer || {};
    this.debugMode = debugMode;
    this.reqType = `REQ_${pageName}_DATA`;
    this.errType = `ERR_${pageName}_DATA`;
    this.resType = `RES_${pageName}_DATA`;
    this.shouldDispatchDtmDataReady = shouldDispatchDtmDataReady;
  }

  reqDataAction = () => executeReduxActionSafely(this.reqType);
  errDataAction = () => executeReduxActionSafely(this.errType);
  resDataAction = response =>
    executeReduxActionSafely(this.resType, {
      payload: {
        ...response.data,
        httpStatus: response.status,
        networkStatus: 'complete'
      }
    });

  fetchData = (config, routeInfo) => {
    const getAPIPageName = routeInfo => {
      switch (this.pageName) {
        case 'corpCategory':
          return 'category';
        case 'corpSegment':
          return get(routeInfo, 'params.segment');
        case 'businessHub':
          return 'home';
        case 'businessCardsV2':
          return 'vac';
        case 'compareCards':
          return 'compare';
        case 'businessPDP':
          return getCardInfo(get(routeInfo, 'params.cardName') || {}).shortName;
        case 'corpPDP':
          return mapCardNameToShortName(get(routeInfo, 'params.cardName') || {});
        default:
          return undefined;
      }
    };
    const convertPreselected = urlParam => {
      const preselectedPageNames = {
        'best-business-credit-cards': 'best',
        'compare-cash-back-business-credit-cards': 'cashBack',
        'compare-travel-rewards-credit-cards': 'travelRewards',
        'compare-american-express-membership-rewards-credit-cards':
          'membershipRewards',
        'compare-no-annual-fee-credit-cards': 'noAnnualFee',
        'compare-flexible-payment-credit-cards': 'flexPay',
        'delta-credit-cards': 'airlinePartner'
      };

      return preselectedPageNames[urlParam];
    };
    const apiPageName = getAPIPageName(routeInfo);
    const preselectedPageName = convertPreselected(get(routeInfo, 'params.param1'));
    const shop = this.pageName.includes('corp') ? 'corporate' : 'business';
    return executeSafely(
      apiPageName,
      () =>
        ajax.get(
          `open/content/v2/us/${shop}/${apiPageName}${
            preselectedPageName ? `?preselected=${preselectedPageName}` : ''
          }`,
          {
            ...config
          }
        ),
      `${this.funcName}: ${this.pageName} is not valid`
    );
  };

  fetchDataCSR = routeInfo => dispatch =>
    executeSafely(
      dispatch,
      async () => {
        try {
          dispatch(this.reqDataAction());
          const response = await this.fetchData({}, routeInfo);

          if (this.debugMode) {
            console.log(`${this.funcName}: fetchDataCSR response ${response}`);
          }
          dispatch(this.resDataAction(response));
        } catch (err) {
          reportError(err, 'fetch-csr-error', dispatch, this.errType);
        }
      },
      `${this.funcName}: dispatch must be passed to fetchDataCSR`
    );

  fetchDataSSR = ({ config = {}, routeInfo = {} }) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await this.fetchData(config, routeInfo);
        if (this.debugMode) {
          console.log(`${this.funcName}: fetchDataSSR data`, response.data);
        }
        resolve({
          dataForServerRender: {
            networkStatus: 'complete',
            ...response.data,
            error: void 0
          },
          headers: { 'set-cookie': config['set-cookie'] }
        });
      } catch (err) {
        reportError(err, 'fetch-ssr-error');
        reject(err);
      }
    });

  didLoadingFinish = (data, cardShortName) =>
    (cardShortName ? cardShortName === get(data, 'cards[0].shortName') : true) &&
    getPage() === this.pageName &&
    (data.networkStatus === 'error' || data.networkStatus === 'complete');

  didLoadingSucceed = state => state.networkStatus === 'complete';
  dispatchDtmPageLoad = dispatch =>
    executeSafely(
      dispatch,
      () =>
        dtmEvents.dispatch({
          type: 'DTM_PAGE_LOAD',
          data: { viewDispatch: dispatch }
        }),
      `${this.funcName}: dispatchDtmPageLoad - dispatch not passed as param`
    );
  dispatchDtmDataReady = (pageName, subCategory2 = '', subCategory3 = '') =>
    executeSafely(
      pageName,
      () => {
        dtmEvents.dispatch({
          type: 'DTM_DATA_READY',
          dtmTag: {
            pageName,
            subCategory2,
            subCategory3
          }
        });
        if (isBrowser()) {
          window.dtmPageAttributesReady = true;
        }
      },
      `${this.funcName}: dispatchDtmDataReady - pageName not passed as param`
    );

  pageDataSetup = (PageComponent, displayName) => {
    PageComponent.mapSsrFetchToStore = fetchedData => ({
      [this.pageName]: fetchedData
    });
    PageComponent.fetchData = (routeInfo, config = {}) =>
      this.fetchDataSSR({ config, routeInfo });
    PageComponent.reducer = { name: this.pageName, func: this.reducer };
    PageComponent.action = this.resType;
    PageComponent.displayName = displayName;
    PageComponent.chunkName = this.pageName;
    PageComponent.pageName = this.pageName;
  };

  reducer = reducerGenerator(
    {
      httpStatus: undefined,
      page: {},
      global: {},
      TNL: {},
      networkStatus: undefined
    },
    () => ({
      [this.reqType]: state => ({
        ...state,
        networkStatus: 'pending'
      }),
      [this.resType]: (state, { payload }) => {
        const dtm = get(payload, 'page.dtm') || {};
        const { pageName, subCategory2, subCategory3 } = dtm;
        this.shouldDispatchDtmDataReady &&
          this.dispatchDtmDataReady(pageName, subCategory2, subCategory3);

        if (this.debugMode) {
          console.log(`${this.funcName}: reducer ${this.resType} payload`, payload);
        }
        return {
          ...state,
          ...payload,
          networkStatus: 'complete'
        };
      },
      [this.errType]: state => ({
        ...state,
        networkStatus: 'error'
      }),
      ...this.customReducer
    })
  );
}
