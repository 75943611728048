'use strict';

/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */

export const PERFORMANCE_BASE_URL = 'log/performance/page';
import Cookies from 'js-cookie';
import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';
import isE2 from 'src/universal/utils/isE2';
import 'acq-laas-queue/lib/csr-request-idle-callback';
import 'core_modules/utils/log.polyfill';
import 'core_modules/utils/jquery-mock';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { isServer } from 'src/universal/utils/browser-detection/index';
import * as aexpDevTools from 'src/aexp-dev-tools';
require('service_modules/utils/window-cookies').exposeToGlobal();

import {
  getUserType,
  perfLogUserTypeCardMember,
  perfLogUserTypeProspect
} from 'src/universal/utils/getUserType';

const storage = browserStorage(STORAGE.session);

if (!isServer()) {
  for (const prop in aexpDevTools) {
    if (aexpDevTools.hasOwnProperty(prop)) {
      window[prop] = aexpDevTools[prop];
    }
  }

  // scope to e1 or e2 envs
  if (__DEV__ || isE2()) {
    // Cumulative Layout Shift (CLS) is a user-centric metric for visual stability
    let cumulativeLayoutShift = 0;
    const observer = new PerformanceObserver(list => {
      list.getEntries().forEach(entry => {
        // Don't count if the layout shift is a result of user interaction
        if (!entry.hadRecentInput) {
          cumulativeLayoutShift += entry.value;
          console.log('CLS IMPACTED', { entry, cumulativeLayoutShift });
        }
      });
    });

    // `buffered: true` to report layout shifts that have already happened on the page
    observer.observe({ type: 'layout-shift', buffered: true });
  }
}

export const perfUserType = getUserType(Cookies.get())[1]
  ? perfLogUserTypeCardMember
  : perfLogUserTypeProspect;

export function postPerformance(url, data) {
  let stringData = JSON.stringify(data).replace(/"/g, '');

  if (url === '/gcp-last-cardart-rendered') {
    const perfArray = performance.getEntriesByName('first-contentful-paint');

    if (perfArray && perfArray.length) {
      const [{ startTime: firstPaint }] = perfArray;
      data.viewportLoaded = firstPaint;
    }
    data['user-type'] = perfUserType;
    stringData = JSON.stringify(data).replace(/"/g, '');
  }
  setTimeout(() =>
    ajax.post(PERFORMANCE_BASE_URL + url, '::' + stringData + '::', {
      contentType: 'text/plain',
      dataType: 'text',
      processData: false
    })
  );
}

window.postPerformance = postPerformance;

if (Array.isArray(window.oneXpErrors) && window.oneXpErrors.length > 0) {
  window.oneXpErrors.forEach(err => {
    postPerformance('/one-xp-errors', err);
  });
  window.oneXpErrors.length = 0;
}

postPerformance(
  '/gcp-cardshop-load',
  browserResourceTiming.appStart('gcp', {
    'git-previous-commit': process.env.GIT_PREVIOUS_COMMIT,
    'git-current-commit': process.env.GIT_COMMIT,
    'user-type': perfUserType
  })
);

window.addEventListener('load', () => {
  browserResourceTiming.appCompleteST();
  postPerformance(
    '/gcp-cardshop-load',
    browserResourceTiming.appEnd('gcp', {
      ...window.page_load_time,
      'user-type': perfUserType
    })
  );
});

const isEEP = EEP => {
  return typeof EEP === 'string' && /^\d+$/.test(EEP);
};

const DEFAULT_EEP = '45094';

const getSessionEEP = () => {
  return storage ? storage.get('eep') : null;
};

const getServerEEP = () =>
  Object.values(window.initialData)
    .map(
      value => value && value.globalData && value.globalData.eepValUsedAtServerSide
    )
    .filter(value => value)[0];

const getPathEEP = () => {
  const pathName = window.location.pathname;
  const lastChar = pathName[pathName.length - 1];
  if (lastChar === '/') {
    return window.location.pathname.split('/').slice(-2)[0];
  }
  return window.location.pathname.split('/').slice(-1)[0];
};

const getEEP = () => {
  // an array of functions that return possible EEPs
  const candidateEEPs = [getSessionEEP, getServerEEP, getPathEEP];
  // Find and return the first valid candidate EEP (or default EEP)
  return (
    candidateEEPs.reduce((eep, getCandidateEEP) => {
      if (eep) {
        return eep;
      }
      try {
        const candidateEEP = getCandidateEEP();
        if (isEEP(candidateEEP)) {
          return candidateEEP;
        }
      } catch (e) {
        return null;
      }
      return null;
    }, null) || DEFAULT_EEP
  );
};

//Set entryPointEEP
const setEntrypointEEP = () => {
  try {
    if (
      window.digitalData &&
      window.digitalData.page &&
      window.digitalData.page.attributes
    ) {
      const eep = getEEP();
      if (storage) {
        storage.set('eep', eep);
      }
      window.digitalData.page.attributes.entrypoint = eep;
    }
  } catch (e) {
    console.error(e);
  }
};

window.requestIdleCallback(setEntrypointEEP);
