import get from 'lodash.get';
import { isUS } from 'src/universal/utils/isUSMarket';
import { isServer } from 'acq-utils/lib/misc';
import { dispatchResetPznAndCardMemberData } from 'src/universal/redux/pznData';
import { dispatchResetOneXpState } from 'src/universal/redux/oneXp';

export const resetStatesOnNavigation = store => next => action => {
  if (
    isServer() ||
    action.type !== '@@router/LOCATION_CHANGE' ||
    !isUS() ||
    __DEV__
  ) {
    return next(action);
  }

  const state = store.getState();
  const isCsr = get(state, 'routing.locationBeforeTransitions') !== null;

  if (isCsr) {
    next(action);
    dispatchResetOneXpState(store.dispatch);
    return dispatchResetPznAndCardMemberData(store.dispatch);
  } else {
    return next(action);
  }
};
