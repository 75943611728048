import get from 'lodash.get';

import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import removeTagsAndTheirContent from 'service_modules/utils/text-remove-tags';

import { action, createSelector, reducerGenerator } from './utils';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import useMock from 'src/universal/utils/useMock';
import cardMemberDataMockData from '__mocks__/cardMemberData';
import { RESET_PZN_CARDMEMBER_DATA } from 'src/universal/redux/pznData';
import { capitalizeFirstLetter } from 'src/universal/utils/capitalizeFirstLetter';

// actionTypes
export const SET_CARD_MEMBER_DATA = 'SET_CARD_MEMBER_DATA';

// Reducer
const defaultState = {
  personalInfo: {
    fullName: {
      firstName: ''
    },
    nameOnCard: '',
    phoneNumbers: []
  },
  userCards: [],
  cardMemberInfoArrived: false
};
export const cardMemberInfoReducer = reducerGenerator(defaultState, {
  [SET_CARD_MEMBER_DATA]: (state, action) => ({
    ...state,
    ...action.payload,
    cardMemberInfoArrived: true
  }),
  [RESET_PZN_CARDMEMBER_DATA]: () => defaultState
});

// Actions
export const dispatchSetCardMemberDataAction = (dispatch, cardMemberData) => {
  dispatch(
    action(SET_CARD_MEMBER_DATA, {
      payload: useMock() ? cardMemberDataMockData : cardMemberData
    })
  );
};

// Selectors
export const getCardMemberInfo = state => state.cardMemberInfo || {};
export const isGCSMember = createSelector(
  getCardMemberInfo,
  cardMemberInfo => !!cardMemberInfo.isGCS
);
export const isCPSMember = createSelector(
  getCardMemberInfo,
  cardMemberInfo => !!cardMemberInfo.isCPS
);
export const isCPSMemberOnly = createSelector(
  [isCPSMember, isGCSMember],
  (isCPS, isGCS) => isCPS && !isGCS
);
export const isUserLoggedIn = createSelector(
  // this value is directly coming from backend via secondary PZN request.
  getCardMemberInfo,
  cardMemberInfo => !!cardMemberInfo.isLoggedIn
);
export const getCardmemberPersonalInfo = createSelector(
  getCardMemberInfo,
  cardMemberInfo => cardMemberInfo.personalInfo
);

export const getCardMemberFirstName = createSelector(
  getCardmemberPersonalInfo,
  personalInfo => capitalizeFirstLetter(personalInfo?.firstName ?? '')
);
export const getCardMemberCards = createSelector(
  getCardMemberInfo,
  cardMemberInfo => cardMemberInfo.userCards || []
);
export const getCardMemberConsumerCardTitles = createSelector(
  getCardMemberInfo,
  cardMemberInfo =>
    (cardMemberInfo.consumerCards || []).map(card => removeTagsAndTheirContent(card))
);

export const getCardMemberCardsByPmc = createSelector(getCardMemberCards, cards =>
  cards.reduce((cards, card) => {
    cards[card.pmcCode] = card;
    return cards;
  }, {})
);
export const getUserCard = pmcCode =>
  createSelector(getCardMemberCardsByPmc, cardsByPmc => cardsByPmc[pmcCode]);
export const getIsUserCard = pmcCode =>
  createSelector(getUserCard(pmcCode), userCard => !!userCard || false);
export const isCardMemberCardsLoaded = createSelector(
  getCardMemberCards,
  usercards => usercards && !!usercards.length
);
export const isCardMemberCardsEmpty = createSelector(
  isCardMemberCardsLoaded,
  isCardsLoaded => !isCardsLoaded
);
export const hasMemberInfoArrived = createSelector(
  getCardMemberInfo,
  cardMemberInfo => cardMemberInfo.cardMemberInfoArrived
);
export const isSupplementaryCardUser = createSelector(
  [hasMemberInfoArrived, getCardMemberInfo],
  (isMemberInfoArrived, { isGCSSupp }) => isMemberInfoArrived && !!isGCSSupp
);
export const isLegacyGcsCardMember = createSelector(
  [hasMemberInfoArrived, getCardMemberInfo],
  (isMemberInfoArrived, { isGCSLegacy }) => isMemberInfoArrived && !!isGCSLegacy
);
export const isOtherMemberType = createSelector(
  [hasMemberInfoArrived, getCardMemberInfo],
  (isMemberInfoArrived, { isOtherType }) => isMemberInfoArrived && !!isOtherType
);
export const getMemberCardTitles = createSelector(
  [getCardMemberCards, getCardMemberConsumerCardTitles],
  (cardsData, consumerCardsTitles) => [
    ...cardsData.map(card => card.cardTitle),
    ...consumerCardsTitles
  ]
);
export const getMemberCardShortNames = createSelector(
  getCardMemberCards,
  cardsData => cardsData.map(card => card.shortName)
);
export const getCardMemberHeroCardData = createSelector(
  getCardMemberCards,
  cardsData => {
    const buildUrl = card => {
      const url = get(card, 'learnMore.url');
      const dtmObj = {
        type: get(card, 'learnMore.dtmTag.type'),
        tag: `Hero-${get(card, 'learnMore.dtmTag.productName')}`
      };
      return mapUrlWithDtmLinkQuery(url, dtmObj);
    };
    const cardsList = cardsData.map(card => ({
      src: get(card, 'appAssets.cardArt') || '',
      url: buildUrl(card),
      alt: card.cardTitle,
      title: replaceStringWithVars({
        varsObject: {
          cardName: card.cardTitle,
          cardNumber: card.last4acctNumber
        },
        string: 'Your ${cardName} ${cardNumber}', //TODO: should come from the backend later
        prependVars: {
          cardNumber: ' ('
        },
        appendVars: {
          cardNumber: ')'
        }
      })
    }));
    return cardsList;
  }
);
export const getCardMemberHeroTitle = title =>
  createSelector(getCardMemberFirstName, firstName => {
    const newTitle = replaceStringWithVars({
      string: title,
      varsObject: {
        userName: firstName
      },
      ...(firstName && {
        prependVars: {
          userName: ' Back, '
        }
      })
    });
    return newTitle;
  });
export const getCMTabbedHeroTitle = (title, isLoggedOut) =>
  createSelector(getCardMemberFirstName, firstName => {
    let textVal = firstName ? ` back, ${firstName}` : '';
    if (isLoggedOut) {
      textVal = '';
    }
    return replaceStringWithVars({
      string: title,
      varsObject: {
        userName: textVal
      }
    });
  });
