import { isServer } from '../browser-detection';

export const getWindowObject = () => {
  if (!isServer()) {
    return window;
  } else {
    return {
      document: { body: {}, documentElement: {} },
      screen: {}
    };
  }
};

export const getDocumentObject = () => {
  return getWindowObject().document;
};
