import React from 'react';

import { setPage } from 'page_modules/active-page';
import { injectReducerToStore } from 'src/universal/redux/utils';

const getComponent = store => ({ params }, cb) => {
  require.ensure(
    [],
    require => {
      const { pageWrapper } = require('../common/pageWrapper');
      const TermsAndConditionsPage = require('./TermsAndConditionsPage').default;
      const query = require('../common/queries/tnc').default;
      const {
        isCorporate
      } = require('acq-gcs-pages/lib/universal/utils/component-helpers');

      const WrappedPage = pageWrapper(
        props => <TermsAndConditionsPage intlPage={props} />,
        {
          query,
          isCorporate: isCorporate(params.shop),
          isTNC: true
        }
      );

      injectReducerToStore(store, WrappedPage);

      cb(null, WrappedPage);
    },
    null,
    'intlPdpTnc'
  );
};
const getVacComponent = store => ({ params }, cb) => {
  require.ensure(
    [],
    require => {
      const { pageWrapper } = require('../common/pageWrapper');
      const TermsAndConditionsVacPage = require('./TermsAndConditionsVacPage')
        .default;
      const vacQuery = require('../common/queries/vac_tnc').default;
      const {
        isCorporate
      } = require('acq-gcs-pages/lib/universal/utils/component-helpers');

      const WrappedPage = pageWrapper(
        props => <TermsAndConditionsVacPage intlPage={props} />,
        {
          query: vacQuery,
          isCorporate: isCorporate(params.shop),
          isTNC: true
        }
      );

      injectReducerToStore(store, WrappedPage);

      cb(null, WrappedPage);
    },
    null,
    'intlCorpVacTnc'
  );
};

const onEnter = function(location, replaceWith, next) {
  setPage('intlPage');
  next();
};

const auPdpTncPage = store => ({
  path: ':countryCode/business/:shop/:page/terms-and-conditions',
  getComponent: getComponent(store),
  onEnter,
  isTNCRoute: true
});
const itPdpTncPage = store => ({
  path: ':countryCode/aziende/:shop/:page/termini-e-condizioni',
  getComponent: getComponent(store),
  onEnter,
  isTNCRoute: true
});
const frPdpTncPage = store => ({
  path: ':countryCode/entreprise/:shop/:page/conditions-generales',
  getComponent: getComponent(store),
  onEnter,
  isTNCRoute: true
});
const mxPpdTncPage = store => ({
  path: ':countryCode/negocios/:shop/:page/terminos-y-condiciones',
  getComponent: getComponent(store),
  onEnter,
  isTNCRoute: true
});
const deVacTncPage = store => ({
  path: ':countryCode/business/:shop/allgemeine-geschaeftsbedingungen',
  getComponent: getVacComponent(store),
  onEnter,
  isTNCRoute: true
});

export default [
  auPdpTncPage,
  itPdpTncPage,
  frPdpTncPage,
  mxPpdTncPage,
  deVacTncPage
];
