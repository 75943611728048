export const pznPageIds = {
  businessHome: 'open_businessHome',
  businessHub: 'open_businessHome',
  businessPDP: 'open_cd',
  cardDetail: 'open_cd',
  businessCardsV2: 'open_vac',
  compareCards: 'open_cc',
  corpCategory: 'open_corpVac',
  corporatePDP: 'open_corpVac',
  corpPDP: 'open_corpVac'
};

export const getPznIdByPageName = pageName => {
  return pznPageIds[pageName] || `open_${pageName}`;
};
