import uuidv1 from 'uuid/v1';

export { uuidv1 as createCorrelationId };

export const getHeaderConfig = (apiKey, correlationId) => {
  return {
    'x-amex-api-key': apiKey,
    'x-amex-request-id': correlationId
  };
};

export const timestamp = () => new Date().toISOString();
