import { CORPORATE_CARD_DETAIL_ROUTE_PATH } from 'src/routes/index';
import { setPage } from 'page_modules/active-page';
import { injectReducerToStore } from 'src/universal/redux/utils';

export default store => ({
  path: CORPORATE_CARD_DETAIL_ROUTE_PATH,
  getComponent: (routerState, cb) => {
    require.ensure(
      [],
      require => {
        const PageComponent = require('./index.js').default;
        PageComponent.WrappedComponent.chunkName = 'corpPDP';
        PageComponent.pageName = 'corpPDP';
        setPage('corpPDP');
        injectReducerToStore(store, PageComponent);
        cb(null, PageComponent);
      },
      null,
      'corpPDP'
    );
  },
  onEnter: function(location, replaceWith, next) {
    next();
  },
  onLeave: function() {}
});
