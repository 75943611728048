import { action, reducerGenerator } from './utils';

export const ONE_XP_DATA_HAS_ARRIVED = 'ONE_XP_DATA_HAS_ARRIVED';
export const RESET_ONE_XP_STATE = 'RESET_ONE_XP_STATE';
export const ONE_XP_TIMEDOUT = 'ONE_XP_TIMEDOUT';
export const EXPERIMENTS_TRACKED = 'EXPERIMENTS_TRACKED';

const defaultState = {
  experiments: {},
  dataArrived: false,
  timedOut: false,
  experimentsTracked: false
};

export const oneXpReducer = reducerGenerator(defaultState, {
  [ONE_XP_DATA_HAS_ARRIVED]: (state, action) => {
    return Array.isArray(action.payload)
      ? {
          ...state,
          experiments: {
            ...state.experiments,
            ...action.payload.reduce((acc, curr) => {
              if (curr.name && curr.name.indexOf('GCS') > -1) {
                acc[curr.name] = curr;
              }
              return acc;
            }, {})
          },
          dataArrived: true
        }
      : state;
  },
  [ONE_XP_TIMEDOUT]: state => {
    return {
      ...state,
      timedOut: !state.dataArrived
    };
  },
  [EXPERIMENTS_TRACKED]: state => {
    return {
      ...state,
      experimentsTracked: true
    };
  },
  [RESET_ONE_XP_STATE]: () => defaultState
});

// Actions
export const dispatchResetOneXpState = dispatch => {
  dispatch(action(RESET_ONE_XP_STATE));
};

export const dispatchExperimentsTracked = dispatch => {
  dispatch(action(EXPERIMENTS_TRACKED));
};

// Selectors
export const getOneXpExperiments = state => state.oneXp.experiments;

export const hasOneXpDataArrived = state => state.oneXp.dataArrived;

export const hasOneXpTimedout = state => state.oneXp.timedOut;

export const getHaveExperimentsBeenTracked = state => state.oneXp.experimentsTracked;

export const hasOneXpRequestFinished = state =>
  hasOneXpDataArrived(state) || hasOneXpTimedout(state);

export const shouldRenderSingleVariant = (
  experiment = {},
  dimensionName,
  variant
) => {
  const targetDimension =
    experiment.dimensions &&
    experiment.dimensions.find(dimension => dimension.name === dimensionName);
  return targetDimension && targetDimension.variant === variant;
};
