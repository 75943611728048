import React from 'react';

// components
import { Element, Size, Icon } from '@americanexpress/aqx-components';
// styles
import styles from '../__styles__/index.scss?modules';

export const ArrowIcon = ({ direction }) => {
  return (
    <Element className={styles.arrowIcon} theme={{ marginLeft: 'auto' }}>
      <Icon
        directory="dls"
        imageName={`dls-icon-${direction}`}
        theme={{ paddingTop: 5 }}
        $size={Size.SM}
      />
    </Element>
  );
};
