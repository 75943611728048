const defaultLanguage = {
  us: 'en',
  au: 'en',
  mx: 'es',
  fr: 'fr',
  jp: 'ja',
  it: 'it',
  de: 'de'
};

export default function(params) {
  const countryStr = params.countryCode || '';
  const countryLanguage = countryStr.toLowerCase().split('-');
  const countryCode = countryLanguage[1] || countryLanguage[0];
  const language = countryLanguage.length > 1 && countryLanguage[0];

  return {
    countryCode,
    language: language || defaultLanguage[countryCode]
  };
}
