export const getCustomEventHandlers = dispatch => {
  const enableIdleMonitor = () => {
    dispatch({
      type: 'NEED_IDLE_MONITOR',
      value: true
    });
  };
  return {
    onPreQualResultArrival: () => {
      enableIdleMonitor();
    },
    onPreQualResultReentrance: () => {
      enableIdleMonitor();
    }
  };
};
