'use strict';

import { isServer } from 'src/universal/utils/browser-detection/index';
import { AMEX_API_KEY, laasUrlE1 } from 'src/laas-schema';

export const toJSON = data => {
  if (!data) {
    return;
  }

  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (error) {
      return;
    }
  }

  return data;
};

const post = (body = {}) => {
  if (isServer() || typeof XMLHttpRequest === 'undefined') {
    return;
  }

  let application = {
    project: 'dxpcardappintra',
    portfolio: 'apply',
    name: '200005363CardShop',
    type: 'WEB_APP'
  };

  let environment = {
    name: 'E1',
    containerType: 'BROWSER'
  };

  let headers = {
    clientIp: '12.130.146.212',
    url: body.headers.url,
    referrerUrl: body.headers.referrerUrl,
    locale: 'en-US',
    userAgent: body.headers.userAgent,
    httpStatusCode: 200,
    responseSize: 2235,
    saneId: void 0,
    sessionToken: 'NA',
    publicGuid: '81c3abb76892b8aa8f54ff4b2dc1525e'
  };

  let data = {
    version: '1.0.0',
    type: 'REPORT',
    timestamp: body.timestamp,
    correlationId: body.correlationId,
    environment: environment,
    application: application,
    headers: headers,
    functionName: body.functionName,
    status: body.status,
    message: body.message,
    responseTime: body.responseTime
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', laasUrlE1, true);
  xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
  xhr.setRequestHeader('x-amex-api-key', AMEX_API_KEY);
  xhr.setRequestHeader('x-amex-request-id', body.correlationId);
  xhr.onload = function() {
    console.log(`[${xhr.responseText}] ${body.correlationId}`);
    data = null;
  };
  xhr.send(JSON.stringify(data));
};

export default post;
