// tip: prequal may need this for client side routing ;). ask AA
const dataMapperMap = {};

export const dataMapperMiddleware = store => next => action => {
  if (action.type !== 'PAGE_DATA_RECEIVED') {
    return next(action);
  }

  const { currentPageType } = action.payload.page;
  const dataMapper = dataMapperMap[currentPageType];

  if (!dataMapper) {
    // TNC CASE
    return next(action);
  }

  return dataMapper({
    store,
    action,
    next
  });
};
