'use strict';
import { getVacPathParts } from 'src/universal/utils/vacRouterPathHelper';

const DEFAULT_EEP = null;

function eepHandler(_state, action) {
  return action.data.params.eep || DEFAULT_EEP;
}

const vacEepHandler = (_state, action) => {
  const partsResult = getVacPathParts(action.data.params);
  return partsResult.eep || DEFAULT_EEP;
};

const actions = {
  'URL_ROUTE[card-detail]': eepHandler,
  'URL_ROUTE[credit-card-listing]': vacEepHandler
};

export default function eepReducer(state = {}, action) {
  if (actions[action.type] && typeof actions[action.type] === 'function') {
    return actions[action.type](state, action);
  }
  return state;
}
