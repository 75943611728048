const urlParser = require('url').parse;
import xss from 'xss';
import { appendQueryParams } from 'acq-utils/lib/misc';
import { urlWithNoDuplicateParams } from 'core_modules/utils/url.utils';

const marketingParams = [
  'affid',
  'sourcecode',
  'cpid',
  'extlink',
  'src',
  'inav',
  'gclid',
  'gclsrc',
  'veid',
  'sourcesuffix'
];
const normalizeQueryParams = queryObject =>
  Object.keys(queryObject).reduce(
    (obj, k) => ({
      ...obj,
      [k.toLowerCase()]: queryObject[k]
    }),
    {}
  );

const getFilteredQueryObjct = queryObject =>
  marketingParams.reduce((obj, key) => {
    const normalizedQueryObject = normalizeQueryParams(queryObject);

    if (normalizedQueryObject[key]) {
      return {
        ...obj,
        [key]: normalizedQueryObject[key]
      };
    }
    return obj;
  }, {});

const queryStringToObject = queryStr =>
  (queryStr || '')
    .split('&')
    .filter(kv => kv)
    .map(kvPairString => {
      const [key, value] = kvPairString.split('=');
      return {
        [key]: value
      };
    })
    .reduce(
      (obj, kvPair) => ({
        ...obj,
        ...kvPair
      }),
      {}
    );

export const appendMarketingParams = (linkHref, queryObject) => {
  if (!linkHref) {
    return linkHref;
  }

  const { query, href } = urlParser(linkHref);
  const hrefQueryObject = queryStringToObject(query);
  if (hrefQueryObject.XMRSOURCECODE) {
    hrefQueryObject.XMRSOURCECODE = decodeURI(
      decodeURI(hrefQueryObject.XMRSOURCECODE)
    );
  }
  const marketingQueryObject = getFilteredQueryObjct(queryObject);
  const mergedQueryParametersObject = Object.assign(
    hrefQueryObject || {},
    marketingQueryObject || {}
  );
  return xss(
    urlWithNoDuplicateParams(appendQueryParams(href, mergedQueryParametersObject))
  );
};
