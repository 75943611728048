import onEnterInterreptor from 'src/services/store/on-enter-interreptor.js';
import { cardDetailRouteLegacy } from 'page_modules/business-pdp/routes';
import { setPage } from 'page_modules/active-page';
import { buildUrl } from 'core_modules/utils/url.utils.js';
import { injectReducerToStore } from 'src/universal/redux/utils';
import { getPdpComponent } from 'page_modules/business-pdp/routes/utils';
import {
  BUSINESS_CARD_PATH,
  BUSINESS_CREDIT_CARDS_ROUTE_PATH,
  US_BASE_URL
} from 'src/routes';
import { ensureNoNeedToLoadPdp } from 'src/universal/utils/vacRouterPathHelper';

const ROUTE_NAME = 'credit-card-listing';
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function onEnter(location, replaceWith, next) {
  const params = location.params;
  if (
    typeof params.eep === 'string' &&
    (params && isNumeric(params.eep) === false)
  ) {
    /*
    Note:
    once we migrate vac to new url structure, we will have to replace the "legacy" PdP route.
    by then, the "legacy" route will likely no longer be supported.
    */
    return cardDetailRouteLegacy().onEnterHandler(location, replaceWith, next);
  }
  next();
}

const path = buildUrl(
  US_BASE_URL,
  BUSINESS_CARD_PATH,
  BUSINESS_CREDIT_CARDS_ROUTE_PATH
);
const businessCreditCardsRoutes = store => ({
  path: path,
  customProps: store,
  getComponents: function(nextState, cb) {
    const params = nextState.params;

    if (ensureNoNeedToLoadPdp(params)) {
      setPage('businessCardsV2');
      require.ensure(
        [],
        require => {
          const PageComponent = require('../').default;
          PageComponent.WrappedComponent.chunkName = 'businessCreditCards';
          PageComponent.pageName = 'businessCardsV2';
          injectReducerToStore(store, PageComponent);
          return cb(null, PageComponent);
        },
        null,
        'businessCreditCards'
      );
    } else {
      nextState.params = {
        cardName: params.param1,
        eep: params.param2
      };
      getPdpComponent({
        cardName: params.param1,
        store,
        cb
      });
    }
  },
  onEnter: onEnterInterreptor(ROUTE_NAME, onEnter),
  onLeave: function() {},
  strict: true,
  exact: true
});

export { businessCreditCardsRoutes };
