import {
  getVisitSearchEngine,
  getReferringUrl,
  getNavigatorProps
} from 'service_modules/pzn-params';

export const getPznParams = async (pznEEP = '') => {
  const EEP = `/${pznEEP}`;

  return [
    {
      resolution: `${window.clientWidth || 0}X${window.clientHeight || 0}`,
      newBrowserArea: `${window.screen.width || 0}X${window.screen.height || 0}`,
      visitSearchEngine: encodeURIComponent(getVisitSearchEngine()),
      referringUrl: encodeURIComponent(getReferringUrl()),
      ...getNavigatorProps()
    },
    EEP
  ];
};

export const determineCmIndicator = (cmData = {}) => {
  return cmData.isCmPath || false;
};
