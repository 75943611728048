/* eslint-disable max-len */
export default {
  personalInfo: {
    fullName: {
      firstName: 'KRISHNA',
      middleName: 'X',
      lastName: 'LINGAMNENI-FEDOR'
    },
    nameOnCard: 'KRISHNA LINGAMNENI-FEDOR',
    phoneNumbers: [
      {
        type: 'H',
        number: '3584412999'
      }
    ]
  },
  userCards: [
    {
      pmcCode: '92',
      iaCode: 'RJF',
      productId: '16',
      shortName: 'platinum',
      pageName: 'american-express-business-platinum-credit-card-amex',
      filter: {
        type: 'charge',
        rewards: []
      },
      filters: ['featured-cards'],
      offerLink: {
        title: 'Welcome Offer',
        link: 'Welcome Offer',
        url:
          '/us/credit-cards/business/business-credit-cards/american-express-business-platinum-credit-card-amex',
        dtmTag: {
          type: 'linknav',
          tag: 'US-Acq-GCP-OpenCategory-Offer-Platinum'
        }
      },
      cardTitle: 'Business Platinum Card',
      cardHeadline: 'Business Platinum Card<sup>&#174;</sup>',
      description:
        'Get 5X Membership Rewards<sup>&#174;</sup> points on flights and prepaid hotels on amextravel.com.<sup>&#8225;</sup>',
      features: [
        '50% more points on qualifying large business purchases<sup>&#8225;</sup> and 35% Airline Bonus.<sup>&#8225;</sup>',
        'Access to 1,000+ Global Airport Lounges.<sup>&#8225;</sup>',
        '$595 annual fee<sup>&#164;</sup>'
      ],
      appAssets: {
        cardArt:
          'https://icm.aexp-static.com/Internet/Acquisition/US_en/AppContent/OneSite/open/category/cardarts/business-platinum.png', // needs env host
        cardArtHorizontalRecommended:
          'https://icm.aexp-static.com/Internet/Acquisition/US_en/AppContent/OneSite/open/category/cardarts/BPlat-horizontal-recommended.png',
        cardArtDiagonalRecommended:
          'https://icm.aexp-static.com/Internet/Acquisition/US_en/AppContent/OneSite/open/category/cardarts/BPlat-diagonal-recommended.png'
      },
      dtmTag: {
        type: 'linknav',
        tag: 'US-Acq-GCP-OpenCategory-CardArt-Platinum',
        eventAction: 'Click',
        eventAsset: 'Platinum'
      },
      active: true,
      decommissioned: false,
      apply: {
        type: 'button',
        title: 'Apply',
        url: 'card-application/apply/business-platinum-charge-card/',
        urlAppend: '-9-0',
        dtmTag: {
          type: 'intlink',
          tag: 'Apply',
          productName: 'Platinum'
        }
      },
      learnMoreLink: {
        title: 'American Express Business Platinum Card',
        link: 'Learn more',
        url:
          '/us/credit-cards/business/business-credit-cards/american-express-business-platinum-credit-card-amex',
        dtmTag: {
          type: 'linknav',
          tag: 'US-Acq-GCP-OpenCategory-LearnMore-Platinum'
        }
      },
      tncLinks: {
        offerTerms: {
          title: 'Offer Terms',
          link: '<sup>&#8224;</sup>Offer Terms',
          url:
            'card-application/apply/prospect/terms/business-platinum-charge-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#offer-terms'
        },
        benefitTerms: {
          title: 'Benefit Terms',
          link: '<sup>&#8225;</sup>Benefit Terms',
          url:
            'card-application/apply/prospect/terms/business-platinum-charge-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#benefit-terms'
        },
        ratesFees: {
          title: 'Rates & Fees',
          link: '<sup>&#164;</sup>Rates & Fees',
          url:
            'card-application/apply/prospect/terms/business-platinum-charge-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd'
        }
      },
      reviewsButton: {
        type: 'button',
        url:
          'https://www.americanexpress.com/us/small-business/credit-cards/business-card-reviews/?cards=business-platinum-card',
        dtmTag: {
          type: 'intlink',
          tag: 'AllReviews'
        }
      },
      annualFee: '$595 Annual Fee<sup>&#8224;</sup><sup>&#164;</sup>',
      similarCards: ['111', '499', '1043'],
      last4acctNumber: '2003',
      businessInfo: {
        businessName: 'GREEN SOLAR LA LLC',
        phoneNumbers: [
          {
            type: 'B',
            number: '8055551259'
          }
        ],
        addresses: [
          {
            type: 'B',
            addressLine1: '2313 EAST DRYWOOD ROAD',
            addressLine2: '',
            zipCode: '85024',
            city: 'PHOENIX',
            state: 'AZ',
            country: 'US'
          }
        ]
      },
      mgmToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNR00iLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6Imdjc3Nob3AiLCJpZCI6IjM3MjcxODE0NTcxMjAwMyIsImlhdCI6MTU2NTE1Mzg0MiwiZXhwIjoxNTY1MTUzODQ1fQ.MnfJ_-k-liX1Ni4qeTYYoAV0wiLE08FgaQaKlHskPdg'
    },
    {
      pmcCode: '112',
      iaCode: 'P53',
      productId: '22',
      shortName: 'gold-delta-skymiles',
      pageName: 'delta-gold-card-skymiles-credit-card',
      filter: {
        type: 'credit',
        rewards: []
      },
      filters: [],
      offerLink: {
        title: 'Welcome Offer',
        link: 'Welcome Offer',
        url:
          '/us/credit-cards/business/business-credit-cards/delta-gold-card-skymiles-credit-card',
        dtmTag: {
          type: 'linknav',
          tag: 'US-Acq-GCP-OpenCategory-Offer-DeltaGold'
        }
      },
      cardTitle: 'Gold Delta SkyMiles Business Credit Card',
      cardHeadline: 'Gold Delta SkyMiles<sup>&#174;</sup> Business Credit Card',
      description:
        'Earn more miles to pay for more travel booked on Delta.com.<sup>&#8225;</sup>',
      features: [
        'Earn 2 miles per dollar spent on eligible Delta purchases<sup>&#8225;</sup>',
        'First checked bag free on Delta flights.<sup>&#8225;</sup>',
        '$0 introductory annual fee for the first year, then $95<sup>&#8224;</sup><sup>&#164;</sup>'
      ],
      appAssets: {
        cardArt:
          'https://icm.aexp-static.com/Internet/Acquisition/US_en/AppContent/OneSite/open/category/cardarts/gold-delta-skymiles-business.png'
      },
      dtmTag: {
        type: 'linknav',
        tag: 'US-Acq-GCP-OpenCategory-CardArt-DeltaGold',
        eventAction: 'Click',
        eventAsset: 'DeltaGold'
      },
      active: true,
      decommissioned: false,
      apply: {
        type: 'button',
        title: 'Apply',
        url: 'card-application/apply/gold-delta-skymiles-business-credit-card/',
        urlAppend: '-9-0',
        dtmTag: {
          type: 'intlink',
          tag: 'Apply',
          productName: 'DeltaGold'
        }
      },
      learnMoreLink: {
        title: 'Gold Delta SkyMiles Business Credit Card',
        link: 'Learn more',
        url:
          '/us/credit-cards/business/business-credit-cards/delta-gold-card-skymiles-credit-card',
        dtmTag: {
          type: 'linknav',
          tag: 'US-Acq-GCP-OpenCategory-LearnMore-DeltaGold'
        }
      },
      tncLinks: {
        offerTerms: {
          title: 'Offer Terms',
          link: '<sup>&#8224;</sup>Offer Terms',
          url:
            'card-application/apply/prospect/terms/gold-delta-skymiles-business-credit-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#offer-terms'
        },
        benefitTerms: {
          title: 'Benefit Terms',
          link: '<sup>&#8225;</sup>Benefit Terms',
          url:
            'card-application/apply/prospect/terms/gold-delta-skymiles-business-credit-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#benefit-terms'
        },
        ratesFees: {
          title: 'Rates & Fees',
          link: '<sup>&#164;</sup>Rates & Fees',
          url:
            'card-application/apply/prospect/terms/gold-delta-skymiles-business-credit-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd'
        }
      },
      reviewsButton: {
        type: 'button',
        url:
          'https://www.americanexpress.com/us/small-business/credit-cards/business-card-reviews/?cards=gold-delta-skymiles-business-credit-card',
        dtmTag: {
          type: 'intlink',
          tag: 'AllReviews'
        }
      },
      annualFee:
        '$0 introductory annual fee for the first year, then $95<sup>&#8224;</sup><sup>&#164;</sup>',
      similarCards: ['141', '111', '92'],
      last4acctNumber: '7003',
      businessInfo: {
        businessName: 'ALTENAHR GROUP',
        phoneNumbers: [
          {
            type: 'B',
            number: '4106645255'
          }
        ],
        addresses: [
          {
            type: 'B',
            addressLine1: '2313 EAST DRYWOOD ROAD',
            addressLine2: '',
            zipCode: '85024',
            city: 'PHOENIX',
            state: 'AZ',
            country: 'US'
          }
        ]
      },
      mgmToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNR00iLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6Imdjc3Nob3AiLCJpZCI6IjM3MTI3MTQ1MDEzNzAwMyIsImlhdCI6MTU2NTE1Mzg0MiwiZXhwIjoxNTY1MTUzODQ1fQ.jaGH2_rv6L1pxPlsHIrKiYlTyvhNn566tYPXRXRLqm0'
    },
    {
      pmcCode: '760',
      iaCode: 'R6G',
      productId: '72',
      shortName: 'lowes-business-rewards',
      pageName: 'lowes-credit-cards',
      filter: {
        type: 'credit',
        rewards: []
      },
      filters: [],
      cardTitle: "Lowe's Business Rewards Card",
      cardHeadline: "Lowe's Business Rewards Card",
      description:
        "The Card that rewards you for shopping at Lowe's<sup>&#8225;</sup>",
      features: [
        "5% off every day at Lowe's<sup>&#8225;</sup>",
        'Earn points on business expenses<sup>&#8225;</sup>',
        'No annual fee<sup>&#164;</sup>'
      ],
      appAssets: {
        cardArt:
          'https://icm.aexp-static.com/Internet/Acquisition/US_en/AppContent/OneSite/open/category/cardarts/lowes-business-rewards.png'
      },
      dtmTag: {
        type: 'linknav',
        tag: 'US-Acq-GCP-OpenCategory-CardArt-Lowes',
        eventAction: 'Click',
        eventAsset: 'Lowes'
      },
      active: true,
      decommissioned: false,
      apply: {
        type: 'button',
        title: 'Apply',
        url: 'card-application/apply/lowes-business-rewards-card/',
        urlAppend: '-9-0',
        dtmTag: {
          type: 'intlink',
          tag: 'Apply',
          productName: 'Lowes'
        }
      },
      learnMoreLink: {
        title: "Lowe's Business Rewards Card",
        link: 'Learn more',
        url: '/us/credit-cards/business/business-credit-cards/lowes-credit-cards',
        dtmTag: {
          type: 'linknav',
          tag: 'US-Acq-GCP-OpenCategory-LearnMore-Lowes'
        }
      },
      tncLinks: {
        offerTerms: {
          title: 'Offer Terms',
          link: '<sup>&#8224;</sup>Offer Terms',
          url: 'card-application/apply/prospect/terms/lowes-business-rewards-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#offer-terms',
          dtmTag: {
            eventAction: 'Click',
            eventAsset: 'OfferTerms'
          }
        },
        benefitTerms: {
          title: 'Benefit Terms',
          link: '<sup>&#8225;</sup>Benefit Terms',
          url: 'card-application/apply/prospect/terms/lowes-business-rewards-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd#benefit-terms'
        },
        ratesFees: {
          title: 'Rates & Fees',
          link: '<sup>&#164;</sup>Rates & Fees',
          url: 'card-application/apply/prospect/terms/lowes-business-rewards-card/',
          urlAppend: '-9-0?key=tncBody&rwdFlag=rwd'
        }
      },
      reviewsButton: {
        type: 'button',
        url:
          'https://www.americanexpress.com/us/small-business/credit-cards/business-card-reviews/?cards=lowes-business-rewards-credit-card',
        dtmTag: {
          type: 'intlink',
          tag: 'AllReviews'
        }
      },
      annualFee: '$0 Annual Fee<sup>&#164;</sup>',
      similarCards: ['1043', '111', '92'],
      last4acctNumber: '3006',
      businessInfo: {
        businessName: 'RAUL ANDRADE',
        phoneNumbers: [
          {
            type: 'B',
            number: '4399787981'
          }
        ],
        addresses: [
          {
            type: 'B',
            addressLine1: '2313 EAST DRYWOOD ROAD',
            addressLine2: '',
            zipCode: '85024',
            city: 'PHOENIX',
            state: 'AZ',
            country: 'US'
          }
        ]
      },
      mgmToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJNR00iLCJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6Imdjc3Nob3AiLCJpZCI6IjM3NzQ3MzA0NjE2MzAwNiIsImlhdCI6MTU2NTE1Mzg0MiwiZXhwIjoxNTY1MTUzODQ1fQ.ze_M7WB_H-FGzn9kwlkIfvyGlejtWaa9gVoekXbZRlA'
    }
  ],
  isCPS: false,
  isGCS: true,
  isLoggedIn: true
};
