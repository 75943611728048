import { createSelector, reducerGenerator, action } from './utils';
import { getPageData } from 'src/universal/redux/page';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';
import get from 'lodash.get';
import { getVacPathParts } from 'src/universal/utils/vacRouterPathHelper';
import { hasPznDataArrived, getPznOfferList } from 'src/universal/redux/pznData';

const UPDATE_ACTIVE_FILTER = 'UPDATE_ACTIVE_FILTER';
export const DEFAULT_FILTER = 'all-cards';

const defaultState = {
  activeFilter: DEFAULT_FILTER
};

export const filterReducer = reducerGenerator(defaultState, {
  [UPDATE_ACTIVE_FILTER]: (state, action) => ({
    ...state,
    activeFilter: get(action, 'activeFilter.id') || state.activeFilter
  }),
  ['URL_ROUTE[credit-card-listing]']: (state, action) => {
    const routerPartsResult = getVacPathParts(get(action, 'data.params'));
    return {
      ...state,
      activeFilter: routerPartsResult.filterName || DEFAULT_FILTER
    };
  }
});

export const dispatchUpdateActiveFilter = (dispatch, payload) => {
  dispatch(action(UPDATE_ACTIVE_FILTER, payload));
};

export const getActiveFilter = state =>
  get(state, 'filter.activeFilter') || DEFAULT_FILTER;

export const getActiveFilterLabel = createSelector(
  [getActiveFilter, getPageData],
  (activeFilter, pageData) => {
    const filterList = get(pageData, 'vacFilter.content') || [];
    return (filterList.find(item => item.filter === activeFilter) || {}).header;
  }
);

export const getActiveFilterCards = createSelector(
  [getActiveFilter, getPageData, hasPznDataArrived, getPznOfferList],
  (activeFilter, pageData, pznArrived, offerList) => {
    const filters = get(pageData, 'vacFilter.content') || [];
    const activeFilterContent =
      filters.filter(item => {
        return item.filter === activeFilter;
      })[0] || {};

    const cardNames = Array.isArray(activeFilterContent.cards)
      ? activeFilterContent.cards
      : [];
    if (pznArrived) {
      return cardNames.filter(
        name => !!offerList.find(offer => offer.shortName === name)
      );
    }
    return cardNames;
  }
);

export const getFilterContent = (isMobile = false) =>
  createSelector([getPageData], pageData => {
    const vacFilterContent = get(pageData, 'vacFilter.content') || [];
    const buttons = get(pageData, 'vacFilter.buttons') || [];
    const {
      ['more-categories']: moreCategoriesButton = {},
      ['filter-cards']: filterCardsButton = {}
    } =
      parseArrayToObject(buttons, 'id') || {};

    const { mainFilters, dropdownFilters } = vacFilterContent.reduce(
      (acc, curr) => {
        if (curr.key === 'dropdown') {
          acc.dropdownFilters.push(curr);
        } else {
          acc.mainFilters.push(curr);
        }
        return acc;
      },
      { mainFilters: [], dropdownFilters: [] }
    );
    const formatFilterItems = item => ({
      id: item.filter,
      text: item.header
    });
    const formattedMainFilterItems = mainFilters.map(formatFilterItems);
    const formattedDropdownFilterItems = dropdownFilters.map(formatFilterItems);

    const items = isMobile
      ? [...formattedMainFilterItems, ...formattedDropdownFilterItems]
      : [
          ...formattedMainFilterItems,
          {
            ...moreCategoriesButton,
            children: formattedDropdownFilterItems
          }
        ];

    return {
      filterCardsButton,
      filters: { items }
    };
  });
