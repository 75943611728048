import { store } from 'src/services/store/store';

const noOps = () => {};

export const getStateObj = () => {
  const storeObj = typeof store !== 'undefined' ? store : {};
  return storeObj.getState ? storeObj.getState() || {} : {};
};

export const getDispatchFunc = () => {
  const storeObj = typeof store !== 'undefined' ? store : {};
  return typeof storeObj.dispatch === 'function' ? storeObj.dispatch : noOps;
};
