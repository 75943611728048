import { browserHistory } from 'react-router';
import uuidv1 from 'uuid/v1';

const updateRequestId = () =>
  (window.page_load_time.performanceINavUniqueId = uuidv1());

const updateNavDestinationPages = () => {
  // The below functions are exposed from the iNav header / Global Nav
  if (
    window.AmexNavigation &&
    window.AmexNavigation.setLoginDestPage &&
    window.AmexNavigation.setLogoutDestPage
  ) {
    window.AmexNavigation.setLoginDestPage(window.location.href);
    window.AmexNavigation.setLogoutDestPage(window.location.href);
  }
};

const setWindowVarsBeforeCsr = () => {
  browserHistory.listen(() => {
    window.clientNavigationStart = Date.now();
    performance.mark('clientNavigationStart');
    updateRequestId();
    updateNavDestinationPages();
  });

  updateRequestId();
  updateNavDestinationPages();
};

export default setWindowVarsBeforeCsr;
