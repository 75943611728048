import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ensureAdobeAndTrackItAreReady } from 'src/universal/utils/thirdPartyScriptHelpers/ensureAdobeHasLoaded';
import {
  getOneXpExperiments,
  dispatchExperimentsTracked
} from 'src/universal/redux/oneXp';

const TrackIt = ({ experiments, dispatchExperimentsTracked }) => {
  const [areAdobeAndTrackItLoaded, setAreAdobeAndTrackItLoaded] = useState(false);
  const [isTrackExperimentCalled, setIsTrackExperimentCalled] = useState(false);

  ensureAdobeAndTrackItAreReady().then(() => setAreAdobeAndTrackItLoaded(true));

  useEffect(
    () => {
      if (
        areAdobeAndTrackItLoaded &&
        Object.keys(experiments).length > 0 &&
        !isTrackExperimentCalled
      ) {
        Object.values(experiments).forEach(experiment => {
          const formattedExperiment = {
            id: experiment.id,
            name: experiment.name,
            dimensions: experiment.dimensions.map(dimension => ({
              name: dimension.name,
              variant: dimension.variant,
              impressed: false
            })),
            source: 'ONE XP'
          };
          window.trackIt.trackExperiment(formattedExperiment);
        });
        setIsTrackExperimentCalled(true);
        dispatchExperimentsTracked();
      }
    },
    [experiments, areAdobeAndTrackItLoaded, isTrackExperimentCalled]
  );

  return null;
};

const mapDispatchToProps = dispatch => ({
  dispatchExperimentsTracked: () => dispatchExperimentsTracked(dispatch)
});

const mapStateToProps = state => ({
  experiments: getOneXpExperiments(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackIt);
