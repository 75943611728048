import { createSelector } from 'reselect';
import { action } from './utils';
import { reducerGenerator } from './utils/reducerGenerator';

import get from 'lodash.get';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';
import { globalRightMenuMobilePhone } from 'service_modules/parser';

// actionTypes
const SET_ACTIVE_PAGE_NAME = 'SET_ACTIVE_PAGE_NAME';

// Reducer
export const activepageReducer = reducerGenerator('', {
  [SET_ACTIVE_PAGE_NAME]: (state, action) => action.payload || state
});

// Actions
export const dispatchSetActivePagename = (dispatch, payload) =>
  dispatch(action(SET_ACTIVE_PAGE_NAME, { payload }));

// Selectors
export const getActivePageName = state => state.activePageName || '';
export const getIsSuppCardsModalOpen = state =>
  state.suppModal.suppModalOpen || false;

export const getPageState = (state, props) => {
  const { pageName: pageNamePropOverride } = props || {};
  return state[pageNamePropOverride || getActivePageName(state)] || {};
};

export const getPageData = createSelector(
  getPageState,
  pageState => pageState.page || pageState.pageData || {}
);

export const getPageLabels = createSelector(getPageData, pageData =>
  parseArrayToObject(pageData.labels, 'identifier', 'text')
);

export const getPageLevelDtmHash = createSelector(getPageData, pageData =>
  parseArrayToObject(pageData.dtmEvents, 'key')
);

export const getPageLinks = createSelector(getPageData, pageData =>
  parseArrayToObject(pageData.links, 'key')
);

// Global Data
export const getPageGlobalData = createSelector(getPageState, pageState => {
  const rawGlobal = pageState.global || pageState.globalData || {};

  // US5006393: part of removal legacy model.js effort.
  const applyByPhoneNumVal = globalRightMenuMobilePhone(rawGlobal.globalHeaderPage);
  return {
    ...rawGlobal,
    applyByPhoneNumber: applyByPhoneNumVal,
    globalHeaderPage: {
      ...rawGlobal?.globalHeaderPage,
      applyByPhoneNumber: applyByPhoneNumVal
    }
  };
});

export const getPageFeatureFlags = createSelector(
  getPageGlobalData,
  ({ featureFlags = {}, featureFlagsArray = [] }) => ({
    ...featureFlags,
    ...parseArrayToObject(featureFlagsArray, 'name', 'value')
  })
);

// Card Data
export const getPageCardsData = createSelector([getPageState], pageData => {
  let cardList = [];
  // for new data structure
  if (Array.isArray(pageData.cards) && pageData.cards.length > 0) {
    cardList = pageData.cards;
  } else {
    // accomodating legacy data structure
    cardList = get(pageData, 'pageData.cards') || [];
  }

  return cardList;
});
export const getPageFirstCardData = createSelector(
  getPageCardsData,
  cardData => (Array.isArray(cardData) && cardData.length ? cardData[0] : {})
);

export const getCardsByShortName = createSelector(getPageCardsData, cards => {
  return parseArrayToObject(cards, 'shortName');
});

export const getPageAllowedLists = createSelector(getPageGlobalData, globalData => ({
  ...parseArrayToObject(globalData.allowedlists, 'name', 'values')
}));

export const getPageDisallowedLists = createSelector(
  getPageGlobalData,
  globalData => ({
    ...parseArrayToObject(globalData.disallowedlists, 'name', 'values')
  })
);

export const getPageEep = urlEep =>
  createSelector(
    getPageGlobalData,
    globalData =>
      Number(urlEep) ? urlEep : get(globalData, 'globalHeaderPage.defaultEEP') || ''
  );

export const getExperimentalPageData = createSelector(
  getPageState,
  pageState => pageState.TNL || {}
);

export const getDtmImpressions = createSelector(getPageState, pageState => {
  const pageData = pageState.page
    ? pageState.page
    : pageState.pageData
      ? pageState.pageData
      : {};
  return parseArrayToObject(pageData.dtmImpressionList || [], 'type');
});

export const getGlobalOgSpinnerText = createSelector(
  getPageGlobalData,
  globalData => {
    return (
      get(globalData, 'globalHeaderPage.accessibility.offerGatingSpinnerText') || ''
    );
  }
);

export const getPznOfferEligibleDtm = createSelector(getPageState, pageState => {
  const pageData = pageState.page
    ? pageState.page
    : pageState.pageData
      ? pageState.pageData
      : {};

  return (pageData.dtmEvents || []).find(e => e.key === 'pzn_offer_eligible');
});

export const getRubiksContentList = createSelector(
  getPageGlobalData,
  globalData => get(globalData, 'globalHeaderPage.rubiksContent') || []
);
