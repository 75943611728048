import React, { useRef, useState, createContext } from 'react';
import { connect } from 'react-redux';
import { useBreakpoint } from '@americanexpress/aqx-components';
import NavbarDropdown from './components/NavbarDropdown.js';
import { getActivePageName, getPageGlobalData } from 'src/universal/redux/page';
import { mobileTheme, tabletTheme } from './__styles__/themes.js';
import {
  useOutsideClick,
  useEscapeKey,
  useScroll,
  getPageTypeVal
} from './utils.js';

export const AccordionDisplayContext = createContext(null);

const Subnav = ({ pageName, globalData, segmentParams }) => {
  const [shouldDisplayAccordion, toggleDisplayAccordion] = useState(false);
  const subnavRef = useRef(null);
  const { sm, lg } = useBreakpoint();

  const closeAccordion = () => toggleDisplayAccordion(false);
  const openAccordion = () => toggleDisplayAccordion(true);

  useOutsideClick(closeAccordion, subnavRef);
  useEscapeKey(closeAccordion);
  useScroll(closeAccordion);

  if (!globalData) {
    return null;
  }

  const pageTypeVal = getPageTypeVal(pageName, segmentParams);

  const isSizeSmall = sm;
  const isSizeLarge = lg;

  return (
    <AccordionDisplayContext.Provider
      value={{
        shouldDisplayAccordion,
        closeAccordion,
        openAccordion
      }}
    >
      <NavbarDropdown
        {...globalData.globalHeaderPage.globalSubNav}
        theme={isSizeSmall ? mobileTheme : tabletTheme}
        pageName={pageTypeVal}
        $dropdownWidth={isSizeSmall ? '100%' : isSizeLarge ? 252 : 300}
        subnavRef={subnavRef}
      />
    </AccordionDisplayContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    pageName: getActivePageName(state),
    globalData: getPageGlobalData(state)
  };
};

export default connect(mapStateToProps)(Subnav);
