import React from 'react';

import { ROUTES } from 'acq-pages-prequal/lib/constants';
import { processPayloadData } from 'acq-pages-prequal/lib/utils/data-mapper';
import { setPage } from 'page_modules/active-page';

import { isUrlExternal } from 'acq-utils/lib/utils';
import { appendMarketingParams } from '../common/marketingQueryParams';
import dtmEvents from 'service_modules/models/dtm-tagging';
import { getCustomEventHandlers } from './preQualCustomEvents';
import { injectReducerToStore } from 'src/universal/redux/utils';

const onClickHandler = pageData => props => {
  const { onClick, dtm, ...rest } = props;

  if (rest.href || rest.to) {
    const withMarketingParams = appendMarketingParams(
      rest.href || rest.to,
      pageData.location.query
    );

    if (isUrlExternal(withMarketingParams)) {
      props.href = withMarketingParams;
      rest.href = withMarketingParams;
    } else {
      props.to = withMarketingParams;
      rest.to = withMarketingParams;
    }
  }

  if (typeof window !== 'object') {
    return props;
  }

  if (!onClick && !dtm) {
    return props;
  }

  rest.onClick = event => {
    if (dtm) {
      dtmEvents.dispatch({
        type: 'DTM_CLICK',
        dtmTag: dtm
      });
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return rest;
};
const addMarketingQPsToEdgeCases = function(props) {
  (props.cards || []).forEach(card => {
    card.links = card.links.map(link => ({
      ...link,
      url: appendMarketingParams(link.url, props.location.query)
    }));
  });
  /**
   * no real need to return here since this mutates via reference
   * but it is a healthy pattern to keep functions functional
   * and test friendly by returning an expected value
   */
  return props;
};

const commonConfig = {
  dataMapper: processPayloadData
};

const PreQualPage = store => route => ({
  path: route,
  getComponent: ({ params }, cb) => {
    require.ensure(
      [],
      require => {
        const { pageWrapper } = require('../common/pageWrapper.js');
        const {
          PropertyProvider
        } = require('acq-components/lib/utils/PropertyProvider/PropertyProvider');
        const {
          smallBusinessPreQualComponents
        } = require('acq-pages-prequal/lib/routes/PreQual');
        const {
          queries: queriesByCountryCode
        } = require('acq-pages-prequal/lib/queries');
        const {
          propertyHandler
        } = require('acq-pages-prequal/lib/props/propertyHandler');

        const stylesMap = require('./styles.js').default;

        const PreQualComponent = smallBusinessPreQualComponents[params.countryCode];
        const styles = stylesMap[params.countryCode].business;
        const queryByCountry = queriesByCountryCode[params.countryCode] || {};
        const query = queryByCountry.business;

        const WrappedPage = pageWrapper(
          props => {
            const eventHandler = propertyHandler({
              toggle: onClickHandler(props),
              button: onClickHandler(props),
              link: onClickHandler(props),
              compare: onClickHandler(props)
            });
            props = addMarketingQPsToEdgeCases(props);
            return (
              <PropertyProvider parseProps={eventHandler}>
                <PreQualComponent
                  pageData={props}
                  params={props.params}
                  fetchData={props.fetchData}
                  pageStyles={styles}
                  customEvents={getCustomEventHandlers(props.dispatch)}
                />
              </PropertyProvider>
            );
          },
          {
            ...commonConfig,
            query,
            isCorporate: false,
            page: 'prequal',
            countryCodeOnly: true
          }
        );

        injectReducerToStore(store, WrappedPage);

        cb(null, WrappedPage);
      },
      null,
      'usPreQualBusiness'
    );
  },
  onEnter: function(location, replaceWith, next) {
    setPage('externalPage');
    next();
  }
});

const routes = {
  preQualSmallBusiness: store => ROUTES.PreQualSmallBusiness.map(PreQualPage(store))
};
export default routes;
