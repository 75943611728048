import isomorphicLogger from 'src/universal/utils/IsomorphicLogger';
import { getIsomorphicCookies } from 'src/universal/utils/getIsomorphicCookies';

export const logError = (errorName = 'gcp-log', errorObj = {}, moreInfo = {}) => {
  const errorLog = {
    ...errorObj,
    ...moreInfo,
    cookieSize: JSON.stringify(getIsomorphicCookies()).length
  };

  isomorphicLogger(errorName, errorLog, true);
  console.error(errorLog);
};
