import React from 'react';

// components
import { Element, Color, useBreakpoint } from '@americanexpress/aqx-components';
import Accordion from './Accordion.js';
import { ArrowIcon } from './ArrowIcon.js';
import { DropdownMenu } from './DropdownMenu';
import { ClickableLogo } from './Logo';
import AltMobileSubnav from './AltMobileSubnav.js';
import TabletSubnav from './Tablet.js';
import DesktopSubnav from './Desktop.js';

// utils
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';

// styles
import styles from '../__styles__/index.scss?modules';

const NavbarToRender = ({
  subnavWidth,
  navbarDecision,
  theme = {},
  mobileDtm,
  data,
  customization = {},
  callInfo,
  mobileLogo,
  desktopLogo,
  showLogo,
  altMobileSubnav,
  $fontSize = 14,
  pageName,
  dropdownWidth,
  textLogo
}) => {
  const { mobileOpen, mobileClose } = mobileDtm;

  const tabletProps = {
    subnavWidth,
    desktopLogo,
    data,
    customization,
    showLogo,
    callInfo,
    $fontSize,
    pageName,
    dropdownWidth,
    textLogo
  };
  if (navbarDecision === 'tabletSubnav') {
    return <TabletSubnav {...tabletProps} />;
  } else if (navbarDecision === 'desktopSubnav') {
    return <DesktopSubnav {...tabletProps} />;
  } else if (navbarDecision === 'altSubnav') {
    return <AltMobileSubnav {...altMobileSubnav} />;
  } else if (navbarDecision === 'mobileSubnav') {
    return (
      <Accordion
        Button={({ onClick, active }) => {
          const openClick = () => {
            if (mobileOpen && !active) {
              onClick();
              sendDTMClickEvent(mobileOpen);
            } else if (mobileClose && active) {
              onClick();
              sendDTMClickEvent(mobileClose);
            } else {
              onClick();
            }
            setTimeout(
              () => document.getElementById('top-level-accordion').focus(),
              0
            );
          };

          return (
            <div className={styles.mobileButtonContainer}>
              <Element
                id="top-level-accordion"
                Component="button"
                type="button"
                className={styles.dropdownbutton}
                aria-expanded={active}
                aria-label="open accordion"
                onClick={openClick}
                theme={{
                  color: Color.BrightBlue,
                  paddingHorizontal: 15,
                  margin: 0,
                  ...theme
                }}
              >
                <Element className={styles.navbarDropdown}>
                  <ArrowIcon direction={active ? 'up' : 'down'} />
                </Element>
              </Element>
              {mobileLogo && (
                <div className={styles.clickableLogoContainer}>
                  <ClickableLogo {...mobileLogo} />
                </div>
              )}
            </div>
          );
        }}
        Body={() => (
          <DropdownMenu
            data={data}
            theme={theme}
            customization={customization}
            callInfo={callInfo}
            $fontSize={$fontSize}
            pageName={pageName}
            dropdownWidth={dropdownWidth}
          />
        )}
      />
    );
  } else {
    return null;
  }
};

const decideNavbar = (pagesToDisplay, pageName, screenSize) => {
  const { dropdown = '', altMobile = '' } = pagesToDisplay || {};

  let navbarDecision;
  if (screenSize === 'md') {
    navbarDecision = 'tabletSubnav';
  } else if (screenSize === 'lg') {
    navbarDecision = 'desktopSubnav';
  } else if (altMobile.includes(pageName)) {
    navbarDecision = 'altSubnav';
  } else if (dropdown.includes(pageName)) {
    navbarDecision = 'mobileSubnav';
  } else {
    navbarDecision = 'noSubnav';
  }

  return navbarDecision;
};

const NavbarDropdown = ({
  pagesToDisplay,
  subnavWidth,
  theme = {},
  mobileDtm,
  data,
  customization = {},
  callInfo,
  mobileLogo,
  desktopLogo,
  altMobileSubnav,
  $fontSize = 14,
  pageName,
  $dropdownWidth,
  subnavRef,
  textLogo
}) => {
  const { current: screenSize } = useBreakpoint();
  const dropdownWidth = $dropdownWidth ? $dropdownWidth : '100%';
  const navbarDecision = decideNavbar(pagesToDisplay, pageName, screenSize);

  if (navbarDecision === 'noSubnav') {
    return null;
  }

  return (
    <div ref={subnavRef}>
      <Element className={styles.root} theme={theme}>
        <Element
          className={styles[navbarDecision === 'altSubnav' ? 'altMobile' : 'item']}
          theme={{ horizontallyFluid: true }}
        >
          <NavbarToRender
            subnavWidth={subnavWidth}
            navbarDecision={navbarDecision}
            theme={theme}
            mobileDtm={mobileDtm}
            data={data}
            cusotmization={customization}
            callInfo={callInfo}
            mobileLogo={mobileLogo}
            desktopLogo={desktopLogo}
            altMobileSubnav={altMobileSubnav}
            $fontSize={$fontSize}
            pageName={pageName}
            dropdownWidth={dropdownWidth}
            textLogo={textLogo}
          />
        </Element>
      </Element>
    </div>
  );
};

export default NavbarDropdown;
