import isProduction from './isProduction';
import getQueryParams from './getQueryParams';

const useMock = () => {
  if (isProduction()) {
    return false;
  }

  const params = getQueryParams();
  return params.useMock === 'true';
};

export default useMock;
