import React from 'react';

// components
import {
  Element,
  FontType,
  Size,
  Link,
  Typography,
  Icon
} from '@americanexpress/aqx-components';

// utils
import clsx from 'clsx';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';

// styles
import styles from '../__styles__/index.scss?modules';

export const CardMemberLink = props => {
  return (
    <Element
      className={clsx(styles.cardMemberLink, styles.dropdownbutton)}
      theme={{ paddingTop: 5 }}
    >
      <Link href={props.url} onClick={() => sendDTMClickEvent(props.dtm)}>
        <Icon
          directory="dls"
          imageName={props.imageName}
          theme={{ paddingRight: 0 }}
          $size={Size.MD}
        />
        <Typography
          $family={FontType.HelveticaRegular}
          $size={props.mobile ? '15px' : '14px'}
        >
          {props.header}
        </Typography>
      </Link>
    </Element>
  );
};
