import { Logger, isomorphicTransport } from 'acq-system-logger';
import { isBrowser } from 'acq-utils/lib/misc';
import { getApiHost } from 'acq-system-api';

export const initLogger = (hostName, requestId) =>
  new Logger({
    transports: [
      isomorphicTransport({
        url: `${getApiHost(hostName)}/us/cardshop-api/api/v1/log?cid=${requestId}`
      })
    ],
    requestId
  });

export const loggerFunc = () =>
  isBrowser()
    ? initLogger(
        window.location.hostname,
        window.page_load_time.performanceINavUniqueId
      )
    : global.logger;
