import 'src/webpack-set-public-path';

require('@babel/polyfill');

import './vendors';

import customEventPolyfill from 'polyfills/customEvent';
import { match } from 'react-router';
import { createRoutes } from 'src/app-routes';
import setWindowVarsBeforeCsr from 'src/client/utils/SetWindowVarsBeforeCsr';

customEventPolyfill();
setWindowVarsBeforeCsr();

try {
  match({ routes: createRoutes(), location }, function() {
    const { ClientProvider } = require('./services/store/store-provider');
    const { hydrate } = require('react-dom');
    const React = require('react');
    browserResourceTiming.setTimestamp('reactapp::attachedToBody');
    hydrate(<ClientProvider />, document.getElementById('content'));
  });
} catch (err) {
  const errHeader = 'react-router-match-error';
  console.error(errHeader, err);
  const SSRErrorPage = require('page_modules/http-errors/ssr-error-page').default;
  const { render } = require('react-dom');
  const React = require('react');
  render(
    <SSRErrorPage
      httpStatus={500}
      reqUrl={window.location.pathname}
      errorDetails={`${errHeader}:${err.message}`}
      stackTrace={err.stack}
    />,
    document.getElementById('content')
  );
}
