'use strict';

import React from 'react';
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import config from './app-transition.json';

class AppTransition extends React.PureComponent {
  render() {
    if (!this.props || !this.props.children) {
      return null;
    }
    const { location, EEP } = this.props;
    const key = location ? location.pathname : '';
    const countryCode = this.props.params.countryCode
      ? this.props.params.countryCode
      : 'en-us';

    return (
      <ReactCSSTransitionGroup
        component={config.component}
        transitionName={{
          enter: `${config.transitionName}-enter`,
          enterActive: `${config.transitionName}-enter-active`,
          leave: `${config.transitionName}-leave`,
          leaveActive: `${config.transitionName}-leave-active`,
          appear: `${config.transitionName}-appear`,
          appearActive: `${config.transitionName}-appear-active`
        }}
        transitionAppear={config.transitionAppear}
        transitionAppearTimeout={config.transitionAppearTimeout}
        transitionEnterTimeout={config.transitionEnterTimeout}
        transitionLeaveTimeout={config.transitionLeaveTimeout}
      >
        <page-transition-spinner id="page-transition-spinner" />
        {React.cloneElement(this.props.children, {
          key: key,
          EEP: EEP,
          countryCode
        })}
      </ReactCSSTransitionGroup>
    );
  }
}

export default connect(function mapStateToProps(state) {
  return {
    EEP: state.eepReducer
  };
})(AppTransition);
