import { action, createSelector, reducerGenerator } from './utils';
import parseArrayToObject from 'src/universal/utils/parseArrayToObject';

// actionTypes
const SET_PZN_DATA = 'SET_PZN_DATA';
const SET_PZN_ERROR = 'SET_PZN_ERROR';
export const RESET_PZN_CARDMEMBER_DATA = 'RESET_PZN_CARDMEMBER_DATA';

// default State
const defaultState = {
  pznOfferList: [],
  pznDataArrived: false,
  pznAttributes: {},
  hasError: false,
  errorInfo: {},
  hasLegacyDispatched: false,
  pznDcf: {},
  pznKabbage: {},
  pznPageData: {},
  eepUsedForPznRequest: ''
};

// Reducer
export const pznDataReducer = reducerGenerator(defaultState, {
  [SET_PZN_DATA]: (state, action) => ({
    ...state,
    pznOfferList: action.payload.offerList,
    pznAttributes: action.payload.pznAttributes,
    pznDcf: action.payload.dcf,
    pznKabbage: action.payload.kabbage,
    pznDataArrived: true,
    pznPageData: action.payload.pznPageData,
    eepUsedForPznRequest: action.payload.eepUsedForPznReq
  }),
  [RESET_PZN_CARDMEMBER_DATA]: () => defaultState,
  [SET_PZN_ERROR]: (state, action) => ({
    ...state,
    pznDataArrived: true,
    hasError: true,
    errorInfo: action.payload
  })
});

// Actions
export const dispatchSetPznDataAction = (dispatch, payload) => {
  dispatch(action(SET_PZN_DATA, { payload }));
};

export const dispatchResetPznAndCardMemberData = dispatch => {
  dispatch(action(RESET_PZN_CARDMEMBER_DATA));
};

export const dispatchSetPznError = (dispatch, payload) => {
  dispatch(action(SET_PZN_ERROR, { payload }));
};

// Selectors
export const getPznData = state => state.pznData;
export const getHasLegacyPznRequestDispatched = createSelector(
  getPznData,
  pznData => pznData.hasLegacyDispatched
);
export const getPznSpecialOffers = createSelector(getPznData, pznData =>
  pznData.pznOfferList.filter(card => card.specialIndicator === 'Y')
);
export const hasPznDataArrived = createSelector(
  getPznData,
  pznData => pznData.pznDataArrived
);
export const getPznSpecialOffersOrdered = createSelector(
  getPznSpecialOffers,
  specialOffers =>
    specialOffers.sort((itemA, itemB) => {
      if (itemA.order < itemB.order) {
        return -1;
      }
      if (itemA.order > itemB.order) {
        return 1;
      }
      return 0;
    })
);
export const isPznDataLoaded = createSelector(
  getPznData,
  pznData => pznData && pznData.pznOfferList && !!pznData.pznOfferList.length
);

export const getPznAttributes = createSelector(
  getPznData,
  pznData => pznData.pznAttributes || {}
);
export const getPznOfferList = createSelector(
  getPznData,
  pznData => pznData.pznOfferList || []
);

export const getPznOfferByShortName = shortName =>
  createSelector(
    getPznOfferList,
    offerList => parseArrayToObject(offerList, 'shortName')[shortName] || {}
  );

export const getPznOffersEepByPmc = createSelector(getPznOfferList, offerList =>
  parseArrayToObject(offerList, 'pmcCode', 'eep')
);
export const getPznOffersEepByShortName = createSelector(
  getPznOfferList,
  offerList => parseArrayToObject(offerList, 'shortName', 'eep')
);
export const getPznInfo = createSelector(
  [hasPznDataArrived, getPznData],
  (hasPznArrived, { hasError = false, errorInfo = {} }) => ({
    hasPznArrived,
    hasError,
    errorInfo
  })
);
export const getPznDcfData = createSelector(
  getPznData,
  pznData => pznData.pznDcf || {}
);

export const getPznPageData = createSelector(
  getPznData,
  pznData => pznData.pznPageData || {}
);
