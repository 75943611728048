'use strict';

import configureStore from './configure-store';
import { isServer } from 'src/universal/utils/browser-detection/index';

let initialData = void 0;
let _store;

if (!isServer()) {
  initialData = window.initialData;
}

// make it a singleton to allow configuring once and exporting multiple times.
const getStore = () => {
  if (!_store) {
    _store = configureStore(initialData);
  }
  return _store;
};

export const store = getStore();
