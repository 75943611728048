import URL from 'url';
import { HttpsProxyAgent } from 'src/import-modules';
import { isServer } from 'acq-utils/lib/misc';

import { ENV } from '/src/ssr-env-detection';

export const FETCH_MAX_RETRIES = 2;
export const FETCH_RETRY_DELAY = 200;

export const fetchRetryConfig = {
  retryDelay: FETCH_RETRY_DELAY,
  retryOn: (attempt, error, response) => {
    if (attempt < FETCH_MAX_RETRIES && (error !== null || response.status !== 200)) {
      console.info(`Server Fetch - Attempting Retry ${attempt + 1}`);
      return true;
    }
  }
};

export const DEFAULT_CONFIG = {
  timeout: 5000,
  responseType: 'json',
  mode: 'cors',
  maxRedirects: 25,
  credentials: 'include',
  cache: 'no-cache',
  headers: {},
  ...fetchRetryConfig
};

export const getProxyHost = env => {
  switch (env) {
    case 'e0':
      return void 0;
    case 'e1':
      return 'http://PHXAPPGWE2-VIP.phx.aexp.com:9090';
    case 'e2':
      return 'http://PHXAPPGWE2-VIP.phx.aexp.com:9090';
    case 'e3':
      return 'http://proxy-appgw.aexp.com:9090';
    default:
      return 'http://proxy.aexp.com:8080';
  }
};

export const getProxyOptions = () => {
  if (!isServer()) {
    return {};
  }

  let proxyUrl = getProxyHost(ENV);
  if (!proxyUrl) {
    return {};
  }

  proxyUrl = URL.parse(proxyUrl);
  proxyUrl.secureProxy = proxyUrl.protocol === 'https:';

  console.log(`[using default proxy] ${proxyUrl}`);
  console.log(`[using NODE_ENV] ${process.env.NODE_ENV}`);

  return {
    cache: true,
    agent: new HttpsProxyAgent(proxyUrl)
  };
};

export const getCorrelationId = () => {
  if (isServer()) {
    return global.uuid;
  }

  return window.page_load_time ? window.page_load_time.performanceINavUniqueId : '';
};
