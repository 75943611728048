import { useEffect, useState } from 'react';
import { ensureAdobeScriptHasLoaded } from 'src/universal/utils/thirdPartyScriptHelpers/ensureAdobeHasLoaded';

export const InsertDTMPageBottom = () => {
  const [isAdobeDTMLoaded, setIsAdobeDTMLoaded] = useState(false);

  ensureAdobeScriptHasLoaded().then(() => setIsAdobeDTMLoaded(true));

  useEffect(
    () => {
      if (isAdobeDTMLoaded) {
        const scriptEl = document.createElement('script');
        scriptEl.setAttribute('type', 'text/javascript');
        scriptEl.innerHTML = 'window._satellite.pageBottom();';
        document.body.appendChild(scriptEl);
      }
    },
    [isAdobeDTMLoaded]
  );

  return null;
};
