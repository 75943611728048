import vac from './viewAllCards';
const { intlVACPage, intlVACPageCorporate } = vac;

import pdp from './cardDetails';
const { intlCardDetailsSmallBusiness, intlCardDetailsCorporate } = pdp;

import routes from './compare';
import intlTNCPage from './tnc';

const { intlCompareSmallBusiness, intlCompareCorporate } = routes;

import preQual from './preQual';

const { preQualSmallBusiness } = preQual;

export {
  intlVACPage,
  intlVACPageCorporate,
  intlCardDetailsSmallBusiness,
  intlCardDetailsCorporate,
  intlCompareSmallBusiness,
  intlCompareCorporate,
  intlTNCPage,
  preQualSmallBusiness
};
