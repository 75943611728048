import { ENV } from 'src/ssr-env-detection';
import { isBrowser } from 'acq-utils/lib/utils';
import { getE3HostNames } from 'acq-system-api';

const gcsE3HostNames = getE3HostNames('gcs');
const isProduction = () =>
  (isBrowser() ? gcsE3HostNames.includes(window.location.host) : ENV === 'e3') ||
  false;

export default isProduction;
