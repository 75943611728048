import { createSelector as createSelectorFunc } from 'reselect';
import isomorphicLogger from 'src/universal/utils/IsomorphicLogger';
import { dispatchSetActivePagename } from '../page';

export const action = (type, payload = {}) => ({
  type,
  ...payload
});

// Read more: https://github.com/reduxjs/reselect
export const createSelector = createSelectorFunc;

export const injectReducerToStore = (store, PageComponent) => {
  if (store && PageComponent) {
    if (PageComponent.reducer) {
      store.injectReducer({
        [PageComponent.reducer.name]: PageComponent.reducer.func
      });
    }
    if (PageComponent.pageName) {
      dispatchSetActivePagename(store.dispatch, PageComponent.pageName);
    }
  }
};

export const executeSafely = (condition, func, errorMessage) =>
  condition ? func() : console.error(errorMessage);
export const executeReduxActionSafely = (type, payload) =>
  executeSafely(
    type,
    () => action(type, { ...payload }),
    `reduxType - ${type} - not found`
  );
export const reportError = (
  errObject = {},
  errLogName = 'gcp-error',
  dispatch,
  errAction
) => {
  if (!errLogName || !errObject) {
    console.warn(`reportError: errLogName or errObject should be passed`);
  }
  if (dispatch) {
    dispatch(errAction);
  }
  isomorphicLogger(errLogName, {
    ...errObject
  });
  console.error(errObject);
};
