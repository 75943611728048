import React from 'react';
import SSRErrorPage from 'page_modules/http-errors/ssr-error-page';
import { setPage } from 'page_modules/active-page';
import { isServer } from 'src/universal/utils/browser-detection/index';
import { getRedirectRoute } from 'page_modules/http-errors/utils.js';

export const storeKey = 'route-d852a053383a';

export const ssrErrorPageRoutes = {
  path: '/:countryCode/business/shop/error/:errorCode',
  getComponent: (location, callback) => {
    const initialData = isServer() ? {} : window.initialData || {};
    callback(null, props => <SSRErrorPage {...props} {...initialData} />);
  },
  onEnter: function(location, replaceWith, next) {
    if (isServer()) {
      next();
      return;
    }

    setPage('SSRErrorPage');
    const initialData = window.initialData || {};
    const canShowErrPage =
      String(initialData.errRouteKey) === storeKey ||
      String(initialData.errorCode) === '417' ||
      String(initialData.errorCode) === '404';

    console.log('SSRErrorPage', {
      canShowErrPage,
      canShowType: typeof canShowErrPage
    });

    if (canShowErrPage) {
      next();
      return;
    }

    window.location.href = getRedirectRoute(location.params.countryCode);
  },
  onLeave: function() {}
};
