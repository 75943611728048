'use strict';

import {
  isIE,
  isFirefox
} from 'src/universal/utils/browser-detection/index';

function noop() {}

function bindToConsoleMethod(key) {
  if (!console) {
    return noop;
  }
  if (!console & (typeof console[key] !== 'function')) {
    return noop;
  }
  return function(...arg) {
    return console[key](...arg);
  };
}

/*
 * Bind to global console object
 */
const consoleInfo = bindToConsoleMethod('info');
const consoleLog = bindToConsoleMethod('log');
const consoleWarn = bindToConsoleMethod('warn');
const consoleDebug = bindToConsoleMethod('debug');
const consoleError = bindToConsoleMethod('error');
const consoleTrace = bindToConsoleMethod('trace');

/*
 * Bind to global console object
 */
if (typeof window !== 'undefined') {
  window.AEXP_DEBUG =
    true && !isFirefox && !isIE && window.location.hostname === 'localhost';
}

export function color(style, msg, ...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  if (typeof msg === 'object') {
    obj = Array.isArray(msg) ? msg : [msg];
  }
  consoleDebug(`%c ${msg}`, style, ...obj);
}

/*
 * Logs by Environment
 */
export function deprecate(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleInfo(...obj);
}

export function prod(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleInfo(...obj);
}

export function info(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleInfo(...obj);
}

export function log(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleLog(...obj);
}

export function warn(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleWarn(...obj);
}

export function debug(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleDebug(...obj);
}

export function error(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleError(...obj);
}

export function trace(...obj) {
  if (!window.AEXP_DEBUG) {
    return;
  }
  consoleTrace(...obj);
}

/*
 * operation duration logging helpers
 */

var operationStartTimeStamps = {};

export function operationStart(opName, skipOutput) {
  if (!opName) {
    return;
  }

  const dtNow = new Date();
  operationStartTimeStamps[opName] = dtNow;

  if (skipOutput !== true) {
    consoleInfo(`${opName} starts at ${dtNow.toString()}.`);
  }
}

export function operationEnd(opName, ...obj) {
  const dtStart = operationStartTimeStamps[opName];

  if (!dtStart || typeof dtStart.getMonth !== 'function') {
    return;
  }

  consoleInfo(
    `${opName} takes ${new Date().getTime() - dtStart.getTime()} milliseconds.`,
    ...obj
  );
  delete operationStartTimeStamps[opName];
}

/*
 * Logs by Colors
 */
export function green(msg, ...obj) {
  color('background: #00FF00; color: #003300', msg, ...obj);
}

export function blue(msg, ...obj) {
  color('background: #e5e5ff; color: #000066', msg, ...obj);
}

export function yellow(msg, ...obj) {
  color('background: #333300; color: #e5e500', msg, ...obj);
}

export function orange(msg, ...obj) {
  color('background: #4c2600; color: #ff8000', msg, ...obj);
}

export function red(msg, ...obj) {
  color('background: #ff4000; color: #ffffff', msg, ...obj);
}

export function pink(msg, ...obj) {
  color('background: 0080ff; color: #bf00ff', msg, ...obj);
}

export function burgundy(groupBy, msg, ...obj) {
  color('background: #ff6666; color: #e5e500', groupBy, msg, ...obj);
}

const LOGS = {
  green: green,
  blue: blue,
  yellow: yellow,
  orange: orange,
  red: red,
  pink: pink,
  burgundy: burgundy,
  info: consoleInfo,
  log: consoleLog,
  warn: consoleWarn,
  debug: consoleDebug,
  error: consoleError,
  trace: consoleTrace,
  deprecate: deprecate,
  operationStart: operationStart,
  operationEnd: operationEnd
};

Object.keys(LOGS).map(function(log) {
  LOGS[log].when = function(condition, ...arg) {
    condition && LOGS[log](...arg);
  };
  return LOGS[log];
});

export default LOGS;
