import URL from 'url';
import queryString from 'query-string';
import { isServer } from 'src/universal/utils/browser-detection/index';
import { appendQueryParams } from 'acq-utils/lib/utils';

export const stripLinkNavs = query => {
  const queryObj = queryString.parse(query);
  delete queryObj['linknav'];
  return queryString.stringify(queryObj);
};

export const stripNullsFromQuery = queryObject => {
  Object.keys(queryObject).forEach(queryKey => {
    if (queryObject[queryKey] === null) {
      delete queryObject[queryKey];
    }
  });
  return queryObject;
};

export const stripQueryString = url => url.slice(0, url.indexOf('?'));

export const removeArrayValues = queryObject =>
  Object.keys(queryObject).reduce((obj, key) => {
    const value = queryObject[key];
    return Object.assign(obj, {
      [key]: Array.isArray(value) ? value.pop() : value
    });
  }, {});

export const urlWithNoDuplicateParams = urlStr => {
  const parsedUrl = queryString.parseUrl(urlStr);
  let urlQuery = removeArrayValues(parsedUrl.query);
  urlQuery = stripNullsFromQuery(urlQuery);

  return appendQueryParams(parsedUrl.url, urlQuery);
};

export const appendTrackingParams = (url, query) => {
  if (!url) {
    return url;
  }

  const queryWithoutLinkNavs = stripLinkNavs(decodeURIComponent(query));

  if (query && queryWithoutLinkNavs) {
    return urlWithNoDuplicateParams(
      `${url}${url.includes('?') ? '&' : '?'}${queryWithoutLinkNavs}`
    );
  } else {
    return urlWithNoDuplicateParams(url);
  }
};

export const buildUrl = (...paths) => {
  return paths.join('/');
};

export function createReactRouterLink(url) {
  return URL.parse(url).path;
}

export const getUrlWithOriginPrepended = url =>
  !isServer() && !url.includes(window.location.origin)
    ? window.location.origin + url
    : url;
