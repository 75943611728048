const ALLOWED_COOKIES = [
  'AMCV_5C36123F5245AF470A490D45@AdobeOrg',
  'saneid',
  'aam_maxymiser',
  'aampros',
  'fltk',
  'amex_cms',
  'lpvid',
  'aam_id',
  'ri',
  'aam_rv',
  'ensighten_sbo',
  'cardshop_sess',
  's_sess'
];

export const axpDebugPrintCookies = () => {
  const arr = String(document.cookie)
    .split(';')
    .map(query => query.trim().split('='))
    .filter(([name]) => ALLOWED_COOKIES.indexOf(name.toLowerCase()) !== -1)
    .map(query => query.join(': '));

  arr.forEach(x => console.log(x));
};
