'use strict';

function noop() {}

const jQueryApi = {
  on: noop,
  ajaxComplete: noop
};

const jQueryStaticApi = {};

const jQuery = function jQueryMock() {
  return jQueryApi;
};

Object.assign(jQuery, jQueryStaticApi, jQueryApi);

if (typeof window !== 'undefined') {
  window.$ = jQuery;
}

export default jQuery;
