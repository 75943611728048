'use strict';

import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';

import Subnav from 'src/universal/components/Subnav/';

import { Sticky } from '@americanexpress/aqx-components';
import '@americanexpress/aqx-components/dist/style.css';
import styles from './sub-nav.scss?modules';

import {
  BreakpointProvider,
  disableZoomGlobally
} from '@americanexpress/aqx-components';

disableZoomGlobally();

const skipToContentClick = e => {
  e.stopImmediatePropagation();
  e.preventDefault();
  const mainContent = document.getElementById('mainContent');
  mainContent.scrollIntoView(true);
  mainContent.focus({ preventScroll: true });
};

const DefaultNav = ({ segmentParams }) => {
  if (typeof window !== 'object') {
    return <div className={styles.subNavPlaceholder} />;
  }

  return <Subnav segmentParams={segmentParams} />;
};

const MainContainer = ({ children, className, segmentParams, ...elementProps }) => {
  useEffect(() => {
    disableZoomGlobally();
    const skipToContentButton = document.querySelectorAll(
      'a[href="#mainContent"]'
    )?.[0];

    if (typeof skipToContentButton?.addEventListener === 'function') {
      skipToContentButton.addEventListener('click', skipToContentClick);
    }

    return () => {
      if (typeof skipToContentButton?.removeEventListener === 'function') {
        skipToContentButton.removeEventListener('click', skipToContentClick);
      }
    };
  }, []);

  const isComparePage = elementProps.activePageName === 'compareCards';
  return (
    <main className={className} id="mainContent" tabIndex="-1">
      <BreakpointProvider>
        {!isComparePage ? (
          <Sticky.Container>
            <Sticky.Item $zIndex={500}>
              <DefaultNav segmentParams={segmentParams} />
            </Sticky.Item>
            {React.createElement('main-container', elementProps, children)}
          </Sticky.Container>
        ) : (
          <>
            <DefaultNav segmentParams={segmentParams} />
            {React.createElement('main-container', elementProps, children)}
          </>
        )}
      </BreakpointProvider>
    </main>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    segmentParams: ownProps?.params?.segment ?? '',
    activePageName: state.activePageName
  };
};

const ConnectedMainContainer = connect(mapStateToProps)(memo(MainContainer));

export default ConnectedMainContainer;
