import { DEFAULT_FILTER } from 'src/universal/redux/cardsFilter';

export const filterNames = [
  DEFAULT_FILTER,
  'cash-back',
  'rewards-points',
  'no-annual-fee',
  'no-foreign-transaction-fees',
  'zero-intro-apr',
  'travel',
  'hotels',
  'airlines'
];

const onlyContainDigits = val => {
  return /^\d+$/.test(val);
};

const isVacFilterInRouterPath = val => {
  // we use "> 0" because DEFAULT_FILTER is not supported as part of the router path
  return filterNames.indexOf(String(val).toLowerCase()) > 0;
};

const extractEep = ({ param1, param2 }) => {
  if (isVacFilterInRouterPath(param1) && onlyContainDigits(param2)) {
    return param2;
  } else if (onlyContainDigits(param1)) {
    return param1;
  }

  return null;
};

const extractFilterName = ({ param1 }) => {
  if (isVacFilterInRouterPath(param1)) {
    return param1;
  }
  return null;
};

export const getVacPathParts = routerParams => {
  let result = {
    eep: null,
    filterName: null
  };

  if (!routerParams || typeof routerParams !== 'object') {
    return result;
  }

  result.eep = extractEep(routerParams);
  result.filterName = extractFilterName(routerParams);
  return result;
};

export const ensureNoNeedToLoadPdp = routerParam => {
  const { param1 } = routerParam || {};

  if (
    typeof param1 !== 'string' ||
    param1.length <= 0 ||
    onlyContainDigits(param1) ||
    isVacFilterInRouterPath(param1)
  ) {
    return true;
  }

  return false;
};
