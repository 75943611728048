import React from 'react';

// components
import {
  Element,
  Color,
  FontType,
  Link,
  Typography
} from '@americanexpress/aqx-components';
// utils
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import { sendDTMCustomEvent } from 'service_modules/models/dtm-tagging';
// styles
import styles from '../__styles__/index.scss?modules';

const BusinessHome = ({ text, href, dtm, theme, isCM }) => {
  return (
    <Element
      id={text}
      className={styles.businessHomeButton}
      style={{ zIndex: 500 }}
      theme={{
        bgColor: Color.Gray01,
        paddingLeft: 10,
        width: isCM ? 210 : 165,
        ...theme
      }}
      onClick={() =>
        sendDTMCustomEvent({
          eventAction: 'click',
          eventAsset: dtm.tag,
          eventType: 'pageInteraction'
        })
      }
      aria-labelledby={text}
    >
      <Link href={mapUrlWithDtmLinkQuery(href, dtm)}>
        <Typography
          Component="span"
          $family={FontType.HelveticaRegular}
          $size="14px"
          $lineHeight={12}
        >
          {text}
        </Typography>
      </Link>
    </Element>
  );
};

export default BusinessHome;
