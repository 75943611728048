'use strict';
import { CARD_DETAIL_SUPP_MODAL } from 'src/services/types';
const initialState = {
  suppModalOpen: false,
  suppModalDtmData: ''
};

export default (state = initialState, action) => {
  if (action.type && action.type === CARD_DETAIL_SUPP_MODAL) {
    return Object.assign({}, state, action.data);
  }
  return state;
};
