import getWaitUntilConditionPromise from '../getWaitUntilConditionPromise';
import { isBrowser } from 'acq-utils/lib/misc';

export const ensureAdobeScriptHasLoaded = (waitTime = 2000) =>
  getWaitUntilConditionPromise({
    condition: () => window._satellite && window._satellite.getVisitorId,
    logName: 'ensureAdobeScriptHasLoaded',
    waitTime
  });

export const ensureAdobeAndTrackItAreReady = async (waitTime = 5000) =>
  getWaitUntilConditionPromise({
    condition: () => typeof window.s?.tl === 'function' && window.trackIt,
    logName: 'ensureAdobeAndTrackitAreReady',
    waitTime
  });

export const ensureAdobeEventsAreReady = async (waitTime = 2000) => {
  await ensureAdobeScriptHasLoaded();
  return getWaitUntilConditionPromise({
    condition: () => window.s, //global var initiated by the adobe script
    logName: 'ensureAdobeEventsAreReady',
    waitTime
  });
};

export const ensurePageAttributesReady = async (waitTime = 3000) => {
  return getWaitUntilConditionPromise({
    condition: () => {
      if (isBrowser()) {
        return window.dtmPageAttributesReady || false;
      }
      return true;
    },
    logName: 'ensurePageAttributesReady',
    waitTime
  });
};
