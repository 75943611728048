import { createSelector, reducerGenerator, action } from './utils';
import { getRubiksContentList } from 'src/universal/redux/page';
import { hasPznDataArrived, getPznOfferList } from 'src/universal/redux/pznData.js';
import {
  saveSessColorChoices,
  getSessColorChoices
} from '../utils/cardColorChoices';

const UPDATE_SELECTED_CARD_DESIGN = 'UPDATE_SELECTED_CARD_DESIGN';
const UPDATE_FROM_SESSION_STORE = 'UPDATE_FROM_SESSION_STORE';

/*
US4229744:
(1) currently we only support "gold". But we are able to multiple cards in this format:
    { gold: 'NUS000000258', platinum: 'NUS000000889', ...etc. }
(2) why not using index value here? (which is easier to processe by code)?
    because assetId gives a certain degree of permanence and stableness.
*/
const defaultState = {};

export const cardColorChangeReducer = reducerGenerator(defaultState, {
  [UPDATE_SELECTED_CARD_DESIGN]: (state, action) => {
    const shortName = action.targetCard;
    if (shortName) {
      const stateNew = {
        ...state,
        [shortName]: action.selectedAssetId
      };
      saveSessColorChoices(stateNew);
      return stateNew;
    }
    return { ...state };
  },
  [UPDATE_FROM_SESSION_STORE]: (state, action) => {
    return {
      ...state,
      ...(action.hashFromSess || {})
    };
  }
});

export const dispatchUpdateSelectedCardDesign = (dispatch, payload) => {
  dispatch(action(UPDATE_SELECTED_CARD_DESIGN, payload));
};

export const dispatchUpdateFromSessStore = dispatch => {
  dispatch(
    action(UPDATE_FROM_SESSION_STORE, { hashFromSess: getSessColorChoices() })
  );
};

export const determineColorPicker = cardShortName =>
  createSelector(
    [hasPznDataArrived, getPznOfferList, getRubiksContentList],
    (pznArrived, offerList, rubiksList) => {
      const allowColorPicker = cardShortName !== 'delta-reserve';
      const clrChoices = (rubiksList || []).find(c => c.shortName === cardShortName);
      if (pznArrived && Array.isArray(offerList) && clrChoices && allowColorPicker) {
        const cardObj = offerList.find(c => c.shortName === cardShortName);
        if (cardObj && Array.isArray(cardObj?.designVariations?.cardDesigns)) {
          return {
            showPicker: true,
            data: { ...cardObj, rubiksContent: clrChoices }
          };
        }
      }
      return { showPicker: false };
    }
  );

export const getDesignInfo = cardShortName =>
  createSelector([determineColorPicker(cardShortName)], pickerStatus => {
    if (!pickerStatus?.showPicker) {
      return { needColorPicker: false };
    }

    return {
      needColorPicker: true,
      ...pickerStatus.data
    };
  });

export const getSelectedColors = state => state.selectedCardColors;

export const getCardColorInfoList = createSelector(
  [hasPznDataArrived, getPznOfferList, getRubiksContentList, getSelectedColors],
  (pznArrived, offerList = [], rubiksList = [], selectedItemHash = {}) => {
    if (!pznArrived) {
      return [];
    }
    const defaultAssetId = rubiksItem => {
      if (Array.isArray(rubiksItem?.content) && rubiksItem.content.length > 0) {
        return rubiksItem.content[0].digitalAssetId;
      }
      return '';
    };
    const resultList = rubiksList.reduce((accu, item) => {
      const blockIt = item.shortName === 'delta-reserve';
      const matchedOffer = blockIt
        ? null
        : offerList.find(
            offer =>
              offer.shortName === item.shortName &&
              Array.isArray(offer?.designVariations?.cardDesigns)
          );
      if (matchedOffer) {
        accu.push({
          ...matchedOffer,
          selectedAssetId: selectedItemHash[item.shortName] || defaultAssetId(item),
          rubiksContent: item
        });
      }
      return accu;
    }, []);
    return resultList;
  }
);

export const colorChoicesAllowed = cardShortName =>
  createSelector([getRubiksContentList], (rubiksList = []) => {
    const rubksItem = rubiksList.find(c => c.shortName === cardShortName);
    if (!rubksItem) {
      return false;
    }
    return true;
  });

export const getSelectedColorInfo = cardShortName =>
  createSelector(getCardColorInfoList, infoList => {
    return infoList.find(item => item.shortName === cardShortName);
  });

export const getAnnualFeeDisclaimer = cardShortName =>
  createSelector(getDesignInfo(cardShortName), designInfo => {
    if (designInfo) {
      return designInfo?.rubiksContent?.annualFeeDisclaimer;
    }
    return null;
  });
