import React from 'react';

// components
import { Element, Link } from '@americanexpress/aqx-components';
// utils
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';

// styles
import styles from '../__styles__/index.scss?modules';

const LogoImg = ({ src, height, alt }) => {
  return (
    <Element className={styles.logoDiv}>
      <img src={src} style={{ height: `${height}` }} height={height} alt={alt} />
    </Element>
  );
};

export const ClickableLogo = ({ src, height, alt, href, dtm = {}, theme = {} }) => {
  return href ? (
    <Link theme={theme} href={mapUrlWithDtmLinkQuery(href, dtm)}>
      <LogoImg src={src} height={height} alt={alt} {...theme} />
    </Link>
  ) : (
    <LogoImg src={src} height={height} alt={alt} {...theme} />
  );
};
