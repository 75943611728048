const e2PublicPath = 'https://qwww.aexp-static.com/hydraglobal/dacommercialfe';
const e3PublicPath = 'https://www.aexp-static.com/hydraglobal/dacommercialfehydra';

export default host => {
  const publicPathHosts = {
    'qwww.americanexpress.com': e2PublicPath,
    'opengcp-fe-qa.americanexpress.com': e2PublicPath,
    'dacommercialfe-qaeusw1.americanexpress.com': e2PublicPath,

    'www.americanexpress.com': e3PublicPath,
    'opengcp-fe.americanexpress.com': e3PublicPath,
    'dacommercialfehydra.americanexpress.com': e3PublicPath,

    // Temporary change for hydra migration
    'dacommercialfe-euse1.americanexpress.com':
      'https://www.aexp-static.com/hydraglobal/dacommercialfe-euse1',
    'dacommercialfe-eusw1.americanexpress.com':
      'https://www.aexp-static.com/hydraglobal/dacommercialfe-eusw1'
  };

  return `${publicPathHosts[host] || ''}/us/credit-cards/business/site/`;
};
