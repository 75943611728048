import React from 'react';
import { Link } from 'acq-components/lib/base-components/Link/Link';

const TplError = props => {
  if (!props || !props.data) {
    return null;
  }

  const data = props.data;

  const eepPart = data.expiredEepVal ? `/${data.expiredEepVal}` : '';

  const url = props.isPrequal
    ? window.location.href
    : data.cta
      ? `${data.cta.url}${eepPart}`
      : '';

  let buttonText;
  // this 'if' is needed to match up the conditional markup "{data.cta && ".
  // otherwise there may be runtime error.
  if (data.cta) {
    buttonText = props.isPrequal ? 'Refresh' : data.cta.text;
  }

  const buildActionDisplay = actions => {
    return actions.reduce((messages, action) => {
      if (action.message.text) {
        messages.push(<p className="message">{action.message.text}</p>);
      }
      if (action.message.phoneNumber) {
        messages.push(
          <Link className="phoneNumber" to={action.message.phoneNumber.href}>
            {action.message.phoneNumber.text}
          </Link>
        );
      }
      return messages;
    }, []);
  };

  const buildButtons = ctas => {
    return ctas.reduce((buttons, cta) => {
      const { url, text } = cta;
      buttons.push(
        <Link className="multi aexp-button aexp-button--blue" to={url}>
          {text}
        </Link>
      );
      return buttons;
    }, []);
  };

  return (
    <section>
      {data.header && <p className="header">{data.header}</p>}
      {data.subheader && (
        <p
          className="subheader"
          dangerouslySetInnerHTML={{ __html: data.subheader }}
        />
      )}
      {data.actions && (
        <div className="action">{buildActionDisplay(data.actions)}</div>
      )}
      {data.message && <p className="message">{data.message}</p>}
      {data.cta && (
        <div className="buttons">
          <Link className="aexp-button aexp-button--blue" to={url}>
            {buttonText}
          </Link>
        </div>
      )}
      {data.ctas && <div className="buttons">{buildButtons(data.ctas)}</div>}
    </section>
  );
};

export default TplError;
