export const US_BASE_URL = '/us';
export const BUSINESS_CARD_PATH = 'credit-cards/business';
export const BUSINESS_CREDIT_CARDS_ROUTE_PATH =
  'business-credit-cards(/:param1)(/:param2)';
export const CARD_DETAIL_ROUTE_PATH = 'business-credit-cards/:cardName(/:eep)';
export const CORPORATE_CARD_DETAIL_ROUTE_PATH =
  '/us/credit-cards/business/corporate-credit-cards/:cardName';
export const COMPARE_CARDS_ROUTE_PATH =
  'business-credit-cards/compare-credit-cards(/:param1)(/:param2)';
export const CORPORATE_PROGRAMS_ROUTE_PATH = 'corporate-credit-cards';

/*
Why do we declare a 2nd set of the consts below?
(1) we are to launch a PDP page (Aladdin/BlueCash) with a brand new route configuration.
(2) the new route will have to co-exist with existing routes.
(3) we will migrate all the "old" routes to "new" routes over time.
(4) appending "2" seems to be a concise and clear way for this purpose.
*/

export const US_BASE_URL2 = '/en-us';
export const BUSINESS_CARD_PATH2 = 'business';
export const CARD_DETAIL_ROUTE_PATH2 = 'credit-cards/:cardName(/:eep)';
export const CORP_SEGMENTS_ROUTE_PATH = `${US_BASE_URL2}/${BUSINESS_CARD_PATH2}/${CORPORATE_PROGRAMS_ROUTE_PATH}/:segment`;
// /en-us/business/corporate-credit-cards/startups/
