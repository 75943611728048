'use strict';

const isServer = typeof window === 'undefined';

const ua = isServer ? '' : (window.navigator.userAgent || '').toLowerCase();
const msie = ua.indexOf('msie');
const edge = ua.indexOf('edge/');
const trident = ua.indexOf('trident/');

export const isIE7 =
  typeof window === 'undefined'
    ? ''
    : window.navigator.appVersion.indexOf('MSIE 7.') !== -1;
export const isIE11 = msie > 0 || trident > 0;
export const isEdge =
  edge > 0 ? parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10) : false;
export const isIELegacy =
  msie > 0 ? parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10) : false;
export const isIE = isIE11;
export const isFirefox = ua.indexOf('firefox') > -1;
export const isChrome = isServer !== true && typeof window.chrome === 'object';

export function isMobileDevice() {
  if (isServer) {
    return false;
  }
  let uaVal = window.navigator ? String(window.navigator.userAgent) : '';
  uaVal = uaVal.toLowerCase();

  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|crios/i.test(
    uaVal
  );
}

const breakpoints = {
  phone: 414, // Max width of rotated iPhone
  tablet: 768, // Max width of rotated iPad
  laptop: 1024 // Common laptop screen width
};

export const browserSize = {
  isPhone(width) {
    return width <= breakpoints.phone;
  },
  isTablet(width) {
    return width > breakpoints.phone && width <= breakpoints.tablet;
  },
  isSmallComputer(width) {
    return width > breakpoints.tablet && width <= breakpoints.laptop;
  },
  isLargeComputer(width) {
    return width > breakpoints.laptop;
  }
};

/**
 * getDeviceType returns a string representing what kind of device the app is
 * running on.
 * @param {number=} deviceWidth The width of the device to check. Defaults to
 * `window.innerWidth` in the browser, 1024 on the server.
 */
export const getDeviceType = deviceWidth => {
  // If we're not on the server, default to window.innerWidth;
  deviceWidth = deviceWidth || (isServer ? 1024 : window.innerWidth);
  if (browserSize.isPhone(deviceWidth)) {
    return 'phone';
  } else if (browserSize.isTablet(deviceWidth)) {
    return 'tablet';
  } else if (browserSize.isSmallComputer(deviceWidth)) {
    return 'small-computer';
  } else if (browserSize.isLargeComputer(deviceWidth)) {
    return 'large-computer';
  } else {
    throw new Error(
      'getDeviceType: Unexpected device width provided. This is likely ' +
        'a problem with the browserSize breakpoint functions'
    );
  }
};

export function isTabletDevice(breakPoint) {
  if (isServer) {
    return false;
  }

  const widthCriteria = breakPoint || 414;
  return isMobileDevice() === true && window.innerWidth > widthCriteria;
}

export function isAndroidDevice() {
  return ua.indexOf('android') > -1;
}

export function isAndroidTabletDevice(breakPoint) {
  const isTablet = isTabletDevice(breakPoint);

  if (isTablet === true && ua.indexOf('android') > -1) {
    return true;
  }

  return false;
}

export function getNetworkType() {
  if (isServer) {
    return 'server';
  }
  const connection =
    window.navigator.connection ||
    window.navigator.mozConnection ||
    window.navigator.webkitConnection;
  return connection ? connection.effectiveType : '';
}

export const isIOS = () => {
  if (isServer) {
    return false;
  }
  const navPlatform = window.navigator ? window.navigator.platform || '' : '';
  const navAgent = window.navigator ? window.navigator.userAgent || '' : '';
  return (
    /^(iPad|iPhone|iPod).*/.test(navPlatform) ||
    (navAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isWinOS = () => {
  if (isServer) {
    return false;
  }
  return (
    window.navigator.userAgent.indexOf('Win') !== -1 ||
    window.navigator.appVersion.indexOf('Win') !== -1
  );
};

export const getWinWidth = (ssrWidth = 900) => {
  if (typeof window === 'undefined') {
    return ssrWidth;
  }
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );
};

export const getWinHeight = (ssrHeight = 650) => {
  if (typeof window === 'undefined') {
    return ssrHeight;
  }
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
};
