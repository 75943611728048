import React, { useState } from 'react';
import { connect } from 'react-redux';

// components
import { Element } from '@americanexpress/aqx-components';
import { DropdownMenu } from './DropdownMenu';
import { CallInfo } from './CallInfo';
import { ClickableLogo } from './Logo';
import BusinessHome from './businessHomeButton';
import { CardMemberLink } from './CardMemberLink';

// styles
import styles from '../__styles__/index.scss?modules';
import { desktopDropdownTheme, tabletCallTheme } from '../__styles__/themes';

// utils
import { getCardMemberData } from '../utils';

const DesktopSubnav = props => {
  const { wideWidth, midWidth } = props.subnavWidth;
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { cmData, pageName, desktopLogo, data, callInfo, textLogo } = props;

  const { userLoggedIn, viewMyAccountSubData, isCardMember } = cmData;

  const isLoggedInCardMember = userLoggedIn && isCardMember;

  let maxWidthVal = '986px'; // we defaults to narrow mode
  if (wideWidth.includes(props.pageName)) {
    maxWidthVal = '1240px';
  } else if (midWidth.includes(props.pageName)) {
    maxWidthVal = '1020px';
  }

  return (
    <Element
      className={styles.desktopSubnav}
      style={{
        maxWidth: maxWidthVal
      }}
    >
      {desktopLogo && <ClickableLogo {...desktopLogo} />}
      {textLogo && <BusinessHome {...textLogo} isCM={isLoggedInCardMember} />}
      <div className={styles.desktopMenu}>
        {data.map((eachMenu, index) => (
          <DropdownMenu
            key={index}
            menuIndex={index}
            theme={desktopDropdownTheme}
            {...props}
            data={[eachMenu]}
            openAccordionIndex={openAccordionIndex}
            setOpenAccordionIndex={setOpenAccordionIndex}
          />
        ))}
      </div>
      <CallInfo
        mobile={false}
        desktop={true}
        {...callInfo}
        pageName={pageName}
        theme={tabletCallTheme}
        isLoggedInCardMember={isLoggedInCardMember}
      />
      {userLoggedIn && isCardMember && <CardMemberLink {...viewMyAccountSubData} />}
    </Element>
  );
};

const mapStateToProps = state => {
  return {
    cmData: getCardMemberData(state)
  };
};

export default connect(mapStateToProps)(DesktopSubnav);
