import AppTransition from 'page_modules/business-page-containers/app-transition';
import { businessCreditCardsRoutes } from 'page_modules/business-vac/routes/index.js';
import { businessHubRouteInit } from 'page_modules/business-hub/routes';
import { compareCardsRouteInit } from 'page_modules/compare-cards/routes/index.js';
import { corpSegmentRouteInit } from 'page_modules/corporate-segments/routes';
import {
  intlVACPage,
  intlVACPageCorporate,
  intlCardDetailsSmallBusiness,
  intlCardDetailsCorporate,
  intlCompareSmallBusiness,
  intlCompareCorporate,
  intlTNCPage,
  preQualSmallBusiness
} from 'page_modules/intl-pages/routes';
import {
  cardDetailRoute,
  cardDetailRouteLegacy
} from 'page_modules/business-pdp/routes';
import corporatePDPRoute from 'page_modules/corp-pdp/route.js';
import { corpCategoryRouteInit } from 'page_modules/corporate-category/routes';
import { notFoundRoute } from 'page_modules/http-errors/not-found-route';
import { ssrErrorPageRoutes } from 'page_modules/http-errors/ssr-error-route';

export const createRoutes = store => ({
  path: '/',
  component: AppTransition,
  childRoutes: [
    ...intlTNCPage.map(intlTNC => intlTNC(store)),
    ssrErrorPageRoutes,
    businessHubRouteInit(store),
    compareCardsRouteInit(store),
    businessCreditCardsRoutes(store),
    corpCategoryRouteInit(store),
    corporatePDPRoute(store),
    cardDetailRouteLegacy(store),
    cardDetailRoute(store),
    corpSegmentRouteInit(store),
    ...intlCompareSmallBusiness(store),
    ...intlCompareCorporate(store),
    ...intlVACPage(store),
    ...intlVACPageCorporate(store),
    ...intlCardDetailsSmallBusiness(store),
    ...intlCardDetailsCorporate(store),
    ...preQualSmallBusiness(store),
    notFoundRoute
  ]
});
