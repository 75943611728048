export const loggedInString = 'logged-in';
export const loggedOutString = 'logged-out';
export const prospectString = 'prospect';
export const cardMemberCookie = 'blueboxpublic';
export const cardMemberCookieLoggedIn = 'blueboxvalues';

export const perfLogUserTypeCardMember = 'card-member';
export const perfLogUserTypeProspect = 'prospect';

export const getUserType = cookies => {
  let userType = prospectString;
  let isCardMember = false;

  if (cookies[cardMemberCookie]) {
    if (cookies[cardMemberCookieLoggedIn]) {
      userType = loggedInString;
    } else {
      userType = loggedOutString;
    }
    isCardMember = true;
  }

  return [userType, isCardMember];
};
