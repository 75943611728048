/*
Note: 
This function cannot be called more than once.  An example will be something like this:
(1) Replace a place-holder with a value.
(2) Then, somewhere in the flow attemp to replace another place-holder with another value.
    on the result of the step(1).
The step(2) will fail because step(1) basically wipeed out all the place-holders.
*/
const stringWithVarsReplace = ({
  varsObject = {},
  prependVars = {},
  appendVars = {},
  string = ''
}) => {
  if (!string) {
    return '';
  }
  let formattedString = typeof string === 'string' ? string : '';

  Object.keys(varsObject).map(key => {
    if (varsObject[key]) {
      const prependString = prependVars[key] || '';
      const appendString = appendVars[key] || '';
      formattedString = formattedString.replace(
        new RegExp(`\\\${${key}\\}`, 'g'),
        `${prependString}${varsObject[key]}${appendString}`
      );
    }
  });

  formattedString = formattedString.replace(/\$\{(.*?)\}/g, '');

  return formattedString;
};

export default stringWithVarsReplace;
