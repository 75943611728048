import { isBrowser } from 'acq-utils/lib/misc';
import { scrollToDocTop } from 'service_modules/utils/window-onscroll';
import { getCardInfo } from 'service_modules/models/card-names/card-names.model';
import onEnterInterreptor from 'services/store/on-enter-interreptor';
import { buildUrl } from 'core_modules/utils/url.utils';
import { getPdpComponent } from './utils';
import {
  US_BASE_URL,
  BUSINESS_CARD_PATH,
  CARD_DETAIL_ROUTE_PATH,
  US_BASE_URL2,
  BUSINESS_CARD_PATH2,
  CARD_DETAIL_ROUTE_PATH2
} from 'src/routes';

const ROUTE_NAME = 'card-detail';

const SUPPORTED_CARDS_FOR_EN_US = [
  'blue-business-cash',
  'delta-skymiles-platinum',
  'delta-skymiles-gold',
  'delta-skymiles-reserve'
];

const isNewRoute = (location = {}) => {
  return (
    String(location.pathname).indexOf(
      `${US_BASE_URL2}/${BUSINESS_CARD_PATH2}/credit-cards`
    ) > -1
  );
};

function onEnter(location, replace, next) {
  if (
    isNewRoute(location.location) === true &&
    SUPPORTED_CARDS_FOR_EN_US.indexOf(location.params.cardName) <= -1
  ) {
    if (isBrowser()) {
      const initialData = window.initialData || {};
      initialData.errorCode = '404';
    }
    replace('/en-us/business/shop/error/404');
    return next();
  }

  location.params.cardName = location.params.cardName || location.params.eep;
  const card = getCardInfo(location.params.cardName);

  if (isBrowser() && (!Object.keys(card).length || card.alias !== true)) {
    const CARD_CATEGORY_URL = '/us/credit-cards/business/business-credit-cards';
    window.location.href = CARD_CATEGORY_URL;
    return;
  }

  scrollToDocTop();
  next();
}

const path = buildUrl(US_BASE_URL, BUSINESS_CARD_PATH, CARD_DETAIL_ROUTE_PATH);
const buildRoute = (isLegacy = true, store = null) => {
  const pathVal = isLegacy
    ? path
    : buildUrl(US_BASE_URL2, BUSINESS_CARD_PATH2, CARD_DETAIL_ROUTE_PATH2);
  return {
    path: pathVal,
    customProps: store,
    getComponent: (routerState, cb) => {
      getPdpComponent({
        cardName: routerState.params.cardName,
        store,
        cb
      });
    },
    onEnterHandler: onEnter,
    onEnter: onEnterInterreptor(ROUTE_NAME, onEnter)
  };
};

export const cardDetailRouteLegacy = store => buildRoute(true, store);
export const cardDetailRoute = store => buildRoute(false, store);
