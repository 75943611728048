export const getRawHeaders = headers => {
  if (!headers) {
    return {};
  }
  if (typeof headers.raw === 'function') {
    return headers.raw();
  }
  return JSON.parse(JSON.stringify(headers));
};

export const parseApiResponse = (response = {}) => {
  const { url, status } = response;
  const headers = getRawHeaders(response.headers);
  return data => {
    return { url, status, data, headers };
  };
};
