import React, { useState, useEffect, useContext } from 'react';
import { useToggle } from '@americanexpress/aqx-components';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';
import { AccordionDisplayContext } from '../index';
import styles from '../__styles__/index.scss?modules';
import { isIpadOS } from '../utils';

const Accordion = ({
  Button,
  Body,
  isDefaultOpen,
  setOpenAccordionIndex,
  openAccordionIndex,
  index,
  desktop
}) => {
  const { shouldDisplayAccordion, closeAccordion, openAccordion } = useContext(
    AccordionDisplayContext
  );
  const [isOpen, toggle] = useToggle(isDefaultOpen);

  const isHorizontalIpad = !!isIpadOS() && desktop;

  useEffect(
    () => {
      if (!shouldDisplayAccordion && isOpen) {
        toggle();
        closeAccordion();
      }
    },
    [shouldDisplayAccordion, isOpen]
  );

  const [itemExpandDtm, setItemExpandDtm] = useState(undefined);
  const [itemCollapseDtm, setItemCollapseDtm] = useState(undefined);

  useEffect(
    () => {
      if (isOpen && index !== openAccordionIndex) {
        toggle();
      }
    },
    [openAccordionIndex, isOpen, index]
  );

  const mouseEnter = () => {
    if (itemExpandDtm && !isOpen) {
      sendDTMClickEvent(itemExpandDtm);
      setOpenAccordionIndex(index);
      toggle();
      openAccordion();
    }
  };

  const mouseExit = () => {
    if (itemCollapseDtm && isOpen) {
      sendDTMClickEvent(itemCollapseDtm);
      toggle();
    }
  };

  const toggleAndSetIndex = () => {
    if (typeof setOpenAccordionIndex === 'function') {
      setOpenAccordionIndex(index);
    }
    if (!isOpen) {
      openAccordion();
    }
    toggle();
  };

  return (
    <div
      onMouseEnter={!isHorizontalIpad && mouseEnter}
      onMouseLeave={!isHorizontalIpad && mouseExit}
      className={styles.accordionDiv}
    >
      <Button
        onClick={toggleAndSetIndex}
        active={isOpen}
        setExpandDtm={setItemExpandDtm}
      />
      <div
        style={{ display: isOpen ? 'block' : 'none' }}
        className={styles.expandSection}
      >
        <Body
          onClick={toggleAndSetIndex}
          active={isOpen}
          setCollapseDtm={setItemCollapseDtm}
        />
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  isDefaultOpen: false
};

export default Accordion;
