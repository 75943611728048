const rateLimit = (fn, delay, context) => {
  const queue = [];
  let timer = null;

  const processQueue = () => {
    const item = queue.shift();
    if (item) {
      fn.apply(item.context, item.arguments);
    }
    if (queue.length === 0) {
      clearInterval(timer);
      timer = null;
    }
  };

  const rateLimitedFunction = (...args) => {
    queue.push({
      context: context || this,
      arguments: args
    });
    if (!timer) {
      processQueue();
      timer = setInterval(processQueue, delay);
    }
  };

  return rateLimitedFunction;
};

export default rateLimit;
