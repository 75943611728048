import { getEnv, setEnv } from 'src/universal/utils/detect-env.js';

const ENV = getEnv();
setEnv(process, 'NODE_ENV', ENV);

console.info(
  [new Array(100).join('*'), `env => ${ENV}`, new Array(100).join('*')].join('\n')
);

export { ENV };
