'use strict';

import { cleanUpBeforeCsr } from 'src/universal/utils/orchestraCmPznExtEvent';

export function createPageTransitionProcessor(store) {
  // note: the order matters
  const routePathToActionMap = [
    {
      parts: ['/mx/', '/au/', '/fr-fr/', '/ja-jp/', '/en-jp/', '/it-it/', '/de-de/'],
      action: 'INTL_PAGE_CLEANUP'
    },
    {
      parts: [
        'credit-cards/american-express',
        'credit-cards/amex-marriott-bonvoy-business',
        'credit-cards/delta-',
        'credit-cards/lowes-credit',
        'credit-cards/hilton-honors',
        'credit-cards/american-airlines',
        'credit-cards/amazon',
        'credit-cards/corporate-p',
        'credit-cards/corporate-meeting',
        'credit-cards/blue-business-cash'
      ],
      action: 'CARD_DETAIL_CLEANUP'
    },
    {
      parts: ['business-credit-cards/compare-credit-cards'],
      action: 'COMPARE_CARDS_CLEANUP'
    },
    /*
    {
      parts: ['business-credit-cards'],
      action: location => {
        return useOldVac(location) === true
          ? 'ALL_CARDS_CLEANUP'
          : 'ALL_CARDS_CLEANUP_V2';
      }
    }, */
    { parts: ['corporate-credit-cards'], action: 'CORPORATE_PROGRAMS_CLEANUP' },
    { parts: ['/'], action: 'BUSINESS_HOME_CLEANUP' }
  ];

  const getActionList = (routePath = '') => {
    const prevPage = global.pagesVisited[global.pagesVisited.length - 2];
    const routePathVal = String(routePath);
    if (routePathVal === prevPage) {
      return null;
    }
    for (let i = 0; i < routePathToActionMap.length; i++) {
      const partsList = routePathToActionMap[i].parts;
      for (let j = 0; j < partsList.length; j++) {
        if (
          routePathVal === partsList[j] ||
          routePathVal.indexOf(partsList[j]) > -1
        ) {
          return routePathToActionMap[i].action;
        }
      }
    }
    return 'PAGE_CLEANUP';
  };

  return location => {
    if (location && (location.action === 'PUSH' || location.action === 'POP')) {
      cleanUpBeforeCsr();
      const action = getActionList(location.pathname);
      if (action) {
        const actionVal =
          typeof action === 'function' ? action(location) : String(action);
        store.dispatch({ type: actionVal });
      }
    }
  };
}
