import React, { useLayoutEffect, useState, useRef } from 'react';

// components
import {
  Element,
  Color,
  FontType,
  useBreakpoint
} from '@americanexpress/aqx-components';
import Accordion from './Accordion.js';
import { MenuItem } from './MenuItem.js';
import { ArrowIcon } from './ArrowIcon.js';
import { CallInfo } from './CallInfo.js';

// utils
import clsx from 'clsx';
import { sendDTMClickEvent } from 'service_modules/models/dtm-tagging';

// styles
import styles from '../__styles__/index.scss?modules';
import {
  mobileCallTheme,
  mobileDropdownTitleTheme,
  tabletDropdownTitleTheme,
  desktopDropdownTitleTheme
} from '../__styles__/themes';

export const AccordionComponent = ({
  item,
  index,
  restOfItems = [],
  setOpenAccordionIndex,
  openAccordionIndex,
  ...accordionProps
}) => {
  const { expandDtm, collapseDtm, title, tabletHeader } = item;

  const {
    theme,
    customization = {},
    $fontSize,
    tablet,
    desktop,
    mobile,
    sendBoxWidth
  } = accordionProps;

  return (
    <Accordion
      key={`accordion-${index}`}
      desktop={desktop}
      setOpenAccordionIndex={setOpenAccordionIndex}
      openAccordionIndex={openAccordionIndex}
      index={index}
      Button={({ onClick, active, setExpandDtm }) => {
        const ref = useRef(null);

        const arrowClick = () => {
          if (expandDtm && !active) {
            onClick();
            sendDTMClickEvent(expandDtm);
          } else if (collapseDtm && active) {
            onClick();
            sendDTMClickEvent(collapseDtm);
          } else {
            onClick();
          }
          setTimeout(
            () => document.getElementById(`accordion-button-${index}`).focus(),
            0
          );
        };

        useLayoutEffect(() => {
          if (desktop && setExpandDtm) {
            setExpandDtm(expandDtm);
          }
        }, []);
        useLayoutEffect(
          () => {
            if (desktop && ref.current) {
              sendBoxWidth(ref.current.offsetWidth);
            }
          },
          [ref.current]
        );

        return (
          <Element
            id={`accordion-button-${index}`}
            Component="button"
            type="button"
            key={`button-${index}`}
            className={styles.dropdownbutton}
            aria-expanded={active}
            aria-label={tabletHeader && !mobile ? tabletHeader : title}
            onClick={arrowClick}
            theme={{
              color: Color.BrightBlue,
              fontType: FontType.HelveticaRegular,
              margin: 0,
              padding: 0,
              ...theme
            }}
          >
            <Element
              className={clsx(
                styles.dropdownTitle,
                styles.restDropdownTitles,
                active && styles.dropdownHover
              )}
              theme={
                tablet
                  ? tabletDropdownTitleTheme
                  : desktop
                    ? desktopDropdownTitleTheme
                    : mobileDropdownTitleTheme
              }
            >
              <span
                // I'm using span instead of typography here so I can use ref, which doesn't work with shop component elements
                style={{
                  fontSize: $fontSize,
                  fontFamily:
                    'HelveticaNeue, Helvetica, Arial, Lucida Grande, sans-serif'
                }}
                ref={ref}
              >
                {tabletHeader && !mobile ? tabletHeader : title}
              </span>
              {!desktop && <ArrowIcon direction={active ? 'up' : 'down'} />}
            </Element>
          </Element>
        );
      }}
      Body={({ setCollapseDtm }) => {
        useLayoutEffect(() => {
          if (desktop && setCollapseDtm) {
            setCollapseDtm(collapseDtm);
          }
        }, []);
        const [openTabletAccordionIndex, setOpenTabletAccordionIndex] = useState(
          null
        );
        return (
          <React.Fragment>
            <Element
              Component="ul"
              key={`body-${index}`}
              className={styles.items}
              theme={{
                margin: 0,
                padding: tablet ? 20 : 10,
                ...customization.items
              }}
            >
              {item.menuItems.map(
                ({ text, href, dtm, isSeparator, key }, menuItemsIndex) =>
                  (key !== 'tabletText' || (key === 'tabletText' && tablet)) && (
                    <MenuItem
                      key={`menu-${menuItemsIndex}`}
                      text={text}
                      href={href}
                      dtm={dtm}
                      isSeparator={isSeparator}
                      theme={customization.item}
                      $fontSize={$fontSize}
                      desktop={desktop}
                    />
                  )
              )}
            </Element>
            {tablet &&
              restOfItems.map((item, restOfItemsIndex) => {
                return (
                  <AccordionComponent
                    key={restOfItemsIndex}
                    item={item}
                    index={restOfItemsIndex + 1}
                    tablet={tablet}
                    mobile={mobile}
                    setOpenAccordionIndex={setOpenTabletAccordionIndex}
                    openAccordionIndex={openTabletAccordionIndex}
                    {...accordionProps}
                  />
                );
              })}
          </React.Fragment>
        );
      }}
    />
  );
};

export const DropdownMenu = ({
  data,
  theme,
  customization = {},
  callInfo,
  $fontSize,
  pageName,
  dropdownWidth,
  menuIndex = 0,
  openAccordionIndex,
  setOpenAccordionIndex
}) => {
  const { current: screenSize } = useBreakpoint();
  const [boxWidth, setBoxWidth] = useState(0);
  const [openTabletAccordionIndex, setOpenTabletAccordionIndex] = useState(null);
  const firstItem = data.slice(0, 1);
  const restOfItems = data.slice(1);

  const mobile = screenSize === 'sm';

  const tablet = screenSize === 'md';

  const desktop = screenSize === 'lg';

  const sendBoxWidth = width => {
    setBoxWidth(width + 20);
  };

  const accordionProps = {
    theme,
    customization,
    $fontSize,
    tablet,
    desktop,
    mobile,
    sendBoxWidth
  };
  return (
    <Element
      theme={{ bgColor: Color.Gray01 }}
      className={styles.dropdownBox}
      style={{ width: desktop && boxWidth }}
    >
      <Element
        className={styles.dropdownMenu}
        theme={{
          bgColor: Color.Gray01,
          horizontallyFluid: true
        }}
        style={{
          width: dropdownWidth
        }}
      >
        {(tablet ? firstItem : data).map((item, index) => {
          return (
            <AccordionComponent
              key={index}
              item={item}
              index={desktop ? menuIndex : index}
              restOfItems={restOfItems}
              setOpenAccordionIndex={
                desktop ? setOpenAccordionIndex : setOpenTabletAccordionIndex
              }
              openAccordionIndex={
                desktop ? openAccordionIndex : openTabletAccordionIndex
              }
              {...accordionProps}
            />
          );
        })}
        {mobile && (
          <CallInfo
            {...callInfo}
            pageName={pageName}
            theme={mobileCallTheme}
            mobile={true}
            desktop={false}
          />
        )}
      </Element>
    </Element>
  );
};
