import { application, environment, header, event } from 'src/laas-schema';
import { isServer } from 'src/universal/utils/browser-detection/index';

const getInitData = () =>
  Array.isArray(window.__PERF_INITIAL_DATA__) ? window.__PERF_INITIAL_DATA__ : [];

const store = isServer() ? [] : getInitData();

export const getNext = () => store.shift();
const add = () => {
  return true;
};

export const Log = (overwrite = v => v) => {
  const appData = Object.assign({}, application(), environment(), header());
  add(store, overwrite(Object.assign({}, appData, event())));
};

export const LogEventDuration = (data = {}) => {
  const { app, env, headers, ...rest } = data;

  const appData = Object.assign(
    rest || {},
    application(app),
    environment(env),
    header(headers)
  );

  const message = {
    eventStart: new Date().getTime()
  };

  const logEventEnd = eventData => {
    return (overwrite = v => v) => {
      message.eventEnd = new Date().getTime();
      message.eventDuration = message.eventEnd - message.eventStart;
      eventData.message = message;
      add(store, overwrite(eventData));
    };
  };

  const logEventStart = () => {
    let eventData = Object.assign({}, appData, event());
    return logEventEnd(eventData);
  };

  return logEventStart;
};
