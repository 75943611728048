import React from 'react';

// components
import { Element, Link } from '@americanexpress/aqx-components';
import { Text } from './Text';

// utils
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';

// styles
import styles from '../__styles__/index.scss?modules';

export const MenuItem = ({
  text,
  href,
  dtm = {},
  isSeparator,
  theme,
  $fontSize
}) => {
  let component;
  if (href) {
    component = (
      <Link
        href={mapUrlWithDtmLinkQuery(href, dtm)}
        className={styles.linkText}
        theme={{ focusDark: true }}
        onClick={e => e.target.focus()}
      >
        <Text $fontSize={14}>{text}</Text>
      </Link>
    );
  } else if (isSeparator) {
    component = (
      <Element theme={{ paddingVertical: 5 }}>
        <hr />
      </Element>
    );
  } else {
    component = (
      <Text $fontSize={$fontSize} textRole="heading">
        {text}
      </Text>
    );
  }

  return (
    <Element
      Component="li"
      className={isSeparator ? styles.separator : styles.menuItem}
      theme={{
        focusDark: true,
        ...theme
      }}
    >
      {component}
    </Element>
  );
};
