import { setPage } from 'page_modules/active-page';
import {
  US_BASE_URL,
  COMPARE_CARDS_ROUTE_PATH,
  BUSINESS_CARD_PATH
} from 'src/routes';
import { injectReducerToStore } from 'src/universal/redux/utils';
import { name as pageName } from '../__styles__/pageVars.scss';
import { buildUrl } from 'core_modules/utils/url.utils';

const path = buildUrl(US_BASE_URL, BUSINESS_CARD_PATH, COMPARE_CARDS_ROUTE_PATH);

const compareCardsRouteInit = store => ({
  path: path,
  getComponent: (_, callback) => {
    require.ensure(
      [],
      require => {
        const PageComponent = require('../').default;
        injectReducerToStore(store, PageComponent);
        callback(null, PageComponent);
      },
      null,
      'compareCards'
    );
  },
  onEnter: (location, replaceWith, next) => {
    setPage(pageName);
    next();
  }
});

export { compareCardsRouteInit };
