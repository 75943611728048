import React from 'react';
import get from 'lodash.get';
import { Provider } from 'react-redux';
import { browserHistory, Router, applyRouterMiddleware } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { syncHistoryWithStore } from 'react-router-redux';
import { createRoutes } from 'src/app-routes';
import { store } from './store';

import { createPageTransitionProcessor } from 'page_modules/utils/isomorphic-rendering/client-transition';
import { setRoutePath, setVisitSearchEngine } from 'service_modules/pzn-params';
import { InsertDTMPageBottom } from 'src/universal/components/DTM/InsertDTMPageBottom';
import TrackIt from 'src/universal/components/TrackIt';
import { updateExperimentState } from 'src/universal/components/Experiments/utils/update-experiment-state';
import { scrollToTopOnOrientationChange } from 'src/universal/utils/scrollTo';
import {
  isAndroidDevice,
  isWinOS
} from 'core_modules/bootstrap/browsers/browser-detection';

import 'page_modules/business-page-containers/app-transition/app-transition.scss';
import 'core_modules/ajax-loaders/templates/progress-circle/progress-circle.scss';
import 'page_modules/http-errors/templates/default.scss';
import 'theme_modules/open-gcp/index.ux.scss';

export function ClientProvider(props) {
  const { oneXpStateUpdater, testNLearn } = window || {};
  oneXpStateUpdater && oneXpStateUpdater.registerDispatcher(store.dispatch);
  testNLearn && testNLearn.registerStoreDispatcher(store);

  const history = syncHistoryWithStore(browserHistory, store);

  history.listen(createPageTransitionProcessor(store));
  history.listen(updateExperimentState);

  setVisitSearchEngine();
  scrollToTopOnOrientationChange();

  return (
    <Provider store={store} {...props}>
      <React.Fragment>
        <Router
          history={history}
          routes={createRoutes(store)}
          onUpdate={() => {
            setRoutePath(window.location);
            setVisitSearchEngine();
            setTimeout(() => {
              const addClassToBody = clsName => {
                const clsList = get(window, 'document.body.classList');
                if (clsList && typeof clsList.add === 'function') {
                  clsList.add(clsName);
                }
              };

              if (isAndroidDevice()) {
                addClassToBody('isAndroid');
              }

              if (isWinOS()) {
                addClassToBody('isWinOS');
              }
            }, 100);
          }}
          render={applyRouterMiddleware(useScroll(() => [0, 0]))} // scrolls back to top on navigation
        />
        <TrackIt />
        <InsertDTMPageBottom />
      </React.Fragment>
    </Provider>
  );
}

export function serverApplication() {
  return Object.assign({}, createRoutes(), {
    path: '/'
  });
}
