import React from 'react';
import { connect } from 'react-redux';

// components
import { Element, Size, Link, Icon } from '@americanexpress/aqx-components';
import { Text } from './Text.js';
import { CardMemberLink } from './CardMemberLink';

// utils
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import { getCardMemberData } from '../utils';

const AltMobileSubnav = ({ text, href = '', imageName, dtm = {}, cmData }) => {
  const { userLoggedIn, viewMyAccountSubData, isCardMember } = cmData;

  return (
    <React.Fragment>
      <Link href={mapUrlWithDtmLinkQuery(href, dtm)}>
        <Element theme={{ marginLeft: 5 }}>
          <Icon
            directory="dls"
            imageName={imageName}
            theme={{ paddingTop: 5, marginRight: 5 }}
            $size={Size.SM}
          />
          <Text $fontSize={'15px'}>{text}</Text>
        </Element>
      </Link>
      {userLoggedIn &&
        isCardMember && <CardMemberLink mobile={true} {...viewMyAccountSubData} />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    cmData: getCardMemberData(state)
  };
};

export default connect(mapStateToProps)(AltMobileSubnav);
