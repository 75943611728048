import React from 'react';
import get from 'lodash.get';
import { Element } from '@americanexpress/aqx-components';
import { Sanitizer } from 'acq-components/lib/base-components/Sanitizer/Sanitizer';
import replaceStringWithVars from 'src/universal/utils/replaceStringWithVars';
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';

const throwNotFound = () => {
  const exception = new Error('Unexpected card name provided');
  exception.errorCode = 404;
  throw exception;
};

export const mapCardNameToShortName = cardName => {
  switch (cardName) {
    case 'american-express-corporate-gold-card-amex':
      return 'corporate-gold';
    case 'american-express-platinum-corporate-card':
      return 'corporate-platinum';
    case 'american-express-corporate-green-card-amex':
      return 'corporate-green';
    case 'american-airlines-credit-card-business-extra':
      return 'corporate-extra';
    case 'corporate-p-card':
      return 'corporate-purchasing';
    case 'corporate-meeting-credit-card':
      return 'corporate-meeting';
    default:
      throwNotFound();
  }
};

export const formatLinksWithEep = (links, eep, appendEep) =>
  Object.entries(links).reduce((acc, [key, link = {}]) => {
    const url = link.url || '';
    if (url) {
      let formattedUrl;
      if (url.includes('${')) {
        formattedUrl = replaceStringWithVars({
          varsObject: {
            eep
          },
          string: url
        });
      } else if (appendEep && eep) {
        formattedUrl = `${url}/${eep}`;
      } else {
        formattedUrl = url;
      }
      const dtmObj = link.dtm;
      acc[key] = {
        ...link,
        url: dtmObj ? mapUrlWithDtmLinkQuery(formattedUrl, dtmObj) : formattedUrl
      };
    } else {
      acc[key] = link;
    }
    return acc;
  }, {});

const buildBenefit = (benefit, textContent, styles) => {
  if (benefit.descriptionList) {
    return (
      <Element>
        <Sanitizer Component="span" safelySetInnerHTML={textContent} />
        <Element Component="ul" className={styles.listContainer}>
          {benefit.descriptionList.map(item => (
            <Element Component="li" key={item}>
              {item}
            </Element>
          ))}
        </Element>
      </Element>
    );
  }
  return <Sanitizer Component="span" safelySetInnerHTML={textContent} />;
};

export const composeCardBenefitProps = (data = [], styles, isMobile) => {
  const parseBenefit = (benefit = {}) => {
    const disclaimer = benefit.disclaimer
      ? `<div class="${styles.disclaimer}">${benefit.disclaimer}</div>`
      : '';
    const benefitHeader = benefit.header || '';
    return {
      title: isMobile ? (
        <Sanitizer
          className={styles.itemTitle}
          Component="h4"
          safelySetInnerHTML={benefitHeader}
        />
      ) : (
        benefitHeader
      ),
      text: buildBenefit(
        benefit,
        `${benefit.description || ''}${disclaimer}`,
        styles
      )
    };
  };

  const items = data.map((benefitGroup = {}) => {
    const header = benefitGroup.header || '';

    const icon = {
      directory: 'dls',
      imageName: get(benefitGroup, 'imageName') || ''
    };

    const content = get(benefitGroup, 'content') || [];

    return {
      icon,
      label: header,
      body: content.map(parseBenefit)
    };
  });

  return { items };
};
