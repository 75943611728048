'use strict';

function noop() {}

(function(global) {
  if (!global.console) {
    global.console = {};
  }
  let con = global.console;

  let properties = ['memory'];
  let methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profiles',
    'profileEnd',
    'show',
    'table',
    'time',
    'timeEnd',
    'timeline',
    'timelineEnd',
    'timeStamp',
    'trace',
    'warn'
  ];
  for (let prop in properties) {
    if (!con[prop]) {
      con[prop] = {};
    }
  }

  for (let method in methods) {
    if (!con[method]) {
      con[method] = noop;
    }
  }
})(typeof window === 'undefined' ? this : window);
