'use strict';

const initialState = {};

export default (state = initialState, action) => {
  if (action.type && action.type === 'GET_MODAL_DATA') {
    return Object.assign({}, state, action.data);
  }

  if (action.type && action.type === 'NEED_MODAL_SPINNER') {
    return Object.assign({}, state, { needModalSpinner: action.data });
  }

  if (action.type && action.type === 'OFFER_EXPIRY_MODAL_ACTIVATED') {
    return Object.assign({}, state, { offerExpiryModalActivated: action.data });
  }
  return state;
};
